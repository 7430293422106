/**
 * @imarcsgroup/client:src/components/body/index.js
 */

/**
 * Constants.
 */
const UI_LOGGED_IN = 'ui-loggedin';
const UI_LOGGED_OUT = 'ui-loggedout';

// Example: data-on-roles='{"email-not-verified":"/home/email_verify/"}'>

/**
 * Body component factory.
 */
function bodyComponentFactory(dyn) {
  const { log, BaseComponent, controllers } = dyn();
  const { navigateTo } = controllers;
  const anchorRe = /#[a-z0-9\.\-_]*/i;
  const disableRedirect = [
    '/home/',
    '/home/email_verify/',
    '/home/account_alert/',
    '/home/password_alert/',
    '/home/session_timeout/',
    '/home/edit_profile_luckzone/',
    '/home/edit_profile_claims/',
    '/home/edit_profile_combined/',
    '/home/update_password/',
  ];

  /**
   * Body Component.
   */
  class BodyComponent extends BaseComponent {
    onConfig({ config }) {
      config('ready', false);
    }

    onMount({ info, onState }) {
      log.trace(`Mounting body component (#${this.id}).`);

      info({
        onRoles: ['[data-on-roles]'],
        onRolesAlert: ['[data-on-roles-alert]'],
      }, {
        onRoles: (value) => JSON.parse(value),
        onRolesAlert: (value) => value ? JSON.parse(value) : {},
      });

      onState({
        session: {
          selector: (_) => _.session.roles,
          action: async (roles) => {
            // Add loggedin/loggedout classes.
            if(roles.includes('@access') || roles.includes('@leaving')) {
              this.ele.addClass(UI_LOGGED_IN).removeClass(UI_LOGGED_OUT);
            } else {
              this.ele.addClass(UI_LOGGED_OUT).removeClass(UI_LOGGED_IN);
            }
            // Add class for each normal role.
            for(let role of roles) {
              if(/^[a-z][a-z0-9\-_]+$/.test(role)) {
                this.ele.addClass(`ui-role-${role}`);
              }
            }
            // Check for modal anchors.
            const currentUrl = new URL(window.location.href);
            const anchor = currentUrl.hash;
            if(anchor) {
              $(anchor).modal();
              history.replaceState('', document.title, currentUrl.href.replace(anchor, ''));
            }
            // On roles...
            if(this.info.onRoles) {
              const rules = this.info.onRoles.rules;
              let ruleMatch = false;
              for(let rule of rules) {
                if(roles.includes(rule.role) && !ruleMatch) {
                  const role = rule.role;
                  const actionType = 'goto';
                  switch (actionType) {
                    case 'goto': {
                      let path;
                      const action = rule?.path;
                      if(action === '[PROFILE]') {
                        const roleSc = roles.includes('secondchance');
                        const roleClaims = roles.includes('claims');
                        path = roleSc && roleClaims ? '/home/edit_profile_combined/' : path;
                        path = !path && roleSc ? '/home/edit_profile_luckzone/' : path;
                        path = !path && roleClaims ? '/home/edit_profile_claims/' : path;
                      }
                      const url = new URL(path || action, window.location.toString());
                      if(!disableRedirect.includes(window.location.pathname) && window.location.pathname !== url.pathname) {
                        const roleAlertSelector = this?.info?.onRolesAlert && this.info.onRolesAlert[role];
                        if(roleAlertSelector) {
                          log.info(`Display alert "${roleAlertSelector}"`);
                          const alert = $(roleAlertSelector);
                          const alertForm = alert.find('form');
                          alert.removeAttr('hidden');
                          alert.modal('show');

                          await new Promise((resolve) => {
                            // On Submit...
                            alertForm.on('submit', async (evt) => {
                              evt.preventDefault();
                              resolve();
                            });
                          });
                          // Everything is resolved...
                          alert.modal('hide');
                          await new Promise((resolve) => {
                            alert.on('hidden.bs.modal', () => {
                              resolve();
                            });
                          });
                          alert.attr('hidden', 'hidden');
                        }
                        log.info(`Redirecting user to "${action}" because they have role "${role}"`);
                        await navigateTo(url.toString());
                        ruleMatch = true;
                      }
                      break;
                    }
                  }
                }
              }
            }
          },
          initial: true,
        },
      });
    }

    static autowireSelector() {
      return 'body';
    }
  }

  /**
   * Autowire.
   */
  const bodyAutowire = BodyComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    bodyAutowire, BodyComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  bodyComponentFactory,
};
