const { asyncIntervalWait } = require('../../utilities/asyncinterval');
const { getFieldValue } = require('../../utilities/fields');

function getConsentValues({ consentNames, form }) {
  return consentNames.reduce((values, name) => {
    const field = form.find(`input[name="${name}"]`);
    if(field.length) {
      const value = getFieldValue(field);
      values[name] = [ ...value, field ];
    }
    return values;
  }, {});
}

function processConsents({ form, disable, consentNames, consentCount }) {
  const values = getConsentValues({ form, consentNames });
  let unsatisfied = 0;
  for(let name in values) {
    const item = values[name];
    const [ value, meta, ele ] = item;
    if(ele.parent().attr('data-isvisible') === 'true') {
      if(value === false) {
        unsatisfied += 1;
      }
    }
  }
  if(unsatisfied > 0) {
    disable.prop('disabled', true).addClass('disabled');
  } else {
    disable.prop('disabled', false).removeClass('disabled');
  }
  return values;
}

async function pushConsents({ values, setConsents }) {
  const consents = {};
  for(let key in values) {
    const item = values[key];
    const [ value, meta ] = item;
    if(value === false) {
      continue;
    }
    let [ name, version ] = meta.text.split(/;/);
    version = version || 'v1';
    consents[name] = {
      version, value,
    };
  }
  await setConsents(consents);
}

async function serviceNotification({ log, login, selector, svcNotify, setConsents, getValueByRole }) {
  try {
    // Setup.
    login?.busy();
    let roleRedirect;
    // Check service notifications...
    const { data } = await svcNotify('account');
    if(!data?.notifications?.length) {
      log.trace('No log in service notifications found to resolve.');
      return false;
    }
    let consentCount = 0;
    let profileCount = 0;
    for(let notify of data.notifications) {
      if(notify.type === 'profile') {
        profileCount += 1;
      } else if(notify.type === 'consent') {
        consentCount += 1;
      }
    }
    const ele = $(selector);
    log.info(`There are ${consentCount} Consent service notifications that need resolution.`);
    // If there are consents to resolve...
    if(consentCount) {
      // More setup.
      const form = ele.find('form');
      const isModal = ele.hasClass('modal');
      const disable = form.find('button[type="submit"], input[type="submit"]');
      const consentNames = [...form.find('.ui-consent')].reduce((acc, ele) => {
        acc.push($(ele).attr('name'));
        return acc;
      }, []);
      ele.removeAttr('hidden');
      if(isModal) {
        ele.modal('show');
      } else {
        ele.show();
      }
      ele.find('.ui-scv-notify-item').hide();
      disable.prop('disabled', true).addClass('disabled');
      if(data.notifications.length === 0) {
        // If everything is ok, stop...
        return false;
      }
      for(let item of data.notifications) {
        if(item.type === 'consent') {
          const consent = ele.find(`.ui-scv-notify-item[data-consent="${item.reason[0]}"]`);
          consent.attr('data-isvisible', 'true').show();
          consent.find('.ui-consent').prop('checked', false);
        }
      }
      if(ele.find(`.ui-scv-notify-item[data-isvisible="true"]`).length === 0) {
        // If no notifications are displayed, ignore and keep going...
        return false;
      }
      // On Change...
      form.on('change', (evt) => {
        processConsents({ form, disable, consentNames, consentCount });
      });
      await new Promise((resolve) => {
        // On Submit...
        form.on('submit', async (evt) => {
          evt.preventDefault();
          const values = processConsents({ form, disable, consentNames, consentCount });
          if(values) {
            try {
              disable.prop('disabled', true).addClass('disabled');
              await pushConsents({ values, setConsents });
              resolve();
            } finally {
              disable.prop('disabled', false).removeClass('disabled');
            }
          }
        });
      });
      // Everything is resolved...
      if(isModal) {
        ele.modal('hide');
        await new Promise((resolve) => {
          ele.on('hidden.bs.modal', () => {
            resolve();
          });
        });
      }
      ele.attr('hidden', 'hidden');
    }

    // If there are profile items to resolve...
    log.info(`There are ${profileCount} Profile service notifications that need resolution.`);
    if(profileCount) {
      roleRedirect = roleRedirect ?? ele.attr('data-updateaccount');
    }

    // Get role redirect from login form if needed.
    roleRedirect = roleRedirect ?? login?.info?.successPath;

    // Process redirect.
    if(roleRedirect) {
      return getValueByRole(roleRedirect);
    }
    return false;
  } finally {
    login?.ready();
  }
}

module.exports = {
  serviceNotification,
};
