/**
 * Imports.
 */
const { debounce } = require('lodash');

/**
 * Notify Preferences component factory.
 */
function notifyPreferencesFactory(dyn) {
  const { log, controllers, BaseComponent } = dyn();
  const {
    getNotifyPreferences, setNotifyPreferences,
  } = controllers;

  /** Message context. */
  const context = 'notifypreferences';

  /**
   * NotifyPreferences Component.
   */
  class NotifyPreferencesComponent extends BaseComponent {
    async onMount({ attach, fields, onEvent, info }) {
      log.trace(`Mounting notify preferences component (#${this.id}).`);
      attach({
        form: ':self',
        disable: [
          ':self',
          'button',
        ],
        messages: '.ui-msg',
      });
      const preferences = await getNotifyPreferences();
      const notifyFields = $('input.notifypreference');
      notifyFields.each((idx, notifyField) => {
        const ele = $(notifyField);
        const name = ele.attr('name');
        const value = preferences[name];
        if(value && value.toLowerCase() === 'yes') {
          ele.prop('checked', true);
        } else {
          ele.prop('checked', false);
        }
      });

      const notifyPreferenceParents = $('input.notifypreference_parent');
      this.toggleNotifyPreferenceLock(notifyPreferenceParents);

      // Debounce preference updates for 2 seconds.
      const updateNotifyPreferences = debounce(async (preferences) => {
        await setNotifyPreferences(preferences);
      }, 1000);
      onEvent({
        'input.notifypreference_parent': {
          'change': async (evt) => {
            const target = $(evt.target);
            this.toggleNotifyPreferenceLock(target);
          },
        },
        'input.notifypreference': {
          'change': async (evt) => {
            const target = $(evt.target);
            const notifyName = target.attr('name');
            const notifyValue = target.prop('checked');
            preferences[notifyName] = !!notifyValue ? 'yes' : 'no';
            await updateNotifyPreferences(preferences);
          },
        },
        'a.keytoggle': {
          'click': (evt) => {
            const target = $(evt.target);
            if(target.is('span, a')) {
              evt.preventDefault();
              target.closest('label').click();
            }
          },
        },
      });
    }

    toggleNotifyPreferenceLock(targets) {
      targets.each((idx, ele) => {
        const target = $(ele);
        const channels = target.attr('class').match(/channel_(\d+)/);
        if(channels.length > 2) {
          console.warn('Multiple channels not supported on a single input.');
          return;
        }
        const channel = channels[1];
        const channelMethods = $(`.notifypreference_parent.channel_${channel}`);
        let enabled = false;
        channelMethods.each((idx, ele) => {
          if($(ele).prop('checked')) {
            enabled = true;
          }
        });
        const channelEle = $(`.notifypreference_child.channel_${channel}`);
        if(enabled) {
          channelEle.prop('disabled', false).parents('div.notifypreference_row').removeClass('notifypreference_disabled');
        } else {
          channelEle.prop('disabled', true).parents('div.notifypreference_row').addClass('notifypreference_disabled');
        }
      });
    }

    static autowireSelector() {
      return 'form.ui-notifypreferences';
    }
  }

  /**
   * Autowire.
   */
  const notifyPreferencesAutowire = NotifyPreferencesComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    notifyPreferencesAutowire, NotifyPreferencesComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  notifyPreferencesFactory,
};
