const { HTTP_OK } = require('../../utilities/httpcode');
const { codeOrError } = require('../../utilities/rest');

function consentsFactory(dyn) {
  const { apiFetch } = dyn();

  /**
   * Example:
   * {
   *   "accept-conditions": {
   *     "version": "v1",
   *     "value": true
   *   }
   * }
   */
  async function setConsents(consents) {
    const consentList = [];
    for (let name in consents) {
      const consent = consents[name];
      const versionString = (consent.version || 'v1').replace(/^v/, '');
      const version = Number(versionString);
      consentList.push({
        consentName: name,
        version,
        enabled: !!consent.value,
      });
    }
    const { data, status } = await apiFetch({
      method: 'PUT',
      path: '/api/v1/players/self/consents',
      body: consentList,
    });
    if (status !== HTTP_OK) {
      return codeOrError(data);
    }
    return { ok: true };
  };

  return {
    setConsents,
  };
}

module.exports = {
  consentsFactory,
};
