/**
 * @imarcsgroup/client:src/utilities/httpcode.js
 */

/**
 * HTTP Code constants.
 */
const HTTP_OK = 200;
const HTTP_CREATED = 201;
const HTTP_NO_CONTENT = 204;
const HTTP_MOVED_PERMANENTLY = 301;
const HTTP_FOUND = 302;
const HTTP_NOT_MODIFIED = 304;
const HTTP_BAD_REQUEST = 400;
const HTTP_UNAUTHORIZED = 401;
const HTTP_FORBIDDEN = 403;
const HTTP_NOT_FOUND = 404;
const HTTP_METHOD_NOT_ALLOWED = 405;
const HTTP_NOT_ACCESSABLE = 406;
const HTTP_TOO_MANY_REQUESTS = 429;
const HTTP_SERVER_ERROR = 500;
const HTTP_INTERNAL_SERVER_ERROR = 500; // Same as HTTP_SERVER_ERROR
const HTTP_NOT_IMPLEMENTED = 501;
const HTTP_BAD_GATEWAY = 502;
const HTTP_SERVICE_UNAVAILABLE = 503;
const HTTP_GATEWAY_TIMEOUT = 504;


/**
 * Exports.
 */
module.exports = {
  // 200s
  HTTP_OK,
  HTTP_CREATED,
  HTTP_NO_CONTENT,
  // 300s
  HTTP_MOVED_PERMANENTLY,
  HTTP_FOUND,
  HTTP_NOT_MODIFIED,
  // 400s
  HTTP_BAD_REQUEST,
  HTTP_UNAUTHORIZED,
  HTTP_FORBIDDEN,
  HTTP_NOT_FOUND,
  HTTP_METHOD_NOT_ALLOWED,
  HTTP_NOT_ACCESSABLE,
  HTTP_TOO_MANY_REQUESTS,
  // 500s
  HTTP_SERVER_ERROR, HTTP_INTERNAL_SERVER_ERROR,
  HTTP_NOT_IMPLEMENTED,
  HTTP_BAD_GATEWAY,
  HTTP_SERVICE_UNAVAILABLE,
  HTTP_GATEWAY_TIMEOUT,
};
