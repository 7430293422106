/**
 * @imarcsgroup/client:src/components/register/index.js
 */

const { DateTime } = require('luxon');
const { toBirthdate } = require('../../utilities/datetime');
const { isArray } = require('@theroyalwhee0/istype');
const { assembleAddress, showVerifyAddressNotFound, showVerifyAddressMatches } = require('../../helpers/address');

/**
 * Register component factory.
 */
function registerComponentFactory(dyn) {
  const { log, env, controllers, BaseComponent } = dyn();
  const { register, login, verifyLogin, navigateTo, addressVerifications } = controllers;

  /** Message context. */
  const context = 'register';

  /**
   * Register Component.
   */
  class RegisterComponent extends BaseComponent {
    onMount({ validation, attach, fields, onEvent, info, validateOnChange }) {
      log.trace(`Mounting register component (#${this.id}).`);

      if(env && env !== 'prod') {
        console.log('======== ATTACHING DEBUG FAST REGISTER ========');
        $('#register_first_name').on('dblclick', (evt) => {
          if(evt.shiftKey) {
            var myemail = `test.user+${Date.now()}@imarcsgroup.com`;
            $('#register_first_name').val('Test');
            $('#register_middle_name').val('Portal');
            $('#register_last_name').val('Luckzone');
            $('#register_suffix>option:eq(1)').attr('selected', true);
            $('#register_email').val(myemail);
            $('#register_confirmemail').val(myemail);
            $('#register_password').val('Test123456');
            $('#register_confirmpassword').val('Test123456');
            $('#register_address').val('2002 11th Ave N');
            $('#register_address2').val('');
            $('#register_city').val('Texas City');
            $('#register_zipcode').val('77590');
            $('#register_zipcode_addon').val('');
            $('#register_dob_mm>option:eq(1)').attr('selected', true);
            $('#register_dob_dd>option:eq(1)').attr('selected', true);
            $('#register_dob_yyyy').val('1981');
            $('#register_countrycode').val('1');
            $('#register_phone').val('555-555-5555');
            $('#register_consent_satisfies-age-requirement').prop('checked', true);
            $('#register_consent_within-jurisdiction-profile').prop('checked', true);
            $('#register_consent_name-and-likeness').prop('checked', true);
          }
        });
      }

      attach({
        form: ':self',
        first_name: '[name="first_name"]',
        middle_name: '[name="middle_name"]',
        last_name: '[name="last_name"]',
        suffix: '[name="suffix"]',
        email: '[name="email"]',
        email_confirm: '[name="email_confirm"]',
        password: '[name="password"]',
        password_confirm: '[name="password_confirm"]',
        address: '[name="address"]',
        address2: '[name="address2"]',
        city: '[name="city"]',
        state: '[name="state"]',
        zipcode: '[name="zipcode"]',
        zipcode_addon: '[name="zipcode_addon"]',
        birthday: '.ui-birthday',
        birthday_mm: '[name="birthday_mm"]',
        birthday_dd: '[name="birthday_dd"]',
        birthday_yyyy: '[name="birthday_yyyy"]',
        callingcode: '[name="callingcode"]',
        phone: '[name="phone"], [name="phone.1"], [name="phone.2"]',
        callingcode2: '[name="callingcode2"]',
        phone2: '[name="phone2"], [name="phone2.1"], [name="phone2.2"]',
        consents: '.ui-consent[type="checkbox"]',
        disable: [ ':self', 'input, select, button' ],
        submit: 'button[type="submit"], input[type="submit"]',
        messages: '.ui-msg',
      });

      fields({
        first_name: validation().required(),
        middle_name: validation().optional(),
        last_name: validation().required(),
        suffix: validation().optional(),
        email: validation().email().required(),
        email_confirm: validation().email().equalsInsensitive('email').required(),
        password: validation().password().required(),
        password_confirm: validation().password().equals('password').required(),
        address: validation().required(),
        address2: validation().optional(),
        city: validation().required(),
        state: validation().required(),
        zipcode: validation().required(),
        zipcode_addon: validation().optional(),
        birthday_mm: validation().required(),
        birthday_dd: validation().required(),
        birthday_yyyy: validation().required(),
        callingcode: validation().optional(),
        phone: validation().required().removeOnSerialize(/[^0-9]/g),
        callingcode2: validation().optional(),
        phone2: validation().optional().removeOnSerialize(/[^0-9]/g),
        '.consents': validation().checkbox().required(),
      });
      info({
        login: ['[data-login]'],
        for: ['[data-for]'],
        successPath: ['[data-success]'],
        addressVerification: ['[data-address-verification]'],
        minAge: () => Number(this.ele.find('.ui-birthday').attr('data-minage') || 18),
      });
      validateOnChange({ context });
      onEvent({
        form: {
          async reset(evt) {
            try {
              this.busy();
              this.clearMessages();
            } finally {
              this.ready();
            }
          },
          async submit(evt) {
            evt.preventDefault();
            try {
              this.busy();
              this.clearMessages();
              let { valid, serialized: values, meta, issues } = await this.validate();
              if(this.info.for) {
                values.for = this.info.for;
              }
              if(!issues.birthday) {
                // Combine and validate birthday.
                values.dob = toBirthdate(values.birthday_yyyy, values.birthday_mm, values.birthday_dd);
                if(isNaN(values.dob)) {
                  issues.birthday = {
                    key: 'birthday',
                    code: 'invalid',
                  };
                  valid = false;
                } else if(Number(values.birthday_yyyy) < 1900) {
                  issues.birthday = {
                    key: 'birthday',
                    code: 'too_old',
                  };
                  valid = false;
                } else {
                  log.trace(`Minimum age for registration is ${this.info.minAge}`);
                  const now = DateTime.utc();
                  const age = DateTime.fromMillis(values.dob).diff(now, 'years').years * -1;
                  if(age < this.info.minAge) {
                    issues.birthday = {
                      key: 'birthday',
                      code: 'too_young',
                      data: {
                        minAge: this.info.minAge,
                      },
                    };
                    valid = false;
                  }
                }
              }
              if(!valid) {
                // Combine birthday messages into a single message.
                if(issues.birthday_yyyy || issues.birthday_mm || issues.birthday_dd) {
                  let code;
                  if(issues.birthday_yyyy?.code === 'required' ||
                    issues.birthday_mm?.code === 'required' ||
                    issues.birthday_dd?.code === 'required'
                  ) {
                    code = 'required';
                  }
                  delete issues.birthday_yyyy;
                  delete issues.birthday_mm;
                  delete issues.birthday_dd;
                  issues.birthday = {
                    key: 'birthday',
                    code: code || 'invalid',
                  };
                }
                // Display messages.
                this.displayMessages({ context, issues });
                return false;
              }
              if(this.info.addressVerification) {
                const [
                  addressVerification, addressNotFound,
                  addressInvalidBox, addressMissingBox,
                ] = this.info.addressVerification.split('|');
                const address = assembleAddress(values);
                const { ok, matches, exactMatch } = await addressVerifications(address);
                if(ok) {
                  let results;
                  const firstMatch = matches[0];
                  if(exactMatch) {
                    results = {
                      selected: firstMatch,
                    };
                  } else {
                    if(firstMatch?.lookupCode === 'INVALID_BOX') {
                      results = await showVerifyAddressNotFound({ address, selector: addressInvalidBox });
                    } else if(firstMatch?.lookupCode === 'MISSING_BOX') {
                      results = await showVerifyAddressNotFound({ address, selector: addressMissingBox });
                    } else if(isArray(matches) && matches.length) {
                      results = await showVerifyAddressMatches({ address, matches, selector: addressVerification });
                    } else {
                      results = await showVerifyAddressNotFound({ address, selector: addressNotFound });
                    }
                  }
                  if((!results) || results.close) {
                    return false;
                  } else if(results.accept) {
                    values.address_accepted = true;
                  } else if(results.edit) {
                    this.fields.address.ele.first().scrollAndFocus();
                    return false;
                  } else if(results.selected) {
                    values.city = results.selected.city;
                    values.state = results.selected.state;
                    values.zipcode = results.selected.zipcode;
                    values.zipcode_addon = results.selected.zipcodeAddon;
                    results.selected.address.forEach((item, idx) => {
                      switch (idx) {
                        case 0: {
                          values.address = item;
                          break;
                        }
                        default: {
                          values.address2 = (values.address2 ? values.address2 + '\n' : '') + item;
                          break;
                        }
                      }
                    });
                  } else {
                    log.error({ results }, `Unhandled verify address results`);
                  }
                } else {
                  const code = 'error';
                  this.displayMessages({ message: [ context, code ] });
                  return false;
                }
              }
              const registerResults = await register(values);
              if(registerResults.code) {
                const { code } = registerResults;
                this.displayMessages({ message: [ context, code ] });
                return false;
              }
              if(this.info.login) {
                const results = await login(values.email, values.password);
                if(!results?.ok) {
                  const code = results?.code || 'error';
                  this.displayMessages({ message: [ context, code ] });
                  return false;
                } else {
                  await verifyLogin(results);
                }
              }
              await navigateTo(this.info.successPath);
              return true;
            } finally {
              this.ready();
            }
          },
        },
      });
    }

    static autowireSelector() {
      return 'form.ui-register';
    }
  }

  /**
   * Autowire.
   */
  const registerAutowire = RegisterComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    registerAutowire, RegisterComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  registerComponentFactory,
};
