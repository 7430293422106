function subscribeTillFactory(dyn) {
  const { subscribe } = dyn();
  return async function subscribeTill(selector, callback, perform) {
    let unsubscribe = () => { };
    const value = await new Promise((resolve) => {
      const sub = subscribe(selector, (value) => {
        if (callback(value)) {
          return resolve(value);
        }
      }, true);
      if (perform) {
        perform();
      }
      unsubscribe = sub.unsubscribe;
    });
    await unsubscribe();
    return value;
  };
}

module.exports = {
  subscribeTillFactory,
};
