function instantimglistFactory(dyn) {
  const { log, apiFetch } = dyn();
  
  async function instantimglist() {
    const { data, status } = await apiFetch({
      method: 'GET',
      path: `/api/v1/external/instants/resources`,
    });
    return data;
  };

  async function instantlookup(gamenumber) {
    const query = {
      gamenumber,
    };
    const { data, status } = await apiFetch({
      method: 'GET',
      path: `/api/v1/external/instants/game`,
      query,
    });
    return { data, status };
  };

  return {
    instantimglist,
    instantlookup,
  };
}

module.exports = {
  instantimglistFactory,
};
