/**
 * @imarcsgroup/client:src/strings/entrylist.js
 */

/**
 * Strings.
 */
const strings = {
  'entrylist.error': 'An error has occurred while trying to retrieve entry list.',
  'entrylist.service_unavailable': 'An error has occurred while trying to retrieve entry list.',
  'entrylist.no_entries': 'You do not have any entries in this series of draws.',
};

/**
 * Exports.
 */
module.exports = strings;
