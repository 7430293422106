/**
 * @imarcsgroup/client:src/components/claimform_standalone/index.js
 */

const { DateTime } = require('luxon');

/**
 * ClaimFormStandalone component factory.
 */
function claimFormStandaloneComponentFactory(dyn) {
  const { log, controllers, BaseComponent } = dyn();
  const { getClaim, getProfile } = controllers;
  let profile = false;

  async function populateClaimFormDetails(claimInfo) {
    const results = {};

    if(!profile) {
      profile = await getProfile();
    }

    const bodyEle = $('body');
    console.log(claimInfo);
    const claimDate = DateTime.fromMillis(claimInfo.claimDate).toFormat('MM/dd/yyyy');

    // Claim Information
    bodyEle.find('.ui-claimslist-claimform-details-claimid').text(claimInfo.id);
    bodyEle.find('.ui-claimslist-claimform-details-claimamount').text(claimInfo.totalPrizeValue);
    bodyEle.find('.ui-claimslist-claimform-details-ticketcount').text(claimInfo.numberOfTickets);
    bodyEle.find('.ui-claimslist-claimform-details-submitteddate').text(claimDate);

    // Player Information
    bodyEle.find('.ui-claimslist-claimform-details-firstname').text(profile.first_name);
    const middleNameEle = bodyEle.find('.ui-claimslist-claimform-details-middlename');
    if(profile.middle_name) {
      middleNameEle.text(profile.middle_name).parent().show();
    } else {
      middleNameEle.parent().hide();
    }
    bodyEle.find('.ui-claimslist-claimform-details-lastname').text(profile.last_name);
    const suffixEle = bodyEle.find('.ui-claimslist-claimform-details-suffix');
    if(profile.suffix) {
      suffixEle.text(profile.suffix).parent().show();
    } else {
      suffixEle.parent().hide();
    }
    bodyEle.find('.ui-claimslist-claimform-details-citizenship').text(profile.citizenship === 'RESIDENT' ? 'US Citizen/Resident Alien' : 'Non-US Citizen/Non-Resident Alien');
    const ssnEle = bodyEle.find('.ui-claimslist-claimform-details-ssn');
    if(profile.ssn) {
      ssnEle.text(`***-**-${profile.ssn.slice(-4)}`).parent().show();
    } else {
      ssnEle.parent().hide();
    }
    bodyEle.find('.ui-claimslist-claimform-details-birthdate').text(`${profile.birthday_mm}/${profile.birthday_dd}/${profile.birthday_yyyy}`);
    bodyEle.find('.ui-claimslist-claimform-details-country').text(profile.countryoforigin);

    // Mailing Address
    bodyEle.find('.ui-claimslist-claimform-details-address').text(profile.claims_address);
    bodyEle.find('.ui-claimslist-claimform-details-city').text(profile.claims_city);
    const claimsStateEle = bodyEle.find('.ui-claimslist-claimform-details-state');
    if(profile.claims_state) {
      claimsStateEle.text(profile.claims_state).parent().show();
    } else {
      claimsStateEle.parent().hide();
    }
    const claimsRegionEle = bodyEle.find('.ui-claimslist-claimform-details-county');
    if(profile.claims_region) {
      claimsRegionEle.text(profile.claims_region).parent().show();
    } else {
      claimsRegionEle.parent().hide();
    }

    // Would be profile.claims_zipcode but the whole zipcode is obfuscated.
    bodyEle.find('.ui-claimslist-claimform-details-zip').text('*****');
    bodyEle.find('.ui-claimslist-claimform-details-zipext').text(profile.claims_zipcode4 || '');

    // Contact
    bodyEle.find('.ui-claimslist-claimform-details-mobile').text(`***-***-${profile.phone.slice(-4)}`);
    const phone2Ele = bodyEle.find('.ui-claimslist-claimform-details-home');
    if(profile.phone2) {
      phone2Ele.text(`***-***-${profile.phone2.slice(-4)}`).show();
    } else {
      phone2Ele.hide();
    }
    bodyEle.find('.ui-claimslist-claimform-details-email').text(profile.email);

    // QR Code
    if(qrcodegen) {
      const { QrCode } = qrcodegen;
      const qrcEncoded = QrCode.encodeText(claimInfo.id, QrCode.Ecc.MEDIUM);
      const qrcSvg = QrCode.toSvgString(qrcEncoded);
      bodyEle.find('.ui-claimslist-claimform-details-qrcode svg').replaceWith(qrcSvg);
    }

    const onClose = new Promise((resolve) => {
      bodyEle.on('hidden.bs.modal', (evt) => {
        results.close = true;
        resolve();
      });
    });
    bodyEle.modal('show');
    await onClose;
    bodyEle.modal('hide');
    return results;
  }

  /**
   * ClaimsList Component.
   */
  class ClaimFormStandaloneComponent extends BaseComponent {
    async onMount() {
      log.trace(`Mounting standalone claim form component (#${this.id}).`);
      const urlParams = new URLSearchParams(window.location.search);
      const claimId = urlParams.get('claimid');
      const data = await getClaim(claimId);

      const { data: claims } = data;
      console.log('claims', claims);
      if(!claims) {
        log.warn(`Failed to retrieve claim ID: ${claimId}`);
      } else {
        const webClaim = claims.filter((claim) => claim.id === claimId)[0];
        populateClaimFormDetails(webClaim);
      }
    }

    static autowireSelector() {
      return '.ui-claimform-standalone';
    }
  }

  /**
   * Autowire.
   */
  const claimFormStandaloneAutowire = ClaimFormStandaloneComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    claimFormStandaloneAutowire, ClaimFormStandaloneComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  claimFormStandaloneComponentFactory,
};
