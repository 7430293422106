/**
 * @imarcsgroup/client:src/validation/index.js
 */

/**
 * Imports.
 */
const { isInteger, isString } = require('@theroyalwhee0/istype');
const { getFieldType } = require('../utilities/fields');
const { patternFactory, testerFactory, regExpFactory } = require('../utilities/regexp');

/**
 * Validation factory.
 */
function validationFactory() {
  /**
   * Validation.
   */
  class Validation {
    constructor() {
      this.meta = {};
      this.rules = {
        serialize: {},
      };
    }
    get validation() {
      return true;
    }
    sync(elements) {
      const count = elements.length;
      for(let idx = 0; idx < count; idx++) {
        const ele = $(elements.get(idx));
        const name = ele.attr('name');
        if(/\.\d+$/.test(name)) {
          // If this is a multi-part item...
          this.meta.names = this.meta.names || [];
          this.meta.names.push(name);
          // Max Length.
          const maxLen = Number(ele.attr('maxlength'));
          if(isInteger(maxLen)) {
            this.addMaxLen(maxLen);
          }
          // Max Length.
          const minLen = Number(ele.attr('data-minlen') || ele.attr('data-minlength'));
          if(isInteger(minLen)) {
            this.addMinLen(minLen);
          }
        } else {
          // Else it is the primary or only item...
          this.meta.name = name;
          // Type.
          const { type } = getFieldType(ele);
          this.field(type);
          // Required.
          if(ele.attr('required') === 'required') {
            this.required();
          }
          // Optional.
          if(ele.attr('data-optional') === 'true') {
            this.optional();
          }
          // Equals.
          const equals = ele.attr('data-equals');
          if(equals) {
            this.equals(equals);
          }
          const equalsInsensitive = ele.attr('data-equals-insensitive');
          if(equalsInsensitive) {
            this.equalsInsensitive(equalsInsensitive);
          }
          // Less Than.
          const lt = ele.attr('data-lt');
          if(lt) {
            this.lt(lt);
          }
          // Less Than or Equals.
          const lte = ele.attr('data-lte');
          if(lte) {
            this.lte(lte);
          }
          // Greater Than.
          const gt = ele.attr('data-gt');
          if(gt) {
            this.gt(gt);
          }
          // Greater Than or Equals.
          const gte = ele.attr('data-gte');
          if(gte) {
            this.gte(gte);
          }
          // Joiner.
          const joiner = ele.attr('data-joiner');
          if(joiner) {
            this.joiner(joiner);
          }
          // Patterns.
          const pattern = ele.attr('pattern');
          if(pattern) {
            this.pattern(pattern);
          }
          const dataPatterns = [];
          for(let idx = 0; idx <= 9; idx++) {
            const char = idx === 0 ? '' : '' + idx;
            const dataPattern = ele.attr(`data-pattern${char}`);
            if(dataPattern) {
              dataPatterns.push(dataPattern);
            }
          }
          if(dataPatterns && dataPatterns.length) {
            this.tester(dataPatterns);
          }
          // Allowed Characters.
          if(ele.attr('data-chars')) {
            this.chars(ele.attr('data-chars'));
          }
          if(ele.attr('data-on-change')) {
            const name = ele.attr('data-on-change');
            const fn = global[name];
            if(!fn) {
              throw new Error(`Can't find function "${name}" for data-on-change.`);
            }
            this.onChange(fn);
          }
          // Max Length.
          const maxLen = Number(ele.attr('maxlength'));
          if(isInteger(maxLen)) {
            this.maxLen(maxLen);
          }
          // Max Length.
          const minLen = Number(ele.attr('data-minlen') || ele.attr('data-minlength'));
          if(isInteger(minLen)) {
            this.minLen(minLen);
          }
          // Autocapitalize
          const autocapitalize = ele.attr('autocapitalize');
          if(autocapitalize) {
            this.autocapitalize(autocapitalize);
          }
          // Lookup.
          const lookup = ele.attr('data-lookup');
          if(lookup) {
            const lookupParams = ele.attr('data-lookup-params');
            this.lookup(lookup, lookupParams);
          }
        }
      }
      return this;
    }
    lookup(lookupName, lookupParams) {
      Object.assign(this.rules, {
        lookup: lookupName,
        lookupParams,
      });
      return this;
    }
    field(type) {
      switch (type) {
        case 'textarea':
        case 'input:hidden':
        case 'input:text': {
          return this.text();
        }
        case 'input:email': {
          return this.email();
        }
        case 'input:password': {
          return this.password();
        }
        case 'input:checkbox': {
          return this.checkbox();
        }
        case 'select': {
          return this.select();
        }
        default: {
          throw new Error(`Unsupported validation field type "${type}".`);
        }
      }
    }
    select() {
      Object.assign(this.rules, {
        type: 'select',
      });
      return this;
    }
    text() {
      Object.assign(this.rules, {
        type: 'text',
      });
      return this;
    }
    email() {
      Object.assign(this.rules, {
        type: 'email',
        pattern: /^.+@(?!\d+\.\d+\.\d+\.\d+).+\..+$/,
        trim: true,
        maxlen: 320,
        autocapitalize: false,
      });
      return this;
    }
    password() {
      Object.assign(this.rules, {
        type: 'password',
        maxlen: 200,
        autocapitalize: false,
      });
      return this;
    }
    checkbox() {
      Object.assign(this.rules, {
        type: 'checkbox',
      });
      return this;
    }
    pattern(value) {
      const pattern = value instanceof RegExp ? value : patternFactory(value);
      Object.assign(this.rules, {
        pattern,
      });
      return this;
    }
    tester(value) {
      const pattern = value instanceof RegExp ? value : testerFactory(value);
      Object.assign(this.rules, {
        pattern,
      });
      return this;
    }
    regexp(value) {
      const pattern = value instanceof RegExp ? value : regExpFactory(value);
      Object.assign(this.rules, {
        pattern,
      });
      return this;
    }
    joiner(value = '') {
      Object.assign(this.rules, {
        joiner: value,
      });
      return this;
    }
    optional(value = true) {
      let optionalAttach;
      const optional = value === true || value === false ? value : true;
      if(isString(value)) {

      }
      // NOTE: Optional is not the reverse of required.
      // Optional allows a ruleset to be short circuited.
      // If a optional value is empty then any furter rules are skipped.
      // This allows things like patterns or minlength to apply to a non-required field.
      Object.assign(this.rules, {
        optional,
        optionalAttach,
      });
      return this;
    }
    required(value = true) {
      Object.assign(this.rules, {
        required: !!value,
      });
      return this;
    }
    addMinLen(value) {
      Object.assign(this.rules, {
        minlen: (this.rules.minlen || 0) + Number(value),
      });
      return this;
    }
    addMaxLen(value) {
      Object.assign(this.rules, {
        maxlen: (this.rules.maxlen || 0) + Number(value),
      });
      return this;
    }
    minLen(value) {
      Object.assign(this.rules, {
        minlen: Number(value),
      });
      return this;
    }
    maxLen(value) {
      Object.assign(this.rules, {
        maxlen: Number(value),
      });
      return this;
    }
    autocapitalize(value = true) {
      let autocapitalize;
      switch (value) {
        case true: {
          autocapitalize = 'sentences';
          break;
        }
        case false: {
          autocapitalize = 'none';
          break;
        }
        default: {
          autocapitalize = '' + value;
        }
      }
      Object.assign(this.rules, {
        autocapitalize,
      });
      return this;
    }
    trim(value = true) {
      Object.assign(this.rules, {
        trim: !!value,
      });
      return this;
    }
    equals(name) {
      Object.assign(this.rules, {
        equals: '' + name,
      });
      return this;
    }
    equalsInsensitive(name) {
      Object.assign(this.rules, {
        equalsInsensitive: '' + name,
      });
      return this;
    }
    lt(name) {
      Object.assign(this.rules, {
        numeric: true,
        lt: '' + name,
      });
      return this;
    }
    lte(name) {
      Object.assign(this.rules, {
        numeric: true,
        lte: '' + name,
      });
      return this;
    }
    gt(name) {
      Object.assign(this.rules, {
        numeric: true,
        gt: '' + name,
      });
      return this;
    }
    gte(name) {
      Object.assign(this.rules, {
        numeric: true,
        gte: '' + name,
      });
      return this;
    }
    chars(value) {
      Object.assign(this.rules, {
        chars: '' + value,
      });
    }
    onChange(fn) {
      Object.assign(this.rules, {
        onChange: fn,
      });
    }
    removeOnSerialize(remove) {
      Object.assign(this.rules.serialize, {
        remove,
      });
      return this;
    }
  }
  return function validation() {
    return new Validation();
  };
}

/**
 * Imports.
 */
module.exports = {
  validationFactory,
};
