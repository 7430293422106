const { HTTP_OK, HTTP_NO_CONTENT } = require('../../utilities/httpcode');
const { codeOrError } = require('../../utilities/rest');

function messagesFactory(dyn) {
  const { apiFetch } = dyn();

  async function getMessages() {
    const { data, status } = await apiFetch({
      method: 'GET',
      path: '/api/v1/players/self/messages',
    });
    if(status !== HTTP_OK) {
      return codeOrError(data);
    }
    return data;
  }

  async function setMessages(messages) {
    const { data, status } = await apiFetch({
      method: 'PUT',
      path: '/api/v1/players/self/messages',
      body: messages,
    });
    if(status !== HTTP_NO_CONTENT) {
      return codeOrError(data);
    }
    return { ok: true };
  };

  return {
    getMessages,
    setMessages,
  };
}

module.exports = {
  messagesFactory,
};
