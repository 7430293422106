/**
 * @imarcsgroup/client:src/components/registerclaims/index.js
 */

const { DateTime } = require('luxon');
const { toBirthdate } = require('../../utilities/datetime');
const { isArray } = require('@theroyalwhee0/istype');
const { assembleAddress, showVerifyAddressNotFound, showVerifyAddressMatches } = require('../../helpers/address');

/**
 * Register Claims component factory.
 */
function registerClaimsComponentFactory(dyn) {
  const { log, env, controllers, BaseComponent } = dyn();
  const { register, login, verifyLogin, navigateTo, addressVerifications } = controllers;

  /** Message context. */
  const context = 'register';

  /**
   * Register Component.
   */
  class RegisterClaimsComponent extends BaseComponent {
    async onMount({ validation, attach, fields, onEvent, info, validateOnChange }) {
      log.trace(`Mounting Claims registration component (#${this.id}).`);

      if(env && env !== 'prod') {
        console.log('======== ATTACHING DEBUG FAST REGISTER ========');
        $('#register_first_name').on('dblclick', (evt) => {
          if(evt.shiftKey || evt.ctrlKey) {
            var myemail = `test.user+${Date.now()}@imarcsgroup.com`;
            $('#register_first_name').val('Test');
            $('#register_middle_name').val('Portal');
            $('#register_last_name').val('Claims');
            $('#register_suffix>option:eq(1)').attr('selected', true);
            $('#register_email').val(myemail);
            $('#register_confirmemail').val(myemail);
            $('#register_password').val('Test123456');
            $('#register_confirmpassword').val('Test123456');
            $('#register_ssn').val('123456789');
            $('#register_ssn_confirm').val('123456789');
            $('#register_claims_address').val('2002 11th Ave N');
            $('#register_claims_city').val('Texas City');
            $('#register_claims_zipcode').val('77590');
            $('#register_dob_mm>option:eq(1)').attr('selected', true);
            $('#register_dob_dd>option:eq(1)').attr('selected', true);
            $('#register_dob_yyyy').val('1981');
            $('#register_phone').val('555-555-5555');
            $('#register_consent_satisfies-age-requirement-claims').prop('checked', true);
            $('#register_consent_ssn-required-for-claims').prop('checked', true);
          }
          if(evt.shiftKey) {
            $('#register_citizenship').val('RESIDENT');
            $('#register_citizenship').change();
            $('#register_claims_country').val('US');
            $('#register_claims_country').change();
            $('#register_claims_state').val('TX');
            $('#register_claims_region').val('Harris');
            $('#register_countryoforigin').val('');
            $('#register_claims_province').val('');
          } else if(evt.ctrlKey) {
            $('#register_citizenship').val('NON_RESIDENT');
            $('#register_citizenship').change();
            $('#register_countryoforigin').val('BE');
            $('#register_claims_country').val('BE');
            $('#register_claims_country').change();
            $('#register_claims_province').val('Limburg');
            $('#register_claims_state').val('');
            $('#register_claims_region').val('');
          }
        });
      }

      attach({
        form: ':self',
        email: '[name="email"]',
        email_confirm: '[name="email_confirm"]',
        password: '[name="password"]',
        password_confirm: '[name="password_confirm"]',
        first_name: '[name="first_name"]',
        middle_name: '[name="middle_name"]',
        last_name: '[name="last_name"]',
        suffix: '[name="suffix"]',
        birthday: '.ui-birthday',
        birthday_mm: '[name="birthday_mm"]',
        birthday_dd: '[name="birthday_dd"]',
        birthday_yyyy: '[name="birthday_yyyy"]',
        phone: '[name="phone"], [name="phone.1"], [name="phone.2"]',
        phone2: '[name="phone2"], [name="phone2.1"], [name="phone2.2"]',
        citizenship: '[name="citizenship"]',
        ssn: '[name="ssn"]',
        ssn_confirm: '[name="ssn_confirm"]',
        countryoforigin: '[name="countryoforigin"]',
        claims_address: '[name="claims_address"]',
        claims_city: '[name="claims_city"]',
        claims_state: '[name="claims_state"]',
        claims_province: '[name="claims_province"]',
        claims_zipcode: '[name="claims_zipcode"]',
        claims_zipcode4: '[name="claims_zipcode4"]',
        claims_country: '[name="claims_country"]',
        claims_region: '[name="claims_region"]',
        consents: '.ui-consent[type="checkbox"]',
        disable: [ ':self', 'input, select, button' ],
        submit: 'button[type="submit"], input[type="submit"]',
        messages: '.ui-msg',
      });

      fields({
        email: validation().email().required(),
        email_confirm: validation().email().equals('email').required(),
        password: validation().password().required(),
        password_confirm: validation().password().equals('password').required(),
        first_name: validation().required(),
        middle_name: validation().optional(),
        last_name: validation().required(),
        suffix: validation().optional(),
        birthday_mm: validation().required(),
        birthday_dd: validation().required(),
        birthday_yyyy: validation().required(),
        phone: validation().required().removeOnSerialize(/[^0-9]/g),
        phone2: validation().optional().removeOnSerialize(/[^0-9]/g),
        citizenship: validation().optional(),
        ssn: validation().optional().removeOnSerialize(/[^0-9]/g),
        ssn_confirm: validation().optional().removeOnSerialize(/[^0-9]/g),
        countryoforigin: validation().optional(),
        claims_address: validation().required(),
        claims_city: validation().required(),
        claims_state: validation().optional(),
        claims_province: validation().optional(),
        claims_zipcode: validation().optional(),
        claims_zipcode4: validation().optional(),
        claims_country: validation().optional(),
        claims_region: validation().optional(),
        '.consents': validation().checkbox().required(),
      });
      info({
        login: ['[data-login]'],
        for: ['[data-for]'],
        successPath: ['[data-success]'],
        addressVerification: ['[data-address-verification]'],
        minAge: () => Number(this.ele.find('.ui-birthday').attr('data-minage') || 18),
      });
      validateOnChange({ context });
      await this.formState();
      onEvent({
        form: {
          async reset(evt) {
            try {
              this.busy();
              this.clearMessages();
            } finally {
              this.ready();
            }
          },
          async submit(evt) {
            evt.preventDefault();
            try {
              this.busy();
              this.clearMessages();
              let { valid, serialized: values, meta, issues } = await this.validate();
              if(this.info.for) {
                values.for = this.info.for;
              }
              if(!issues.birthday) {
                // Combine and validate birthday.
                values.dob = toBirthdate(values.birthday_yyyy, values.birthday_mm, values.birthday_dd);
                if(isNaN(values.dob)) {
                  issues.birthday = {
                    key: 'birthday',
                    code: 'invalid',
                  };
                  valid = false;
                } else if(Number(values.birthday_yyyy) < 1900) {
                  issues.birthday = {
                    key: 'birthday',
                    code: 'too_old',
                  };
                  valid = false;
                } else {
                  log.trace(`Minimum age for registration is ${this.info.minAge}`);
                  const now = DateTime.utc();
                  const age = DateTime.fromMillis(values.dob).diff(now, 'years').years * -1;
                  if(age < this.info.minAge) {
                    issues.birthday = {
                      key: 'birthday',
                      code: 'too_young',
                      data: {
                        minAge: this.info.minAge,
                      },
                    };
                    valid = false;
                  }
                }
              }
              if(!valid) {
                // Combine birthday messages into a single message.
                if(issues.birthday_yyyy || issues.birthday_mm || issues.birthday_dd) {
                  let code;
                  if(issues.birthday_yyyy?.code === 'required' ||
                    issues.birthday_mm?.code === 'required' ||
                    issues.birthday_dd?.code === 'required'
                  ) {
                    code = 'required';
                  }
                  delete issues.birthday_yyyy;
                  delete issues.birthday_mm;
                  delete issues.birthday_dd;
                  issues.birthday = {
                    key: 'birthday',
                    code: code || 'invalid',
                  };
                }
                // Display messages.
                this.displayMessages({ context, issues });
                return false;
              }
              if(this.info.addressVerification) {
                let result = { };
                if(values.claims_country === 'US' && values.claims_state === 'TX') {
                  const addressFields = {
                    address: values.claims_address,
                    state: values.claims_state,
                    city: values.claims_city,
                    zipcode: values.claims_zipcode,
                    zipcode4: values.claims_zipcode4,
                    county: values.claims_region,
                  };
                  const address = assembleAddress(addressFields);
                  result = await this.verifyAddress(address, true);
                  if(!result || result.close) {
                    return false;
                  }
                  if(result.accept) {
                    values.address_accepted = true;
                  } else if(result.edit) {
                    this.fields.claims_address.ele.first().scrollAndFocus();
                    return false;
                  } else {
                    values.claims_addressVerified = true;
                    values.claims_address = result.address;
                    values.claims_city = result.city;
                    values.claims_state = result.state;
                    values.claims_zipcode = result.zipcode;
                    values.claims_zipcode4 = result.zipcode4;
                    values.claims_region = result.county;
                  }
                }
              }
              const registerResults = await register(values);
              if(registerResults.code) {
                const { code } = registerResults;
                this.displayMessages({ message: [ context, code ] });
                return false;
              }
              if(this.info.login) {
                const results = await login(values.email, values.password);
                if(!results?.ok) {
                  const code = results?.code || 'error';
                  this.displayMessages({ message: [ context, code ] });
                  return false;
                } else {
                  await verifyLogin(results);
                }
              }
              await navigateTo(this.info.successPath);
              return true;
            } finally {
              this.ready();
            }
          },
        },
        citizenship: {
          'change': () => {
            this.citizenshipState();
          },
        },
        claims_country: {
          'change': () => {
            this.countryState();
          },
        },
      });
    }

    async formState() {
      await this.citizenshipState();
      await this.countryState();
    }

    async citizenshipState() {
      const citizenship = this.elements.citizenship;
      if(citizenship) {
        const value = citizenship.val();
        const form = this.elements.form;
        const coo = form.find('[name="countryoforigin"]');
        if(value !== 'NON_RESIDENT') {
          form.find('.countryoforigin').hide();
          if(!coo.prop('disabled')) {
            coo.val('');
          }
        } else {
          if(coo.val()) {
            coo.prop('disabled', true);
          }
          form.find('.countryoforigin').show();
        }
      }
    }

    async countryState() {
      const claims_country = this.elements.claims_country;
      if(claims_country) {
        const value = claims_country.val();
        const form = this.elements.form;

        const claimsStateField = form.find('[name="claims_state"]');
        if(value === 'US') {
          form.find('.claims_state').show();
          form.find('.claims_province').hide();
          form.find('[name="claims_province"]').val('');
          claimsStateField.attr('required', true);

          form.find('[name="claims_address"]').prop('placeholder', 'Address (USPS address format)');
          form.find('[name="claims_zipcode"]').prop('placeholder', 'ZIP Code');
          form.find('[name="claims_zipcode4"]').show();
          form.find('[name="claims_region"]').show();
        } else {
          form.find('.claims_province').show();
          form.find('.claims_state').hide();
          claimsStateField.attr('required', false).val('');

          form.find('[name="claims_address"]').prop('placeholder', 'Address');
          form.find('[name="claims_zipcode"]').prop('placeholder', 'Postal Code');
          form.find('[name="claims_zipcode4"]').hide().val('');
          form.find('[name="claims_region"]').hide().val('');
        }
      }
    }

    async verifyAddress(address, allowPobox) {
      const [
        addressVerification, addressNotFound,
        addressInvalidBox, addressMissingBox,
      ] = this.info.addressVerification.split('|');
      const { ok, matches, exactMatch } = await addressVerifications(address, allowPobox);
      if(ok) {
        let results;
        const firstMatch = matches[0];
        const userZipExt = address?.zipcode?.split('-')[1];
        const userCounty = address?.county;
        const countyMatch = !userCounty || firstMatch?.county.toLowerCase() === userCounty.toLowerCase();
        const zipExtMatch = !userZipExt || firstMatch?.zipcodeAddon === userZipExt;

        if(exactMatch && countyMatch && zipExtMatch) {
          results = {
            selected: firstMatch,
          };
        } else if(firstMatch?.lookupCode === 'INVALID_BOX') {
          results = await showVerifyAddressNotFound({ address, selector: addressInvalidBox });
        } else if(firstMatch?.lookupCode === 'MISSING_BOX') {
          results = await showVerifyAddressNotFound({ address, selector: addressMissingBox });
        } else if(isArray(matches) && matches.length) {
          results = await showVerifyAddressMatches({ address, matches, selector: addressVerification });
        } else {
          results = await showVerifyAddressNotFound({ address, selector: addressNotFound });
        }
        let verifiedAddress = {};
        if((!results) || results.close) {
          return false;
        } else if(results.accept) {
          verifiedAddress.accept = true;
        } else if(results.edit) {
          verifiedAddress.edit = true;
        } else if(results.selected) {
          verifiedAddress = {
            city: results.selected.city,
            state: results.selected.state,
            zipcode: results.selected.zipcode,
            zipcode4: results.selected.zipcodeAddon,
            county: results.selected.county,
          };
          results.selected.address.forEach((item, idx) => {
            switch (idx) {
              case 0: {
                verifiedAddress.address = item;
                break;
              }
              default: {
                verifiedAddress.address2 = (values.address2 ? values.address2 + ', ' : '') + item;
                break;
              }
            }
          });
        } else {
          log.error({ results }, `Unhandled verify address results`);
          return false;
        }
        return verifiedAddress;
      } else {
        log.error('Failed to reach address lookup service.');
        return false;
      }
    }

    static autowireSelector() {
      return 'form.ui-register-claims';
    }
  }

  /**
   * Autowire.
   */
  const registerClaimsAutowire = RegisterClaimsComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    registerClaimsAutowire, RegisterClaimsComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  registerClaimsComponentFactory,
};
