/**
 * Imports.
 */
const { isString, isObject, isArray, isNumber } = require('@theroyalwhee0/istype');

/**
 * Could this be a valid session?
 */
function isSession(session) {
  return !!(
    isObject(session) &&
    isString(session.type) &&
    isString(session.ident) &&
    isArray(session.roles) &&
    isObject(session.tokens) &&
    isObject(session.tokens.oauth) &&
    isObject(session.tokens.refresh) &&
    isString(session.tokens.oauth.key) &&
    isString(session.tokens.refresh.key) &&
    isNumber(session.tokens.oauth.expireTime) &&
    isNumber(session.tokens.refresh.expireTime) &&
    session.tokens.oauth.key.length > 1 &&
    session.tokens.refresh.key.length > 1 &&
    session.tokens.oauth.expireTime > 1 &&
    session.tokens.refresh.expireTime > 1
  );
}


/**
 * Is the OAuth token expired?
 * Or will it expire within given milliseconds.
 */
function isOAuthExpired(session, within=0) {
  const expireTime = session?.tokens?.oauth?.expireTime;
  const now = Date.now() + within;
  return !(isNumber(expireTime) && expireTime >= now);
}

/**
 * Is the Refresh token expired?
 * Or will it expire within given milliseconds.
 */
function isRefreshExpired(session, within=0) {
  const expireTime = session?.tokens?.refresh?.expireTime;
  const now = Date.now() + within;
  return !(isNumber(expireTime) && expireTime >= now);
}

/**
 * Exports.
 */
module.exports = {
  isSession,
  isOAuthExpired,
  isRefreshExpired,
};
