/**
 * @imarcsgroup/client:src/components/forgotpassword/index.js
 */

/**
 * ForgotPassword component factory.
 */
function forgotPasswordComponentFactory(dyn) {
  const { log, controllers, BaseComponent } = dyn();
  const { forgotPassword, navigateTo } = controllers;

  const context = 'forgotpassword';

  /**
   * ForgotPassword Component.
   */
  class ForgotPasswordComponent extends BaseComponent {
    onMount({ validation, attach, fields, info, onEvent, validateOnChange }) {
      log.trace(`Mounting forgot-password component (#${this.id}).`);
      attach({
        form: ':self',
        email: 'input[name="email"]',
        disable: [ ':self', 'input, select, button' ],
        submit: 'button[type="submit"], input[type="submit"]',
        messages: '.ui-msg',
      });
      fields({
        email: validation().email().required(),
      });
      info({
        successPath: ['[data-success]'],
      });
      validateOnChange({ context });
      onEvent({
        form: {
          async submit(evt) {
            evt.preventDefault();
            try {
              this.busy();
              this.clearMessages();
              const { valid, values, meta, issues } = await this.validate();
              if(!valid) {
                this.displayMessages({ context, issues });
                return false;
              }
              const { email } = values;
              const { data, status } = await forgotPassword(email);
              if(status === 401) {
                const code = data?.code || 'error';
                this.displayMessages({ message: [ context, code ] });
                return false;
              }
              navigateTo(this.info.successPath);
              return true;
            } finally {
              this.ready();
            }
          },
        },
      });
    }

    static autowireSelector() {
      return 'form.ui-forgotpassword';
    }
  }

  /**
   * Autowire.
   */
  const forgotPasswordAutowire = ForgotPasswordComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    forgotPasswordAutowire, ForgotPasswordComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  forgotPasswordComponentFactory,
};
