const { DateTime } = require('luxon');


function toBirthdate(year, month, day) {
  // DOB Timestamp in GMT+UTC.
  month = ('' + month).padStart(2, '0');
  day = ('' + day).padStart(2, '0');
  const dob = DateTime.fromISO(`${year}-${month}-${day}T00:00:00Z`);
  return +dob;
}


module.exports = {
  toBirthdate,
};
