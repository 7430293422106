/**
 * Imports.
 */
const { isArray, isString, isObject } = require('@theroyalwhee0/istype');
const { HTTP_OK, HTTP_SERVICE_UNAVAILABLE } = require('../../utilities/httpcode');
const { codeOrError } = require('../../utilities/rest');

/**
 * Constants.
 */
const svcNotifyHeader = 'x-svc-notify';

/**
 * SvcNotify controller factory.
 * @param {DynastyDepends} dyn Dynasty depends function.
 * @returns {object} The object with built functions in it.
 */
async function scvNotifyFactory(dyn) {
  const { apiFetch, siteId, clientId, dispatch, actions } = dyn();
  const { sessionFromTokens } = actions;
  async function svcNotify(services) {
    const query = {};
    if(isString(services)) {
      query['service'] = services;
    } else if(isArray(services)) {
      query['service'] = services.slice(0);
    }
    const { data } = await apiFetch({
      self: true,
      method: 'GET',
      path: '/api/v1/players/service-notifications',
      query,
    });
    return { ok: true, data };
  };

  return {
    svcNotify,
  };
}

/**
 * Exports.
 */
module.exports = {
  scvNotifyFactory,
};
