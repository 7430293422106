function filterValue(value, rules) {
  if ('replace' in rules && rules.replace) {
    value = value.replace(rules.replace, '');
  }
  if ('trim' in rules && rules.trim === true) {
    value = value.trim();
  }
  return value;
}

module.exports = {
  filterValue,
};
