/**
 * Imports.
 */
const { HTTP_UNAUTHORIZED, HTTP_FORBIDDEN } = require('../../utilities/httpcode');

/**
 * Header names.
 */
const rolesUpdatedHeader = 'x-roles-updated';

/**
 * API fetch factory.
 */
function apiFetchFactory(dyn) {
  const { log, actions, dispatch, subscribeTill, apiFetchCore, refreshSession: refreshSessionController } = dyn();
  const { refreshSession } = refreshSessionController;
  const { sessionLogOut } = actions;
  return async function apiFetch({ method, path, query, body, self = 'auto', cache = false, cacheBuster = false }) {
    const { status, data, headers, url, res } = await apiFetchCore({ method, path, query, body, self, cache, cacheBuster });
    const isOAuth = /^\/api\/v1\/oauth\//.test(url.pathname);
    if((!isOAuth) && self && (status === HTTP_UNAUTHORIZED || status === HTTP_FORBIDDEN)) {
      // If a self endpoint and there is an auth error and this is not dealing with an oauth endpoint...
      await subscribeTill((_) => _.session, (session) => !session.roles.includes('@access'), () => {
        dispatch(sessionLogOut());
      });
    } else if(rolesUpdatedHeader in headers && '' + headers[rolesUpdatedHeader] === '1') {
      log.debug('Refreshing session due to role changes.');
      await refreshSession();
    }
    return { status, data, headers, res };
  };
}

/**
 * Exports.
 */
module.exports = {
  apiFetchFactory,
};
