/**
 * @imarcsgroup/client:src/userdata/actions.js
 */

/**
 * Imports.
 */
const { createReducer } = require('@reduxjs/toolkit');
const { userdataClear, userdataSet } = require('./actions');

/**
 * Initial State.
 */
const initialState = {
};

/**
 * Reducer.
 */
const userdataReducer = createReducer(initialState, {
  [userdataClear]: (state, _action) => {
    return initialState;
  },
  [userdataSet]: (state, action) => {
    state.user = state.user || {};
    Object.assign(state.user, action.payload);
  },
});

/**
 * Exports.
 */
module.exports = {
  userdata: userdataReducer,
};
