/**
 * @imarcsgroup/client:src/api/index.js
 */

/**
 * API factory.
 */
function apiFactory({ log, global, defaultStrings, VERSION }) {
  const strings = Object.assign({}, defaultStrings);
  const $$ = {
    VERSION, log,
    setup: {
      library: true,
    },
    strings(value) {
      return Object.assign(strings, value);
    },
  };
  Object.defineProperty(global, '$$', {
    get() {
      return $$;
    },
  });
  return $$;
}

/**
 * Exports.
 */
module.exports = {
  apiFactory,
};
