const TOKEN_TYPE = [
  'session',
  'refresh',
  'reset',
];

function verifyTokenFactory(dyn) {
  const { apiFetch } = dyn();

  async function verifyToken(token, type) {
    if(!TOKEN_TYPE.includes(type)) {
      throw new Error(`Token type "${type}" not recognized.`);
    }
    const query = { [type]: token };
    const { data, status } = await apiFetch({
      method: 'GET',
      path: `/api/v1/oauth/tokens/verify`,
      query,
    });
    return { data, status };
  };

  return {
    verifyToken,
  };
}

module.exports = {
  verifyTokenFactory,
};
