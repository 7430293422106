/**
 * Imports.
 */
const { HTTP_OK } = require('../../utilities/httpcode');

/**
 * RefreshSession controller factory.
 * NOTE: This controller is special as it can be called from apiFetch, violating the normal access controller rules.
 * @param {DynastyDepends} dyn Dynasty depends function.
 * @returns {object} The object with built functions in it.
 */
async function refreshSessionFactory(dyn) {
  const { log, apiFetchCore, getState, dispatch, actions, siteId, clientId, subscribeTill } = dyn();
  const { sessionLogOut, sessionFromTokens } = actions;

  async function refreshSession() {
    let session = getState((_) => _.session);

    const refreshToken = session?.tokens?.refresh?.key;

    // Refresh Session Token.
    const { data, status } = await apiFetchCore({
      method: 'PUT',
      path: `/api/v1/oauth/self/tokens/`,
      body: {
        siteId, clientId,
        token: refreshToken,
      },
    });

    if(status !== HTTP_OK) {
      log.info(`Unable to refresh session token. Logging out.`);
      await subscribeTill((_) => _.session, (session) => !session.roles.includes('@access'), () => {
        dispatch(sessionLogOut());
      });
      return { ok: true };
    }

    // Setup session in store.
    dispatch(sessionFromTokens(data));
    log.info(`Session tokens refreshed.`);
    return { ok: true };
  };

  return {
    refreshSession,
  };
}

/**
 * Exports.
 */
module.exports = {
  refreshSessionFactory,
};
