/**
 * Components factory.
 */
async function componentsFactory(dyn) {
  const { log, jq, componentFactories, builtComponents, ...rest } = dyn();
  const attach = { log, $:jq, jq, ...rest };
  const componentList = {};
  const factoryDyn = (obj) => {
    if(obj) {
      Object.assign(obj, attach);
    }
    return attach;
  };
  for(let key in componentFactories) {
    const factory = componentFactories[key];
    const component = await factory(factoryDyn);
    Object.assign(componentList, component);
  }
  if(builtComponents) {
    for(let key in builtComponents) {
      const component = builtComponents[key];
      Object.assign(componentList, component);
    }
  }
  const count = Object.keys(componentList).length;
  log.trace(`${count} components attached.`);
  return componentList;
}

/**
 * Exports.
 */
module.exports = {
  componentsFactory,
};
