const { HTTP_OK, HTTP_NO_CONTENT } = require('../../utilities/httpcode');
const { codeOrError } = require('../../utilities/rest');

function claimsSetoffsFactory(dyn) {
  const { apiFetch, claimsFetch } = dyn();

  async function claimsSetoffsDownload(token) {
    const query = { token };
    const { data, status, headers, res } = await apiFetch({
      method: 'GET',
      path: `/api/v1/external/setoffs`,
      query,
    });
    if(status !== HTTP_OK) {
      return codeOrError(data);
    }

    const filename = headers['x-file-name'][0];
    const blob = await res.blob();
    const url = URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    a.remove();
    return { ok: true };
  };

  async function claimsSetoffsEmail(claimId) {
    const { data, status } = await claimsFetch({
      self: true,
      method: 'POST',
      path: `/api/v1/mobile-claims/players/self/claims/setoffsemail`,
      body: { id: claimId },
    });
    if(status !== HTTP_OK && status !== HTTP_NO_CONTENT) {
      return codeOrError(data);
    }
    return {
      ok: true,
    };
  };

  return {
    claimsSetoffsDownload,
    claimsSetoffsEmail,
  };
}

module.exports = {
  claimsSetoffsFactory,
};
