const { isArray } = require('@theroyalwhee0/istype');

/**
 * String table factory.
 */
function stringsFactory(dyn) {
  const { log, strings } = dyn();

  function simpleTemplate(value='', data={}) {
    const text = value.replace(/\{\{([a-zA-Z\.]+)\}\}/g, (match, key, idx) => {
      return data[key] || '-';
    });
    return text;
  }

  function upperFirst(value) {
    if(typeof value === 'string') {
      return value.slice(0, 1).toUpperCase() + value.slice(1);
    }
    return value;
  }

  function findString(lookups, strs) {
    if(!strs) {
      strs = strings();
    }
    let source;
    let string;
    for(let idx = 0; idx < lookups.length; idx++) {
      const item = lookups[idx];
      const key = typeof item === 'string' ? item : item.message;
      if(key in strs) {
        source = item;
        string = strs[key];
        break;
      }
    }
    return {
      source,
      string,
      ok: string !== undefined,
    };
  }

  function guessString(lookups) {
    const string = {
      text: 'Unable to find error message text.',
      type: 'error',
      meta: { },
      guess: true,
    };
    if(lookups.length > 0) {
      const source = lookups[0];
      if(source && source.message) {
        log.warn(`Guessing message for "${source.message}"`);
        let [ context, key, code ] = source.message.split('.');
        if(key) {
          code = code || 'invalid';
          const textKey = upperFirst(key).replace(/_/g, ' ');
          const textCode = code.replace(/_/g, ' ');
          const text = `${textKey} is ${textCode}.`;
          string.text = text;
          string.ok = true;
          Object.assign(string.meta, { context, key, code });
          return { string, source };
        }
      }
    }
  }

  function getString(lookups, data) {
    lookups = isArray(lookups) ? lookups : [lookups];
    let results = findString(lookups);
    if(!results.ok) {
      results = guessString(lookups);
    }
    if(results && results.string) {
      let { string } = results;
      if(typeof string === 'string') {
        string = { text: string };
      } else {
        string = Object.assign({}, string);
      }
      if(!('type' in string)) {
        string.type = 'error';
      }
      if(data) {
        string.text = simpleTemplate(string.text, data);
      }
      return string;
    }
    return '';
  }
  return {
    getString,
    findString,
  };
}


/**
 * Exports.
 */
module.exports = {
  stringsFactory,
};
