function forgotPasswordFactory(dyn) {
  const { apiFetch } = dyn();

  async function forgotPassword(emailAddress) {
    const { data, status } = await apiFetch({
      method: 'PUT',
      path: `/api/v1/players/forgotten-password`,
      body: { emailAddress },
    });
    return { data, status };
  };

  return {
    forgotPassword,
  };
}

module.exports = {
  forgotPasswordFactory,
};
