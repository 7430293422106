/**
 * Controllers factory.
 */
async function controllersFactory(dyn) {
  const { log, controllerFactories, builtControllers, ...rest } = dyn();
  const controllersList = {};
  const attach = {
    // NOTE: controllers is exposed to controllers, but it is not fully
    // populated at this point. Accessing it during the factory phase will
    // likely to lead to bugs.
    ...rest,
    log,
    controllers: controllersList,
  };
  const factoryDyn = (obj) => {
    if(obj) {
      Object.assign(obj, attach);
    }
    return attach;
  };
  for(let key in controllerFactories) {
    const factory = controllerFactories[key];
    const controller = await factory(factoryDyn);
    Object.assign(controllersList, controller);
  }
  if(builtControllers) {
    for(let key in builtControllers) {
      const controller = builtControllers[key];
      Object.assign(controllersList, controller);
    }
  }
  const count = Object.keys(controllersList).length;
  log.trace(`${count} controllers attached.`);
  return controllersList;
}

/**
 * Exports.
 */
module.exports = {
  controllersFactory,
};
