const { isFunction } = require('@theroyalwhee0/istype');

function lookupsFactory(dyn) {
  // NOTE: controllers is exposed to controllers, but it is not fully
  // populated at this point. Accessing it during the factory phase will
  // likely to lead to bugs.
  const { controllers } = dyn();

  async function lookups(value, lookupName, lookupParams) {
    const controllerName = `${lookupName}Lookup`;
    const lookup = controllers[controllerName];
    if(!isFunction(lookup)) {
      throw new Error(`Unrecognized lookup "${lookupName}".`);
    }
    const params = new URLSearchParams(lookupParams);
    const results = await lookup(value, params);
    return results;
  };

  return {
    lookups,
  };
}

module.exports = {
  lookupsFactory,
};
