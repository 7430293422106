const validTypes = [
  'input:hidden',
  'input:text',
  'input:email',
  'input:password',
  'input:checkbox',
  'textarea',
  'select',
];

function getFieldType(field) {
  const tag = field.prop('nodeName')?.toLowerCase();
  const info = {
    tag,
    type: undefined,
    kind: {
      tag,
    },
  };
  if(tag === 'input') {
    const type = field.attr('type');
    info.type = `input:${type}`;
    info.kind.type = type;
  } else if(tag === 'textarea') {
    info.type = 'textarea';
  } else if(tag === 'select') {
    info.type = 'select';
  }
  if(!validTypes.includes(info.type)) {
    const name = field.attr('name') || '?';
    const error = new Error(`Unsupported field type "${tag}" "${info.type}" for "${name}".`);
    error.field = field;
    throw error;
  }
  return info;
}

function getFieldValue(field) {
  if(field.length > 1) {
    let value = '';
    let meta = [];
    for(let idx = 0; idx < field.length; idx++) {
      const ele = $(field.get(idx));
      const [ itemValue, itemMeta ] = getFieldValue(ele);
      value += itemValue;
      meta.push(itemMeta);
    }
    return [ value, meta ];
  } else {
    const { type } = getFieldType(field);
    switch (type) {
      case 'input:checkbox': {
        const value = field.is(':checked');
        const text = field.val();
        return [ value, { text }];
      }
      case 'select': {
        const value = field.val();
        return [value];
      }
      case 'textarea':
      case 'input:hidden':
      case 'input:password':
      case 'input:email':
      case 'input:text': {
        const value = field.val();
        return [value];
      }
      default: {
        throw new Error(`Unable to get field value. Unsupported field type "${type}"`);
      }
    }

  }
}

function setFieldValue(field, value) {
  if(field.length > 1) {
    for(let idx = 0; idx < field.length; idx++) {
      const ele = $(field.get(idx));
      setFieldValue(ele, value[idx]);
    }
  } else {
    const { type } = getFieldType(field);
    switch (type) {
      case 'select':
      case 'textarea':
      case 'input:password':
      case 'input:email':
      case 'input:text':
      case 'input:hidden': {
        field.val(value);
        break;
      }
      case 'input:checkbox': {
        if(!!value) {
          field.prop('checked', true);
        } else {
          field.prop('checked', false);
        }
        break;
      }
      default: {
        throw new Error(`Unable to get field value. Unsupported field type "${type}"`);
      }
    }
  }
}

module.exports = {
  getFieldType,
  getFieldValue,
  setFieldValue,
};
