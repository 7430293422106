function claimslistFactory(dyn) {
  const { claimsFetch } = dyn();

  async function claimslist({ sortColumns = 'createdat', sortOrder = 'desc' } = {}) {
    const query = {
      'sortColumn': sortColumns,
      'sortOrder': sortOrder,
    };

    const { data, status } = await claimsFetch({
      self: true,
      method: 'GET',
      path: `/api/v1/mobile-claims/players/self/claims`,
      query,
    });

    return { data, status };
  };

  async function getClaim(id) {
    const { data, status } = await claimsFetch({
      self: true,
      method: 'GET',
      path: `/api/v1/mobile-claims/players/self/claims`,
      query: { id },
    });
    return { data, status };
  }

  return {
    claimslist, getClaim,
  };
}

module.exports = {
  claimslistFactory,
};
