const { createSelector } = require('@reduxjs/toolkit');

function getStateFactory(dyn) {
  const { store } = dyn();
  return function getState(selector) {
    const stateSelector = createSelector(selector, (_) => _);
    const state = store.getState();
    return stateSelector(state);
  };
}

module.exports = {
  getStateFactory,
};
