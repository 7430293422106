function zipcodeLookupFactory(dyn) {
  const { apiFetch } = dyn();

  async function zipcode(params) {
    const query = params ?? new URLSearchParams();
    const { status, data } = await apiFetch({
      method: 'GET',
      path: `/api/v1/kyc/postalcode`,
      query,
    });
    let result;
    if(status >= 200 && status <= 299) {
      result = { data };
    } else {
      result = { data: { skip: true } };
    }
    return result;
  };

  async function zipcodeLookup(value, params) {
    params.set('postalcode', value);
    const { data } = await zipcode(params);
    const found = !!(data?.postalCodes?.includes(value)) || !!data.skip;
    return { found };
  };

  return {
    zipcode,
    zipcodeLookup,
  };
}

module.exports = {
  zipcodeLookupFactory,
};
