const { HTTP_OK } = require('../../utilities/httpcode');
const { codeOrError } = require('../../utilities/rest');

function submissionsFactory(dyn) {
  const { log, apiFetch } = dyn();

  async function selfSubmissions({ url, drawConfigId, pagesize } = {}) {
    const request = {
      method: 'GET',
      path: url || `/api/v1/second-chance/players/self/submissions`,
    };
    if(!url) {
      request.query = {
        'draw-config-ids': drawConfigId,
        'sort-columns': 'createdat',
        'sort-orders': 'desc',
      };
      if(pagesize) {
        request.query.pagesize = pagesize;
      }
    }
    const { data, status } = await apiFetch(request);
    if(status !== 200) {
      return codeOrError(data);
    }
    return { ok: true, data };
  };

  async function createSubmission({ tickets }) {
    log.trace(`Submitting ticket "${tickets.join(', ')}".`);
    const { data, status } = await apiFetch({
      method: 'POST',
      path: `/api/v1/second-chance/players/self/submissions`,
      body: {
        'tickets': tickets,
      },
    });
    if(status !== HTTP_OK) {
      return codeOrError(data);
    }
    return {
      data,
    };
  }

  return {
    selfSubmissions,
    createSubmission,
  };
}

module.exports = {
  submissionsFactory,
};
