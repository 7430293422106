/**
 * Imports.
 */
const componentFactories = {
  ...require('./body'),
  ...require('./closeaccount'),
  ...require('./drawconfiglist'),
  ...require('./entrylist'),
  ...require('./feedback'),
  ...require('./forgotpassword'),
  ...require('./login'),
  ...require('./logout'),
  ...require('./nav'),
  ...require('./passwordupdate'),
  ...require('./profile'),
  ...require('./profilenotification'),
  ...require('./promotions'),
  ...require('./register'),
  ...require('./registernotification'),
  ...require('./registerclaims'),
  ...require('./registercombined'),
  ...require('./resetpassword'),
  ...require('./ticketentry'),
  ...require('./userdata'),
  ...require('./verifyemail'),
  ...require('./claimssetoffsdownload'),
  ...require('./notifypreferences'),
  ...require('./messages'),
  ...require('./unsubscribe'),
  ...require('./claimentry'),
  ...require('./claimformstandalone'),
  ...require('./bridge'),
};
/**
 * Exports.
 */
module.exports = {
  componentFactories,
};
