function drawconfiglistFactory(dyn) {
  const { apiFetch } = dyn();

  async function drawconfiglistOpen({ pagesize } = {}) {
    return drawconfiglist({ pagesize, state: 'open' });
  }

  async function drawconfiglistVisible({ pagesize } = {}) {
    return drawconfiglist({ pagesize, state: 'visible' });
  }

  async function drawconfiglist({ pagesize, state = 'visible', sortColumns = 'ordering' } = {}) {
    const query = {
      state,
      'sort-columns': sortColumns,
    };
    if(pagesize) {
      query.pagesize = pagesize;
    }
    const { data, status } = await apiFetch({
      method: 'GET',
      path: `/api/v1/second-chance/draw-config`,
      query,
    });
    return { data };
  };

  return {
    drawconfiglist,
    drawconfiglistOpen,
    drawconfiglistVisible,
  };
}

module.exports = {
  drawconfiglistFactory,
};
