/**
 * @imarcsgroup/client:src/api/start.js
 */

/**
 * Imports.
 */
const { waitForDomEvent } = require('../utilities/dom');

/**
  * Constants.
  */
const DEFAULT_TIMEOUT = 60 * 1000; // 60 seconds.

/**
 * API Start factory.
 */
async function apiStartFactory(dyn) {
  const { log, timeOut=DEFAULT_TIMEOUT } = dyn();
  await waitForDomEvent(global, 'api-setup-start', timeOut);
  log.debug('API start triggered.');
}

/**
 * Exports.
 */
module.exports = {
  apiStartFactory,
};
