/**
 * @imarcsgroup/client:src/components/passwordupdate/index.js
 */

/**
 * PasswordUpdate component factory.
 */
function passwordUpdateComponentFactory(dyn) {
  const { log, controllers, BaseComponent } = dyn();
  const { passwordUpdate, navigateTo } = controllers;

  const context = 'passwordupdate';

  /**
   * PasswordUpdate Component.
   */
  class PasswordUpdateComponent extends BaseComponent {
    onMount({ validation, attach, fields, info, onEvent, validateOnChange }) {
      log.trace(`Mounting password-update component (#${this.id}).`);
      attach({
        form: ':self',
        old_password: 'input[name="old_password"]',
        new_password: 'input[name="new_password"]',
        password_confirm: 'input[name="password_confirm"]',
        disable: [ ':self', 'input, select, button' ],
        submit: 'button[type="submit"], input[type="submit"]',
        messages: '.ui-msg',
      });
      info({
        successPath: ['[data-success]'],
      });
      fields({
        old_password: validation().password().required(),
        new_password: validation().password().required(),
        password_confirm: validation().password().equals('new_password').required(),
      });
      validateOnChange({ context });
      onEvent({
        form: {
          async submit(evt) {
            evt.preventDefault();
            try {
              this.busy();
              this.clearMessages();
              const { valid, values, meta, issues } = await this.validate();
              if(!valid) {
                this.displayMessages({ context, issues });
                return false;
              }
              const { old_password, new_password } = values;
              const results = await passwordUpdate(old_password, new_password);
              if(!results?.ok) {
                const code = results?.code || 'error';
                this.displayMessages({ message: [ context, code ] });
                return false;
              }
              await navigateTo(this.info.successPath);
              return true;
            } finally {
              this.ready();
            }
          },
        },
      });
    }

    static autowireSelector() {
      return 'form.ui-passwordupdate';
    }
  }

  /**
   * Autowire.
   */
  const passwordUpdateAutowire = PasswordUpdateComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    passwordUpdateAutowire, PasswordUpdateComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  passwordUpdateComponentFactory,
};
