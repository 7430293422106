/**
 * @imarcsgroup/client:src/components/promotions/index.js
 */

const { isInteger } = require('@theroyalwhee0/istype');

/**
 * Promotions component factory.
 */
function promotionsComponentFactory(dyn) {
  const { log, controllers, BaseComponent } = dyn();
  const { drawconfiglistOpen } = controllers;

  /**
   * Promotions Component.
   */
  class PromotionsComponent extends BaseComponent {

    build({ drawConfigs }) {
      const gameNumMapping = {};
      let globalTicketIndex = 0;
      let currentPromotion;
      // If tickets toggle button, set it up...
      if(this.elements?.toggleTickets?.length > 0) {
        const initialState = this.elements.toggleTickets.attr('data-state');
        this.elements.toggleTickets.on('click', (evt) => {
          evt.preventDefault();
          const currentState = this.elements.toggleTickets.attr('data-state');
          const nextState = currentState === 'hide' ? 'show' : 'hide';
          if(nextState === 'hide') {
            this.elements.toggleTickets.find('.ui-hide-ticket').show();
            this.elements.toggleTickets.find('.ui-show-ticket').hide();
            this.elements.tickets.show();
          } else {
            this.elements.toggleTickets.find('.ui-hide-ticket').hide();
            this.elements.toggleTickets.find('.ui-show-ticket').show();
            this.elements.tickets.hide();
          }
          this.elements.toggleTickets.attr('data-state', nextState);
        });
        if(initialState === 'hide') {
          this.elements.toggleTickets.find('.ui-hide-ticket').show();
          this.elements.toggleTickets.find('.ui-show-ticket').hide();
          this.elements.tickets.show();
        } else {
          this.elements.toggleTickets.find('.ui-hide-ticket').hide();
          this.elements.toggleTickets.find('.ui-show-ticket').show();
          this.elements.tickets.hide();
        }
      }
      for(let idx = 0; idx < drawConfigs.length; idx++) {
        const drawConfig = drawConfigs[idx];
        // Get the dyndata.
        let dynData = {};
        try {
          dynData = JSON.parse(drawConfig.dynData);
        } catch(ex) {
          if(ex.name === 'SyntaxError') {
            log.warn(ex, `Failure parsing draw config dyndata for ID "${drawConfig.drawConfigId}".`);
          } else {
            throw ex;
          }
        }
        const drawConfigName = drawConfig.name || '';
        if(this.elements?.ddMenu?.length > 0) {
          // If dropdown menu included, populate it...
          const ddItem = $(`
            <a class="dropdown-item" href="#">
              <img>
              <span></span>
            </a>
          `);
          const imageSrc = dynData?.desktop?.images?.thumbnail?.default;
          ddItem.attr('data-idx', idx);
          ddItem.attr('data-drawconfigid', drawConfig.drawConfigId);
          ddItem.find('span').text(drawConfigName);
          ddItem.find('img').attr('src', imageSrc);
          ddItem.appendTo(this.elements.ddMenu);
          ddItem.on('click', (evt) => {
            evt.preventDefault();
            const target = $(evt.target).closest('a');
            const promoIdx = Number(target.attr('data-idx'));
            currentPromotion = promoIdx;
            this.elements?.carousel?.carousel(promoIdx);
          });
        }
        if(this.elements?.carousel?.length > 0) {
          // If the carousel indicators are included, populated it...
          const carouselId = this.elements.carousel.attr('id');
          if(this.elements.carouselIndicators?.length > 0) {
            const indicator = $(`<li></li>`);
            indicator.attr('data-target', carouselId);
            indicator.attr('data-slide-to', idx);
            indicator.appendTo(this.elements.carouselIndicators);
          }
        }
        if(this.elements?.carouselInner?.length > 0) {
          // If the carousel is included, populated it...
          const template = this.elements?.carouselInner.find('.carousel-item-template');
          let item;
          if(template && template.length) {
            item = template.clone();
            item.removeClass('carousel-item-template');
            item.addClass('carousel-item');
            item.removeAttr('hidden');
          } else {
            item = $(`<div class="carousel-item"><img class="d-block w-100"></div>`);
          }
          const imagePlaceholder = this.elements.carouselInner.attr('data-fallback');
          const imageSrc = dynData?.desktop?.images?.promotion?.default;
          item.attr('data-idx', idx);
          item.attr('data-drawconfigid', drawConfig.drawConfigId);
          const img = item.find('img');
          img.attr('alt', drawConfigName);
          img.attr('src', imageSrc);
          if(idx === 0) {
            img.one('load', () => {
              this.ele.addClass('ui-resources-loaded');
            });
          }
          img.one('error', () => {
            if(imagePlaceholder) {
              log.warn(`Falling back to placeholder image for draw config "${drawConfigName}".`);
              img.attr('src', imagePlaceholder);
              img.attr('alt', 'Promotion image is missing.');
            }
          });
          item.appendTo(this.elements.carouselInner);
        }

        if(this.elements?.ticketsInner?.length > 0) {
          // If the tickets carousel is included, populated it...
          // If the carousel is included, populated it...
          const template = this.elements?.ticketsInner.find('.carousel-item-template');
          const tickets = dynData?.desktop?.images?.tickets;
          if(tickets) {
            for(let gameNum in tickets) {
              const ticketSet = tickets[gameNum];
              for(let ticketIdx= 0; ticketIdx < ticketSet.length; ticketIdx++) {
                gameNumMapping[gameNum] = {
                  promoIdx: idx,
                  ticketIdx: globalTicketIndex,
                };
                globalTicketIndex++;
                const ticket = ticketSet[ticketIdx];
                let item;
                if(template && template.length) {
                  item = template.clone();
                  item.removeClass('carousel-item-template');
                  item.addClass('carousel-item');
                  item.removeAttr('hidden');
                } else {
                  item = $(`<div class="carousel-item"><img class="d-block w-100"></div>`);
                }
                const imagePlaceholder = this.elements.ticketsInner.attr('data-fallback');
                const imageSrc = ticket;
                item.attr('data-promotionidx', idx);
                item.attr('data-gameNum', gameNum);
                const img = item.find('img');
                img.attr('src', imageSrc);
                img.one('error', () => {
                  if(imagePlaceholder) {
                    log.warn(`Falling back to placeholder image for draw config "${drawConfigName}" and game-number "${gameNum}".`);
                    img.attr('src', imagePlaceholder);
                    img.attr('alt', 'Ticket image is missing.');
                  }
                });
                item.appendTo(this.elements.ticketsInner);
              }
            }
          }
        }
      }
      this.elements.carousel.carousel({
        interval: false,
      });
      this.elements.tickets.carousel({
        interval: false,
      });

      let lock = false; // Lock to prevent carousels from triggering each other.
      this.elements.carousel.on('slide.bs.carousel', (evt) => {
        // On Promotion Carousel change...
        const promoIdx = currentPromotion = evt.to;
        if(isInteger(promoIdx)) {
          const items = this.elements.ticketsInner.find('.carousel-item');
          for(let ticketIdx = 0; ticketIdx < items.length; ticketIdx++) {
            const item = $(items[ticketIdx]);
            const checkIdx = Number(item.attr('data-promotionidx'));
            if(promoIdx === checkIdx) {
              if(lock === false) {
                this.elements?.tickets?.carousel(ticketIdx);
              }
              break;
            }
          }
        }
      });

      this.elements.tickets.on('slide.bs.carousel', (evt) => {
        try {
          lock = true;
          // On Ticket Carousel change...
          const ele = $(evt.relatedTarget);
          const promoIdx = Number(ele.attr('data-promotionidx'));
          if(isInteger(promoIdx)) {
            if(currentPromotion !== promoIdx) {
              this.elements.carousel.carousel(promoIdx);
            }
          }
          const gameNum = ele.attr('data-gamenum');
          this.populateBoundGameNumber(gameNum);
        } finally {
          lock = false;
        }
      });

      if(this.info.ticketBind) {
        const gameNumEle = $(this.info.ticketBind).find('.ui-gamenum');
        gameNumEle.on('focusout', (evt) => {
          const gameNum = gameNumEle.val();
          if(gameNumMapping[gameNum]) {
            this.gameNumDirty = true;
            const promoIdx = gameNumMapping[gameNum].promoIdx;
            const ticketIdx = gameNumMapping[gameNum].ticketIdx;
            try {
              lock = true;
              if(isInteger(promoIdx)) {
                if(currentPromotion !== promoIdx) {
                  this.elements.carousel.carousel(promoIdx);
                }
              }
              if(isInteger(ticketIdx)) {
                this.elements?.tickets?.carousel(ticketIdx);
              }
            } finally {
              lock = false;
            }
          }
        });
      }

      // Activate first carousel item if none active.
      if(this.elements.carousel.find('.carousel-item.active').length === 0) {
        this.elements.carousel.find('.carousel-item').first().addClass('active');
      }
      if(this.elements.tickets.find('.carousel-item.active').length === 0) {
        this.elements.tickets.find('.carousel-item').first().addClass('active');
      }
      const gameNum = this.elements.tickets.find('.carousel-item').first().attr('data-gamenum');
      this.populateBoundGameNumber(gameNum);
    }

    populateBoundGameNumber(gameNum) {
      if(this.info.ticketBind && gameNum && !this.gameNumDirty) {
        const ticketentry = $(this.info.ticketBind);
        const gameNumEle = ticketentry.find('.ui-gamenum');
        gameNumEle.val(gameNum);
        log.trace(`Populating bound game-number with "${gameNum}".`);
      }
      this.gameNumDirty = false;
    }

    async onMount({ attach, info }) {
      log.trace(`Mounting promotions component (#${this.id}).`);
      attach({
        ddToggle: '.dropdown-toggle',
        ddMenu: '.dropdown-menu',
        carousel: '.ui-promotion-carousel.carousel',
        carouselIndicators: '.ui-promotion-carousel.carousel .carousel-indicators',
        carouselInner: '.ui-promotion-carousel.carousel .carousel-inner',
        tickets: '.ui-tickets-carousel.carousel',
        ticketsIndicators: '.ui-tickets-carousel.carousel .carousel-indicators',
        ticketsInner: '.ui-tickets-carousel.carousel .carousel-inner',
        toggleTickets: '.ui-promotion-ticket-toggle',
      });
      info({
        ticketBind: ['[data-bind]'],
      });
      const { data: { drawConfigs } } = await drawconfiglistOpen();
      this.build({ drawConfigs });
    }

    static autowireSelector() {
      return '.ui-promotions';
    }
  }

  /**
   * Autowire.
   */
  const promotionsAutowire = PromotionsComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    promotionsAutowire, PromotionsComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  promotionsComponentFactory,
};
