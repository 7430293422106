/**
 * api-client:src/ui/autowireearly.js
 */

/**
 * Constants.
 */
const re_autowireearly = /.AutowireEarly$/;

/**
 * AutoWireEarly factory.
 */
async function autoWireEarlyFactory(dyn) {
  const { log, components } = dyn();
  for(let name in components) {
    const autoWireEarlyFn = components[name];
    if(re_autowireearly.test(name) && typeof autoWireEarlyFn === 'function') {
      await autoWireEarlyFn();
    }
  }
  log.trace('AutoWireEarly complete.');
}

/**
 * Exports.
 */
module.exports = {
  autoWireEarlyFactory,
};
