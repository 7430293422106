/**
 * @imarcsgroup/client:src/libs/jquery.js
 */

/**
 * Imports.
 */
const compareVersions = require('compare-versions');
const { raiseUnhandledError } = require('../utilities/unhandled');

/**
 * Minimum version.
 */
const MINVERSION = '3.6.0';
const MATCHVERSION = '3.x.x';

/**
 * Check the version of jQuery installed.
 * @type {jQuery}
 */
function checkJquery() {
  const jQuery = global.jQuery;
  if(!(
    jQuery && jQuery.fn
		&& typeof jQuery === 'function'
		&& typeof jQuery.fn === 'object'
		&& typeof jQuery.fn.jquery === 'string'
  )) {
    throw new Error('jQuery library not found.');
  }
  const version = jQuery.fn.jquery;
  if(!(
    compareVersions(version, MINVERSION) > -1
		&& compareVersions(version, MATCHVERSION) === 0
  )) {
    throw new Error(`jQuery version "${version}" not supported.`);
  }
  // Override jQuery event handler to raise unhandled error if a rejection occurs.
  const jqAdd = jQuery.event.add;
  jQuery.event.jqAdd = jqAdd;
  jQuery.event.add = function add(elem, types, handler, data, selector) {
    // NOTE: handler can be an object or a function.
    try {
      const fn = typeof handler === 'function' ? handler : handler.handler;
      function customHandler(...args) {
        try {
          const result = fn.apply(this, args);
          if(result instanceof Promise) {
            result.catch(raiseUnhandledError);
          }
          return result;
        } catch(ex) {
          raiseUnhandledError(ex);
        }
      }
      const newHandler = typeof handler === 'function' ? customHandler : Object.assign({}, handler, { handler: customHandler });
      return jqAdd.call(this, elem, types, newHandler, data, selector);
    } catch(ex) {
      console.error(ex);
    }
  };
  // Extend jQuery a bit.
  $.fn.arrayReduce = Array.prototype.reduce;
  $.fn.scrollAndFocus = function scrollAndFocus() {
    const offset = Number($('body').attr('data-scrolloffset') || 50);
    const scrollTop = Math.max(this.offset().top - offset, 0);
    $('html, body').animate({ scrollTop }, 500);
    setTimeout(() => {
      this.focus();
    }, 5);
  };
  return jQuery;
}

/**
 * Exports.
 */
module.exports = {
  checkJquery,
  'jQuery': global.jQuery,
};
