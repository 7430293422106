/**
 * @imarcsgroup/client:src/logs.js
 */

/**
 * Imports.
 */
const northwoodsLib = require('@theroyalwhee0/northwoods');
const consoleFormattedLib = require('@theroyalwhee0/northwoods-consoleformatted');
const windowLib = window;

/**
 * Constants.
 */
const LOGLEVEL = 'LOGLEVEL';
const DEFAULTLEVEL = 'info';

/**
 * Cast log level to a number if it is a numeric string.
 * @param  {Any} value The value to check.
 * @return {Any}       The resulting numeric or passed though value.
 */
function castNumericLevel(value) {
  if(typeof value === 'string' && /^\d+$/.test(value)) {
    return Number(value);
  } else {
    return value;
  }
}

/**
 * Attach LOGLEVEL property to target object.
 * @param  {Object} window  Window to attach to.
 * @param  {Object} northwoods  Northwoods library.
 * @param  {Object} log   Northwoods logger instance.
 * @return {undefined}        None.
 */
function attachLogLevelProperty(window, northwoods, log) {
  const { sessionStorage } = window;
  Object.defineProperty(window, LOGLEVEL, {
    get: () => {
      return log.level();
    },
    set: (value) => {
      value = castNumericLevel(value);
      if(typeof value === 'string') {
        value = value.toLowerCase();
        if(value === 'default') {
          value = DEFAULTLEVEL;
        }
      }
      const level = northwoods.resolveLevel(value) || DEFAULTLEVEL;
      const logLevel = northwoods.nameFromLevel[level] || level;
      if(sessionStorage) {
        sessionStorage.setItem(LOGLEVEL, level);
      }
      log.level(level);
      log.info({ logLevel }, 'Log level changed.');
    },
  });
}

/**
 * Resolve log level from default, given, or stored log level.
 * @param  {Window} window  Window object.
 * @param  {Object} northwoods  Northwoods library.
 * @param  {Number|String} level  The log level to use. Optional.
 * @return {Number}        The resolved log level.
 */
function resolveInitialLogLevel(window, northwoods, level) {
  const { sessionStorage } = window;
  const sessionLevel = sessionStorage && sessionStorage.getItem(LOGLEVEL);
  if(sessionStorage && sessionLevel !== null) {
    level = castNumericLevel(sessionLevel);
  } else {
    level = level === undefined ? DEFAULTLEVEL : level;
  }
  return northwoods.resolveLevel(level);
}

/**
 * Extend log instance with helper methods.
 * @param  {Object} northwoods  Northwoods library.
 * @param  {Object} log   Northwoods logger instance.
 * @return {undefined}        None.
 */
function extendLogInstance(northwoods, log) {
  // TODO: Move these into northwoods library.
  Object.assign(log, {
    get isTrace() {
      return log.level() <= northwoods.TRACE;
    },
    get isDebug() {
      return log.level() <= northwoods.DEBUG;
    },
    get isInfo() {
      return log.level() <= northwoods.INFO;
    },
    get isWarn() {
      return log.level() <= northwoods.WARN;
    },
    get isError() {
      return log.level() <= northwoods.ERROR;
    },
    get isFatal() {
      return log.level() <= northwoods.FATAL;
    },
  });
}

/**
 * Log factory.
 */
function logFactory({
  name, level,
  window=windowLib, northwoods=northwoodsLib, logStreamType=consoleFormattedLib,
}) {
  // TODO: Move log stream type into createLogger where it belongs.
  northwoods.setDefaultStreamType(logStreamType);
  level = resolveInitialLogLevel(window, northwoods, level);
  const log = northwoods.createLogger({ name, level });
  const logLevel = northwoods.nameFromLevel[level];
  attachLogLevelProperty(window, northwoods, log);
  extendLogInstance(northwoods, log);
  return log;
}

/**
 * Exports.
 */
module.exports = {
  logFactory,
};
