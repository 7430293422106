/**
 * Imports.
 */
const Cookies = require('js-cookie');
const { isString } = require('@theroyalwhee0/istype');
const { isSession } = require('./checks');

/**
 * Constants.
 */
const isSecure = location.protocol === 'https:';
const COOKIENAME = (isSecure ? '__Host-' : '') + 's';
const SEPARATOR = '|';
const PART_COUNT = 6;

/**
 * Serialize Refresh token.
 */
function serializeSession(session) {
  const serialized = [
    session.ident,
    session.tokens.oauth.key,
    session.tokens.oauth.expireTime,
    session.tokens.refresh.key,
    session.tokens.refresh.expireTime,
    session.roles.join(','),
  ];
  return serialized.join(SEPARATOR);
}

/**
 * Parse a cookie value.
 */
function parseCookie(cookieValue) {
  let session;
  if(isString(cookieValue)) {
    const split = cookieValue.split(SEPARATOR);
    if(split.length === PART_COUNT) {
      const [ ident, oauthKey, oauthExpire, refreshKey, refreshExpire, rolesString ] = split;
      const roles = rolesString === '' ? [] : rolesString.split(',');
      session = {
        type: 'unknown',
        roles: roles,
        ident,
        tokens: {
          oauth: {
            key: oauthKey,
            expireTime: Number(oauthExpire),
          },
          refresh: {
            key: refreshKey,
            expireTime: Number(refreshExpire),
          },
        },
      };
    }
  }
  return session;
}

/**
 * Write the session cookie.
 */
function writeSessionCookie(session) {
  if(isSession(session)) {
    const sessionCookie = serializeSession(session);
    Cookies.set(COOKIENAME, sessionCookie, { secure: isSecure, sameSite: 'lax', path: '/' });
  } else {
    removeCookie();
  }
}

/**
 * Read the session cookie.
 */
function readSessionCookie() {
  const cookieValue = Cookies.get(COOKIENAME);
  const session = parseCookie(cookieValue);
  if(isSession(session)) {
    return session;
  }
  removeCookie();
  return false;
}

/**
 * Remove a cookie.
 */
function removeCookie() {
  Cookies.set(COOKIENAME, '', { expires: 0, secure: isSecure, sameSite: 'lax', path: '/' });
}

/**
 * Exports.
 */
module.exports = {
  writeSessionCookie,
  readSessionCookie,
};
