/**
 * Imports.
 */
const { HTTP_OK, HTTP_SERVER_ERROR, HTTP_FORBIDDEN } = require('../../utilities/httpcode');

/**
 * LogOut controller factory.
 * @param {DynastyDepends} dyn Dynasty depends function.
 * @returns {object} The object with built functions in it.
 */
async function logoutFactory(dyn) {
  const { log, apiFetch, dispatch, actions, subscribeTill } = dyn();
  const { sessionLogOut } = actions;
  async function logout(url) {
    // Log Out.
    const { data, status } = await apiFetch({
      method: 'DELETE',
      path: `/api/v1/oauth/self/tokens/`,
    });
    if (status === HTTP_OK) {
      log.trace(`Logged out.`);
    } else if (status === HTTP_FORBIDDEN) {
      log.trace('Was already logged out.');
    } else if (status === HTTP_SERVER_ERROR) {
      log.warn(`An error occurred logging out. Clearing anyways.`);
    }
    await subscribeTill((_) => _.session, (session) => !session.roles.includes('@access'), () => {
      dispatch(sessionLogOut({ url }));
    });
    return true;
  }
  return {
    logout,
  };
}

/**
 * Exports.
 */
module.exports = {
  logoutFactory,
};
