/**
 * Imports.
 */
const { isArray, isString, isObject } = require('@theroyalwhee0/istype');
const { HTTP_OK, HTTP_SERVICE_UNAVAILABLE } = require('../../utilities/httpcode');
const { codeOrError } = require('../../utilities/rest');

/**
 * NotifyPreferences controller factory.
 */
async function notifyPreferencesFactory(dyn) {
  const { apiFetch } = dyn();

  async function getNotifyPreferences() {
    const { data } = await apiFetch({
      self: true,
      method: 'GET',
      path: '/api/v1/players/self/notifications/',
    });
    return { ...data };
  };

  async function setNotifyPreferences(body) {
    await apiFetch({
      self: true,
      method: 'PUT',
      path: '/api/v1/players/self/notifications/',
      body,
    });
    return { ok: true };
  }

  return {
    getNotifyPreferences, setNotifyPreferences,
  };
}

/**
 * Exports.
 */
module.exports = {
  notifyPreferencesFactory,
};
