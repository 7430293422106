/**
 * @imarcsgroup/client:src/api/end.js
 */

/**
 * Imports.
 */
const { waitForDomEvent } = require('../utilities/dom');

/**
  * Constants.
  */
const DEFAULT_TIMEOUT = 60 * 1000; // 60 seconds.

/**
 * API End factory.
 */
async function apiEndFactory(dyn) {
  const { log, timeOut=DEFAULT_TIMEOUT } = dyn();
  await waitForDomEvent(global, 'api-setup-end', timeOut);
  log.debug('API end triggered.');
}

/**
 * Exports.
 */
module.exports = {
  apiEndFactory,
};
