const { HTTP_OK, HTTP_SERVICE_UNAVAILABLE, HTTP_NO_CONTENT } = require('../../utilities/httpcode');
const { codeOrError } = require('../../utilities/rest');

function passwordUpdateFactory(dyn) {
  const { apiFetch } = dyn();

  async function passwordUpdate(oldPassword, newPassword) {
    const { data, status } = await apiFetch({
      method: 'PUT',
      path: `/api/v1/players/self/password`,
      body: {
        oldPassword, newPassword,
      },
    });
    if(status === HTTP_SERVICE_UNAVAILABLE) {
      return { code: 'service_unavailable' };
    } else if(status !== HTTP_OK && status !== HTTP_NO_CONTENT) {
      return codeOrError(data);
    }
    return {
      ok: true,
    };
  };

  return {
    passwordUpdate,
  };
}

module.exports = {
  passwordUpdateFactory,
};
