async function verifyLoginAccess({ log, login, selector, onState, getString, sendVerifyLogin, verifyLogin, loginData }) {
  try {
    // Pause Claims list work until access has been verified.
    login?.busy();

    const data = loginData.authData[0];
    const authCode = data.authCode;
    const mobileHidden = data.meta?.mobileHidden;

    // Verify access.
    let verifyLoginResults;
    const verified = login.hasRole('login-mfa');
    if(!verified) {
      const ele = $(selector);
      const isModal = ele.hasClass('modal');
      const form = ele.find('form');
      const msg = form.find('.ui-msg');
      const mfaCode = form.find('[name="code"]');
      const emailEle = form.find('.ui-email');
      const sendCode = form.find('.ui-sendcode');
      const sendCodeSms = form.find('.ui-sendcodesms');
      const submitButton = form.find('button[type="submit"]');

      const email = login.ele.find('[name="email"]').val();
      emailEle.text(email);

      mfaCode.on('input', async (evt) => {
        const input = $(evt.currentTarget);
        const value = input.val();
        if(value.length != 6) {
          submitButton.prop('disabled', true);
        } else {
          submitButton.prop('disabled', false);
        }
      });

      const sendCodeHandler = async (evt) => {
        let responseText;
        const sms = evt.data.sms;
        const results = await sendVerifyLogin(authCode, sms);
        mfaCode.val('');
        if(!results?.ok) {
          const code = results?.code || 'general_error';
          responseText = getString(`login.verify.${mobileHidden ? '' : 'nosms.'}${code}`).text;
        } else {
          if(sms) {
            responseText = getString(`login.verify.send_sms`, { mobileHidden }).text;
          } else {
            responseText = getString(`login.verify.send_email`).text;
          }
        }
        msg.text(responseText);
      };

      sendCode.on('click', { sms: false }, sendCodeHandler);

      if(mobileHidden) {
        sendCodeSms.on('click', { sms: true }, sendCodeHandler);
      } else {
        sendCodeSms.hide();
      }

      msg.text('');
      mfaCode.val('');

      const results = await sendVerifyLogin(authCode);
      if(!results?.ok) {
        const code = results?.code || 'general_error';
        const responseText = getString(`login.verify.${mobileHidden ? '' : 'nosms.'}${code}`).text;
        msg.text(responseText);
        return false;
      }

      ele.removeAttr('hidden');
      if(isModal) {
        ele.modal('show');
      } else {
        ele.show();
      }

      verifyLoginResults = await new Promise((resolve) => {
        // On Submit...
        form.on('submit', async (evt) => {
          evt.preventDefault();
          msg.text('');
          const results = await verifyLogin(loginData, mfaCode.val());
          if(!results?.ok) {
            mfaCode.val('');
            const code = results?.code || 'general_error';
            const responseText = getString(`login.verify.${mobileHidden ? '' : 'nosms.'}${code}`).text;
            msg.text(responseText);
          } else {
            resolve(results);
          }
        });
      });
      // Everything is resolved...
      if(isModal) {
        ele.modal('hide');
        await new Promise((resolve) => {
          ele.on('hidden.bs.modal', () => {
            resolve();
          });
        });
      }
    }
    return verifyLoginResults || false;
  } finally {
    login?.ready();
  }
}

module.exports = {
  verifyLoginAccess,
};
