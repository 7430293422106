/**
 * @imarcsgroup/client::src/strings/register.js
 */

/**
 * Strings.
 */
const strings = {
  'register.error': 'An error has occurred while trying to register.',
  'register.email.required': 'Email is required.',
  'register.first_name.required': 'First name is required.',
  'register.last_name.required': 'Last name is required.',
  'register.password.required': 'Password is required.',
  'register.password_confirm.required': 'Password confirmation is required.',
  'register.address.required': 'Address is required.',
  'register.city.required': 'City is required.',
  'register.state.required': 'State is required.',
  'register.zipcode.required': 'Zipcode is required.',
  'register.phone.required': 'Phone is required.',
  'register.birthday.required': 'Birthday is required.',
  'register.birthday.invalid': 'Birthday is invalid.',
  'register.birthday.too_young': 'You must be {{minAge}} or older to register.',
  'register.profile_duplicate_email': 'The Email Address is already in use. Use another Email Address or login.',
};

/**
 * Exports.
 */
module.exports = strings;

