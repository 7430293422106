/**
 * @imarcsgroup/client:src/store/reducers.js
 */

/**
 * Import/Exports.
 */
module.exports = {
  ...require('../services/userdata/reducers'),
  ...require('../services/session/reducers'),
};
