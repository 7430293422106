/**
 * @imarcsgroup/client:src/components/claimslist/index.js
 */

const { DateTime } = require('luxon');
const { CLAIM_ACTIVE_STATE, CLAIM_COMPLETED_STATE } = require('../../utilities/claimstate');

const CLAIM_TICKET_TYPE = {
  ONLINE: 'ONLINE',
  INSTANT: 'INSTANT',
  INLANE_RECEIPT_TICKET: 'INLANE_RECEIPT_TICKET',
  INLANE_QUICK_TICKET: 'INLANE_QUICK_TICKET',
};

const PROMO_IMG = {
  'pwrb': { fullGameName: 'Powerball', image: 'powerball@2x.png' },
  'pck3': { fullGameName: 'Pick 3', image: 'pick3@2x.png' },
  'pck4': { fullGameName: 'Pick 4', image: 'pick4@2x.png' },
  'loto': { fullGameName: 'Lotto Texas', image: 'lotto@2x.png' },
  'tx2s': { fullGameName: 'Texas Two Step', image: 'tx2step@2x.png' },
  'alno': { fullGameName: 'All or Nothing', image: 'aon@2x.png' },
  'csh5': { fullGameName: 'Cash Five', image: 'cash5@2x.png' },
  'mlto': { fullGameName: 'Mega Millions', image: 'megamillions@2x.png' },
  'dly4': { fullGameName: 'Daily 4', image: 'daily4.png' },
};

/**
 * ClaimsList component factory.
 */
function claimslistComponentFactory(dyn) {
  const {
    log, env, debugClaims, controllers, BaseComponent, stringTable,
    instantsResourcesDomain, dateTime,
  } = dyn();
  const {
    navigateTo, claimslist, claimsSetoffsEmail,
    getProfile, instantimglist, instantlookup,
  } = controllers;
  const { getString } = stringTable;
  let profile = false;
  
  const irtRe = /^[A-Za-z0-9]+;\d+:(\d+);\d+:[A-Za-z0-9+/=]+$/;

  async function showClaimFormDetails(claimInfo) {
    if(!claimInfo) {
      return;
    }
    $('#claimform_success').modal('hide');

    const results = {};

    if(!profile) {
      profile = await getProfile();
    }

    const modalEle = $('.ui-claimslist-claimform-details');
    const claimDate = DateTime.fromMillis(claimInfo.claimDate).toFormat('MM/dd/yyyy');

    // Claim Information
    modalEle.find('.ui-claimslist-claimform-details-claimid').text(claimInfo.imarcsClaimId);
    modalEle.find('.ui-claimslist-claimform-details-claimamount').text(claimInfo.dollarGrossPrize);
    modalEle.find('.ui-claimslist-claimform-details-ticketcount').text(claimInfo.numberOfTickets);
    modalEle.find('.ui-claimslist-claimform-details-submitteddate').text(claimDate);

    // Player Information
    modalEle.find('.ui-claimslist-claimform-details-firstname').text(profile.first_name);
    const middleNameEle = modalEle.find('.ui-claimslist-claimform-details-middlename');
    if(profile.middle_name) {
      middleNameEle.text(profile.middle_name).parent().show();
    } else {
      middleNameEle.parent().hide();
    }
    modalEle.find('.ui-claimslist-claimform-details-lastname').text(profile.last_name);
    const suffixEle = modalEle.find('.ui-claimslist-claimform-details-suffix');
    if(profile.suffix) {
      suffixEle.text(profile.suffix).parent().show();
    } else {
      suffixEle.parent().hide();
    }

    modalEle.find('.ui-claimslist-claimform-details-citizenship').text(profile.citizenship === 'RESIDENT' ? 'US Citizen/Resident Alien' : 'Non-US Citizen/Non-Resident Alien');
    const ssnEle = modalEle.find('.ui-claimslist-claimform-details-ssn');
    if(profile.ssn) {
      ssnEle.text(`***-**-${profile.ssn.slice(-4)}`).parent().show();
    } else {
      ssnEle.parent().hide();
    }
    modalEle.find('.ui-claimslist-claimform-details-birthdate').text(`${profile.birthday_mm}/${profile.birthday_dd}/${profile.birthday_yyyy}`);
    modalEle.find('.ui-claimslist-claimform-details-country').text(profile.countryoforigin);

    // Mailing Address
    modalEle.find('.ui-claimslist-claimform-details-address').text(profile.claims_address);
    modalEle.find('.ui-claimslist-claimform-details-city').text(profile.claims_city);

    const claimsStateEle = modalEle.find('.ui-claimslist-claimform-details-state');
    if(profile.claims_state) {
      claimsStateEle.text(profile.claims_state).parent().show();
    } else {
      claimsStateEle.parent().hide();
    }
    const claimsRegionEle = modalEle.find('.ui-claimslist-claimform-details-county');
    if(profile.claims_region) {
      claimsRegionEle.text(profile.claims_region).parent().show();
    } else {
      claimsRegionEle.parent().hide();
    }
    
    // Would be profile.claims_zipcode but the whole zipcode is obfuscated.
    modalEle.find('.ui-claimslist-claimform-details-zip').text('*****');
    let claimsZipcodeExt = '';
    if(profile.claims_zipcode4) {
      claimsZipcodeExt = `-${profile.claims_zipcode4}`;
    }
    modalEle.find('.ui-claimslist-claimform-details-zipext').text(claimsZipcodeExt);

    // Contact
    modalEle.find('.ui-claimslist-claimform-details-mobile').text(`***-***-${profile.phone.slice(-4)}`);
    const phone2Ele = modalEle.find('.ui-claimslist-claimform-details-home');
    if(profile.phone2) {
      phone2Ele.text(`***-***-${profile.phone2.slice(-4)}`).show();
    } else {
      phone2Ele.hide();
    }
    modalEle.find('.ui-claimslist-claimform-details-email').text(profile.email);

    // QR Code
    if(qrcodegen) {
      const { QrCode } = qrcodegen;
      const qrcEncoded = QrCode.encodeText(claimInfo.imarcsClaimId, QrCode.Ecc.MEDIUM);
      const qrcSvg = QrCode.toSvgString(qrcEncoded);
      modalEle.find('.ui-claimslist-claimform-details-qrcode svg').replaceWith(qrcSvg);
    }

    const onClose = new Promise((resolve) => {
      modalEle.on('hidden.bs.modal', (evt) => {
        results.close = true;
        resolve();
      });
    });
    modalEle.modal('show');
    await onClose;
    modalEle.modal('hide');
    return results;
  }

  async function showClaimDetails(claimInfo) {
    const results = {};
    const modalEle = $('.ui-claimslist-details');

    if(claimInfo.webClaim) {
      modalEle.find('.ui-claimslist-details-webclaimid').text(claimInfo.imarcsClaimId);
      modalEle.find('.ui-claimslist-details-state').text(claimInfo.displayWebClaimStatus);
    } else {
      modalEle.find('.my-claims-details-promo-img').attr('src', claimInfo.imageUrl);
      modalEle.find('.ui-claimslist-details-state').text(claimInfo.displayStatus);
      modalEle.find('.my-claims-details-webclaimid').hide();
    }
    
    let ticketCode = claimInfo.ticketCode;
    if(ticketCode) {
      const ticketLength = ticketCode.length;
      if(ticketLength === 19) {
        ticketCode = ticketCode.replace(/(\d{4})(\d{9})(\d{6})/, '$1-$2-$3');
      } else if(ticketLength === 26) {
        ticketCode = ticketCode.replace(
          /(\d{2})(\d{4})(\d{7})(\d{3})(\d{10})/,
          '$1-$2-$3-$4-$5'
        );
      } else if(ticketLength === 56) {
        ticketCode = ticketCode.substring(
          ticketCode.indexOf(':') + 1,
          ticketCode.lastIndexOf(';')
        ).replace(/(\d{4})(\d{9})(\d{6})/, '$1-$2-$3');
      }
    } else {
      modalEle.find('.my-claims-details-ticketcode').hide();
    }

    const gameNameContainer = modalEle.find('.my-claims-details-gamename');
    if(claimInfo.ticketType !== CLAIM_TICKET_TYPE.INSTANT) {
      gameNameContainer.hide();
    } else {
      modalEle.find('.ui-claimslist-details-gamename').text(claimInfo.gameName || claimInfo.gameNumber);
      gameNameContainer.show();
    }
    modalEle.find('.ui-claimslist-details-ticketcode').text(ticketCode);
    modalEle.find('.ui-claimslist-details-grossprize').text(claimInfo.dollarGrossPrize);

    const msg = getString(`${claimInfo.webClaim ? 'web' : ''}claim.details.${claimInfo.state}`).text;
    modalEle.find('.ui-claimslist-details-msg').html(msg);

    const capIdContainer = modalEle.find('.my-claims-details-capid');
    const capIdEle = modalEle.find('.ui-claimslist-details-capid');
    if(claimInfo.capId) {
      capIdContainer.show();
      capIdEle.text(claimInfo.capId);
    } else {
      capIdContainer.hide();
      capIdEle.text('');
    }

    const grossPrizeContainer = modalEle.find('.mc-modal-gross');

    const taxesContainer = modalEle.find('.my-claims-details-taxes-container');
    const taxesInfo = modalEle.find('.my-claims-details-taxes-description');
    const taxesEle = modalEle.find('.ui-claimslist-details-taxes');
    if(claimInfo.taxes) {
      grossPrizeContainer.addClass('mc-modal-divider-bottom');
      taxesContainer.show();
      taxesInfo.show();
      taxesEle.text(claimInfo.dollarTaxes);
    } else {
      taxesContainer.hide();
      taxesInfo.hide();
      taxesEle.text('');
    }

    const setOffsContainer = modalEle.find('.my-claims-details-setoffs-container');
    const setOffsButtons = modalEle.find('.my-claims-details-setoff-buttons');
    const setOffsEle = modalEle.find('.ui-claimslist-details-setoffs');
    if(claimInfo.setOffs) {
      grossPrizeContainer.addClass('mc-modal-divider-bottom');
      setOffsContainer.show();
      setOffsButtons.show();
      setOffsEle.text(`-${claimInfo.dollarSetOffs}`);
    } else {
      setOffsContainer.hide();
      setOffsButtons.hide();
      setOffsEle.text('');
    }

    if(!claimInfo.taxes && !claimInfo.setOffs) {
      grossPrizeContainer.removeClass('mc-modal-divider-bottom');
    }

    const paidPrizeContainer = modalEle.find('.mc-total-prize-payment');
    const paidPrizeEle = modalEle.find('.ui-claimslist-details-paidprize');
    if(claimInfo.paidPrize > 0 || claimInfo.taxes > 0 || claimInfo.setOffs > 0) {
      paidPrizeContainer.show();
      paidPrizeEle.text(claimInfo.dollarPaidPrize);
    } else {
      paidPrizeContainer.hide();
      paidPrizeEle.text('');
    }

    const viewWebClaimEle = modalEle.find('.ui-claimslist-details-viewwebclaim');
    if(claimInfo.webClaim) {
      viewWebClaimEle.off('click.viewWebClaim').on('click.viewWebClaim', () => {
        showClaimFormDetails(claimInfo);
      });
      viewWebClaimEle.show();
    } else {
      viewWebClaimEle.hide();
    }

    const viewSetoffEle = modalEle.find('.ui-claimslist-details-viewsetoff');
    viewSetoffEle.off('click.viewSetoff').on('click.viewSetoff', () => {
      if(claimInfo.setOffsUrl) {
        navigateTo(`${claimInfo.setOffsUrl}`, true);
      }
    });
    
    const sendSetoffModalEle = $('.ui-claimslist-details-sendsetoff');
    const sendSetoffEle = sendSetoffModalEle.find('.sendsetoff');
    sendSetoffEle.off('click.sendSetoff').on('click.sendSetoff', () => {
      claimsSetoffsEmail(claimInfo.id);
    });

    const onClose = new Promise((resolve) => {
      modalEle.on('hidden.bs.modal', (evt) => {
        results.close = true;
        resolve();
      });
    });
    modalEle.modal('show');
    await onClose;
    modalEle.modal('hide');
    return results;
  }

  /**
   * ClaimsList Component.
   */
  class ClaimsListComponent extends BaseComponent {

    async build(claims) {
      let claimsInfo = {};
      let instantTicketRes;
      let instantTicketData = { };
      let activeCount = 0;
      let completedCount = 0;
      
      claims.reverse(); // Insert claims from back-to-front to preserve query order.
      for(let loop=0; loop < claims.length; loop++) {
        const row = this.elements.tpl.clone();
        const claim = claims[loop];
        row.removeAttr('hidden').removeClass('ui-template-row');
        row.attr('data-claimid', claim.id);

        const webClaim = claim.webClaim;
        let gameName = claim.gameName ? claim.gameName.toLowerCase() : 'unknown';
        let gameNumber = gameName;
        let imageUrl = '/-res/img/fallback/placeholder_instants_logo.png';
        let ticketCode = claim.ticketCode;
        const ticketType = claim.ticketType;
        if(ticketType === CLAIM_TICKET_TYPE.INSTANT) {
          if(!instantTicketRes) {
            instantTicketRes = await instantimglist();
          }
          gameNumber = ticketCode.substring(2, 6);
          const resources = instantTicketRes[gameNumber];
          if(!resources) {
            log.warn(`Failed to find Instant ticket data for '${gameNumber}'.`);
          } else if(resources.thumbnail2xUrl) {
            imageUrl = instantsResourcesDomain + resources.thumbnail2xUrl;
          } else if(resources.thumbnailUrl) {
            imageUrl = instantsResourcesDomain + resources.thumbnailUrl;
          }

          if(!instantTicketData[gameNumber]) {
            instantTicketData[gameNumber] = { gameNumber, gameName: '' };
            if(Number(gameNumber)) {
              const instantTicketDataLookup = await instantlookup(gameNumber);
              if(instantTicketDataLookup.status === 200) {
                instantTicketData[gameNumber] = instantTicketDataLookup.data;
              }
            }
          }
          gameName = instantTicketData[gameNumber].gameName;
        } else {
          const imageName = PROMO_IMG[gameNumber]?.image;
          if(webClaim) {
            imageUrl = '/-res/img/fallback/placeholder_webclaim_logo.png';
          } else if(imageName) {
            imageUrl = `/-res/img/game-images/${imageName}`;
          } else {
            imageUrl = '/-res/img/fallback/placeholder_online_logo.png';
          }
        }

        if(ticketType === CLAIM_TICKET_TYPE.ONLINE) {
          // If SureLock ticket, reformat ticket code.
          if(ticketCode.length === 65) {
            ticketCode = ticketCode.slice(11, 30);
          }
        }

        if(ticketType === CLAIM_TICKET_TYPE.INLANE_RECEIPT_TICKET) {
          ticketCode = ticketCode.match(irtRe)?.[1];
          if(!ticketCode) {
            ticketCode = 'Unknown';
          }
        }

        if(ticketType === CLAIM_TICKET_TYPE.INLANE_QUICK_TICKET) {
          ticketCode = 'Quick Ticket';
        }

        gameName = $('<textarea>').html(gameName).text();
        const grossPrize = Number(claim.grossPrize) || 0;
        const taxes = Number(claim.taxes) || 0;
        const setOffs = Number(claim.setOffs) || 0;
        const paidPrize = Number(claim.paidPrize) || 0;
        const claimInfo = Object.assign({}, claim, {
          gameName, gameNumber, ticketCode, imageUrl, grossPrize, taxes, setOffs, paidPrize, ticketType, webClaim,
          state: claim.claimState,
          displayStatus: getString(`claim.status.${claim.claimState}`).text || claim.claimState || 'UNKNOWN',
          displayWebClaimStatus: getString(`webclaim.status.${claim.claimState}`).text || claim.claimState || 'UNKNOWN',
          active: !!CLAIM_ACTIVE_STATE[claim.claimState],
          completed: !!CLAIM_COMPLETED_STATE[claim.claimState],
          dollarGrossPrizeSummary: (Math.round(grossPrize / 100)).toLocaleString('en-US'),
          dollarGrossPrize: (grossPrize / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
          dollarTaxes: (taxes / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
          dollarSetOffs: (setOffs / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
          dollarPaidPrize: (paidPrize / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
        });

        row.on('click', (evt) => {
          showClaimDetails(claimInfo);
        });

        const promoImg = $('<img>');
        promoImg.attr('src', claimInfo.imageUrl);

        let drawEndDate = 'TBD';
        if(claimInfo.drawEndDate !== claimInfo.drawStartDate) {
          drawEndDate = 'Multiple Draws';
        } else if(claimInfo.drawEndDate) {
          drawEndDate = dateTime.timestampToDateTime(claimInfo.drawEndDate).toFormat('MM/dd/yyyy');
        }

        if(!webClaim) {
          row.addClass('claimslist-game-type-' + ticketType.toLowerCase());
          row.addClass('claimslist-game-' + claimInfo.gameNumber);
          row.find('.my-claims-promo-img').append(promoImg).attr('alt', PROMO_IMG[claimInfo.gameName]?.fullGameName || claimInfo.gameName);
          row.find('.ui-claimslist-claim-type').text('Mobile Claim');
          row.find('.ui-claimslist-state').text(claimInfo.displayStatus);
          row.find('.ui-claimslist-gamename').text(claimInfo.gameName);
          row.find('.ui-claimslist-gamenumber').text(claimInfo.gameNumber);
          row.find('.ui-claimslist-drawdate').text(drawEndDate);
          row.find('.ui-claimslist-claimamount').text(claimInfo.dollarGrossPrizeSummary);
        } else {
          row.addClass('claimslist-game-type-claimform');
          row.find('.my-claims-promo-img').append(promoImg).attr('alt', 'Web Claim');
          row.find('.ui-claimslist-claim-type').text('Web Claim');
          row.find('.ui-claimslist-webclaim-id').text(claimInfo.imarcsClaimId);
          row.find('.ui-claimslist-state').text(claimInfo.displayWebClaimStatus);
          row.find('.ui-claimslist-claimamount').text(claimInfo.dollarGrossPrizeSummary);
        }

        claimsInfo[claim.id] = claimInfo;

        if(claimInfo.active) {
          activeCount++;
          row.insertAfter(this.elements.active);
        } else if(claimInfo.completed) {
          completedCount++;
          row.insertAfter(this.elements.completed);
        }
      }
      return { claimsInfo, activeCount, completedCount };
    }

    async onMount({ attach, info }) {
      log.trace(`Mounting claims list component (#${this.id}).`);
      attach({
        tpl: '.ui-template-row',
        active: '.ui-claimslist-active',
        completed: '.ui-claimslist-completed',
      });
      info({
        mfa: ['[data-mfa]'],
      });
      const data = await claimslist();
      const { data: claims } = data;

      // Load debug claims from configuration in dev mode, if provided.
      if(env && env === 'dev' && debugClaims?.length) {
        claims.push(...debugClaims);
      }

      const { claimsInfo, activeCount, completedCount } = await this.build(claims);
      if(!activeCount) {
        this.elements.active.find('.ui-claimslist-empty').show();
      }
      if(!completedCount) {
        this.elements.completed.find('.ui-claimslist-empty').show();
      }

      const urlParams = new URLSearchParams(window.location.search);
      const claimId = urlParams.get('claimId');
      if(claimId) {
        $('.viewclaimform').on('click', async (evt) => {
          evt.preventDefault();
          await showClaimFormDetails(claimsInfo[claimId]);
        });
      }
    }

    static autowireSelector() {
      return '.ui-claimslist';
    }
  }

  /**
   * Autowire.
   */
  const claimslistAutowire = ClaimsListComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    claimslistAutowire, ClaimsListComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  claimslistComponentFactory,
};
