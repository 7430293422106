/**
 * @imarcsgroup/client:src/components/profile/index.js
 */

const { DateTime } = require('luxon');
const { toBirthdate } = require('../../utilities/datetime');
const { isArray } = require('@theroyalwhee0/istype');
const { assembleAddress, showVerifyAddressNotFound, showVerifyAddressMatches } = require('../../helpers/address');

/**
 * Profile component factory.
 */
function profileComponentFactory(dyn) {
  const { log, env, controllers, BaseComponent, stringTable, getState } = dyn();
  const {
    getProfile, profile, getConsents, setConsents, navigateTo,
    addressVerifications, sendVerifyEmail, verifyEmail,
    sendChangeEmail, changeEmail, logout,
  } = controllers;
  const { getString } = stringTable;

  /** Message context. */
  const context = 'profile';

  /** SSN hack. */
  let ssnRestore;

  /**
   * Profile Component.
   */
  class ProfileComponent extends BaseComponent {
    async onMount({ validation, attach, fields, onEvent, info, validateOnChange, validateOnRequest }) {
      log.trace(`Mounting profile component (#${this.id}).`);

      if(env && env !== 'prod') {
        console.log('======== ATTACHING DEBUG FAST EDIT PROFILE ========');
        const debugCheck = $('#editprofile_form').attr('data-for');
        $('#editprofile_first_name').on('dblclick', (evt) => {
          $('#editprofile_first_name').val('Test');
          $('#editprofile_middle_name').val('Portal');
          $('#editprofile_last_name').val('User');
          $('#editprofile_suffix>option:eq(1)').attr('selected', true);
          $('#editprofile_dob_yyyy').val('2000');
          $('#editprofile_dob_mm').val('10');
          $('#editprofile_dob_dd').val('11');
          $('#editprofile_phone').val('555-555-1111');
          if(debugCheck.includes('secondchance')) {
            if(evt.shiftKey || evt.ctrlKey) {
              $('#editprofile_address').val('2002 11th Ave N');
              $('#editprofile_city').val('Texas City');
              $('#editprofile_zipcode').val('77590');
              $('#editprofile_consent_satisfies-age-requirement').prop('checked', true);
              $('#editprofile_consent_within-jurisdiction-profile').prop('checked', true);
              $('#editprofile_consent_name-and-likeness').prop('checked', true);
            }
          }
          if(debugCheck.includes('claims')) {
            if(evt.shiftKey || evt.ctrlKey) {
              $('#editprofile_ssn').val('123456789');
              $('#editprofile_ssn_confirm').val('123456789');
              $('#editprofile_claims_address').val('2002 11th Ave N');
              $('#editprofile_claims_city').val('Texas City');
              $('#editprofile_claims_zipcode').val('77590');
              $('#editprofile_consent_satisfies-age-requirement-claims').prop('checked', true);
              $('#editprofile_consent_ssn-required-for-claims').prop('checked', true);
            }
            if(evt.shiftKey) {
              $('#editprofile_citizenship').val('RESIDENT');
              $('#editprofile_citizenship').change();
              $('#editprofile_claims_country').val('US');
              $('#editprofile_claims_country').change();
              $('#editprofile_claims_state').val('TX');
              $('#editprofile_claims_region').val('Harris');
              $('#editprofile_countryoforigin').val('');
              $('#editprofile_claims_province').val('');
            } else if(evt.ctrlKey) {
              $('#editprofile_citizenship').val('NON_RESIDENT');
              $('#editprofile_citizenship').change();
              $('#editprofile_countryoforigin').val('BE');
              $('#editprofile_claims_country').val('BE');
              $('#editprofile_claims_country').change();
              $('#editprofile_claims_province').val('Limburg');
              $('#editprofile_claims_state').val('');
              $('#editprofile_claims_region').val('');
            }
          }
        });
      }

      attach({
        form: ':self',
        email: '[name="email"]',
        email_confirm: '[name="email_confirm"]',
        first_name: '[name="first_name"]',
        middle_name: '[name="middle_name"]',
        last_name: '[name="last_name"]',
        suffix: '[name="suffix"]',
        birthday: '.ui-birthday',
        birthday_mm: '[name="birthday_mm"]',
        birthday_dd: '[name="birthday_dd"]',
        birthday_yyyy: '[name="birthday_yyyy"]',
        callingcode: '[name="callingcode"]',
        phone: '[name="phone"], [name="phone.1"], [name="phone.2"]',
        callingcode2: '[name="callingcode2"]',
        phone2: '[name="phone2"], [name="phone2.1"], [name="phone2.2"]',
        citizenship: '[name="citizenship"]',
        ssn: '[name="ssn"]',
        ssn_confirm: '[name="ssn_confirm"]',
        countryoforigin: '[name="countryoforigin"]',
        address: '[name="address"]',
        address2: '[name="address2"]',
        city: '[name="city"]',
        state: '[name="state"]',
        zipcode: '[name="zipcode"]',
        claims_address: '[name="claims_address"]',
        claims_address2: '[name="claims_address2"]',
        claims_city: '[name="claims_city"]',
        claims_state: '[name="claims_state"]',
        claims_province: '[name="claims_province"]',
        claims_zipcode: '[name="claims_zipcode"]',
        claims_zipcode4: '[name="claims_zipcode4"]',
        claims_country: '[name="claims_country"]',
        claims_region: '[name="claims_region"]',
        consents: '.ui-consent[type="checkbox"]',
        disable: [
          ':self',
          'input:not([name="ssn"], [name="birthday_yyyy"], .usemcaddress, .uselzaddress)',
          'select:not([name="countryoforigin"], [name="birthday_mm"], [name="birthday_dd"])',
          'button',
        ],
        submit: 'button[type="submit"], input[type="submit"]',
        reset: 'button[type="reset"], input[type="reset"]',
        notify: '.ui-profile-notify',
        messages: '.ui-msg',
      });
      fields({
        email: validation().email().required(),
        email_confirm: validation().email().equalsInsensitive('email').required(),
        first_name: validation().required(),
        middle_name: validation().optional(),
        last_name: validation().required(),
        suffix: validation().optional(),
        birthday_mm: validation().required(),
        birthday_dd: validation().required(),
        birthday_yyyy: validation().required(),
        callingcode: validation().optional(),
        phone: validation().required().removeOnSerialize(/[^0-9]/g),
        callingcode2: validation().optional(),
        phone2: validation().optional().removeOnSerialize(/[^0-9]/g),
        citizenship: validation().optional(),
        ssn: validation().optional().removeOnSerialize(/[^0-9]/g),
        ssn_confirm: validation().optional().removeOnSerialize(/[^0-9]/g),
        countryoforigin: validation().optional(),
        address: validation().optional(),
        address2: validation().optional(),
        city: validation().optional(),
        state: validation().optional(),
        zipcode: validation().optional(),
        claims_address: validation().optional(),
        claims_address2: validation().optional(),
        claims_city: validation().optional(),
        claims_state: validation().optional(),
        claims_province: validation().optional(),
        claims_zipcode: validation().optional(),
        claims_zipcode4: validation().optional(),
        claims_country: validation().optional(),
        claims_region: validation().optional(),
        '.consents': validation().checkbox().optional(),
      });
      info({
        for: ['[data-for]'],
        allowRole: ['[data-allow-role]'],
        denyRole: ['[data-deny-role]'],
        successPath: ['[data-success]'],
        addressVerification: ['[data-address-verification]'],
        validateOnRequest: ['[data-validate-on-request]'],
        pageContext: ['[data-context]'],
        minAge: () => Number(this.ele.find('.ui-birthday').attr('data-minage') || 18),
      });
      const addressNeedsVerification = this.hasRole('address-needs-verification');
      const addressUnsatisfied = this.hasRole('address-unsatisfied');
      const phoneUnsatisfied = this.hasRole('phone-unsatisfied');
      const hasNotificationRole = this.hasRole('notification');
      const hasSecondChanceRole = this.hasRole('secondchance');
      const hasClaimsRole = this.hasRole('claims');

      if(addressNeedsVerification || addressUnsatisfied || phoneUnsatisfied) {
        $('.editprofile_cancel').attr('href', '#').on('click', async (evt) => {
          evt.preventDefault();
          await logout('/home/');
          return;
        });
      }

      // Deny access to profile pages that are not for the user's current set of roles.
      const allowedRoles = this.info?.allowRole?.split(',') || [];
      const deniedRoles = this.info?.denyRole?.split(',') || [];
      const deniedAccess = deniedRoles.length !== 0 && deniedRoles.some((role) => {
        return this.hasRole(role);
      });
      const allowedAccess = allowedRoles.length === 0 || allowedRoles.every((role) => {
        return this.hasRole(role);
      });
      if(!allowedAccess || deniedAccess) {
        if(hasNotificationRole) {
          await navigateTo('/home/notification_landing/');
        } else if(hasSecondChanceRole && !hasClaimsRole) {
          await navigateTo('/home/lz_only_landing/');
        } else if(!hasSecondChanceRole && hasClaimsRole) {
          await navigateTo('/home/mc_only_landing/');
        } else {
          await logout('/home/');
        }
        return;
      }

      if(hasNotificationRole) {
        $('.satisfies-age-requirement_container').show();
        $('.satisfies-age-requirement-claims_container').show();
      }

      const restoreValues = await getProfile();
      ssnRestore = restoreValues.ssn;
      
      // Do not restore birthday if it is the default value.
      if(restoreValues.birthday_yyyy === '1900'
        && restoreValues.birthday_mm === '01'
        && restoreValues.birthday_dd === '01') {
        delete restoreValues.birthday_mm;
        delete restoreValues.birthday_dd;
        delete restoreValues.birthday_yyyy;
        $('[name="birthday_yyyy"]').removeAttr('readonly').removeAttr('disabled');
        $('[name="birthday_mm"]').removeAttr('readonly').removeAttr('disabled');
        $('[name="birthday_dd"]').removeAttr('readonly').removeAttr('disabled');
      }
      // Do not restore citizenship if it is UNSPECIFIED.
      if(restoreValues.citizenship === 'UNSPECIFIED') {
        delete restoreValues.citizenship;
      }
      
      this.populate(restoreValues);
      validateOnChange({ context });
      // Validate profile on load.
      if(this.info.validateOnRequest !== 'false') {
        await validateOnRequest({ context });
      }
      // Display notify message?
      const queryParams = new URLSearchParams(window.location.search);
      if(queryParams.get('notify') === '1') {
        this.elements.notify.show();
      }
      const formState = await this.formState();
      const email = this.elements.email.val();

      onEvent({
        form: {
          async reset(evt) {
            try {
              this.busy();
              this.populate(await getProfile(), true);
              this.clearMessages();
            } finally {
              this.ready();
            }
          },
          async submit(evt) {
            evt.preventDefault();
            try {
              this.busy();
              this.clearMessages();
              let { valid, serialized: values, meta, issues } = await this.validate();
              if(this.info.for) {
                values.for = this.info.for;
              }
              // Restore address verification state.
              if(restoreValues.addressVerified) {
                values.addressVerified = restoreValues.addressVerified;
              }
              if(restoreValues.claims_addressVerified) {
                values.claims_addressVerified = restoreValues.claims_addressVerified;
              }
              if(!issues.birthday) {
                // Combine and validate birthday.
                values.dob = toBirthdate(values.birthday_yyyy, values.birthday_mm, values.birthday_dd);
                if(isNaN(values.dob)) {
                  issues.birthday = {
                    key: 'birthday',
                    code: 'invalid',
                  };
                  valid = false;
                } else if(Number(values.birthday_yyyy) < 1900) {
                  issues.birthday = {
                    key: 'birthday',
                    code: 'too_old',
                  };
                  valid = false;
                } else {
                  log.trace(`Minimum age for registration is ${this.info.minAge}`);
                  const now = DateTime.utc();
                  const age = DateTime.fromMillis(values.dob).diff(now, 'years').years * -1;
                  if(age < this.info.minAge) {
                    issues.birthday = {
                      key: 'birthday',
                      code: 'too_young',
                      data: {
                        minAge: this.info.minAge,
                      },
                    };
                    valid = false;
                  }
                }
              }
              if(!valid) {
                // Combine birthday messages into a single message.
                if(issues.birthday_yyyy || issues.birthday_mm || issues.birthday_dd) {
                  let code;
                  if(issues.birthday_yyyy?.code === 'required' ||
                    issues.birthday_mm?.code === 'required' ||
                    issues.birthday_dd?.code === 'required'
                  ) {
                    code = 'required';
                  }
                  delete issues.birthday_yyyy;
                  delete issues.birthday_mm;
                  delete issues.birthday_dd;
                  issues.birthday = {
                    key: 'birthday',
                    code: code || 'invalid',
                  };
                }
                // Display messages.
                this.displayMessages({ context, issues });
                return false;
              }

              const dirtyFields = Object.keys(this.dirty);
              const emailChanged = dirtyFields.includes('email');
              if(emailChanged) {
                let emailVerified = false;
                const newEmail = this.elements.email.val();
                
                const changeEmailOldModal = $('#changeemailverifyold');
                const changeEmailOldForm = changeEmailOldModal.find('.ui-change-email-old');
                const msg = changeEmailOldForm.find('.ui-msg');
                const mfaCode = changeEmailOldForm.find('[name="code"]');
                const sendCode = changeEmailOldForm.find('.ui-sendcode');
                const submitButton = changeEmailOldModal.find('.ui-change-email-old button[type="submit"]');

                changeEmailOldForm.find('.ui-email').text(email);

                mfaCode.on('input', async (evt) => {
                  const input = $(evt.currentTarget);
                  const value = input.val();
                  if(value.length != 6) {
                    submitButton.prop('disabled', true);
                  } else {
                    submitButton.prop('disabled', false);
                  }
                });

                sendCode.on('click', async (evt) => {
                  const results = await sendVerifyEmail();
                  mfaCode.val('');
                  let responseText;
                  if(!results?.ok) {
                    const code = results?.code || 'general_error';
                    responseText = getString(`changeemail_old.${code}`).text;
                  } else {
                    responseText = getString(`changeemail_old.send_email`).text;
                  }
                  msg.text(responseText);
                });

                msg.text('');
                mfaCode.val('');
                changeEmailOldModal.removeAttr('hidden');
                changeEmailOldModal.modal('show');

                const results = await sendVerifyEmail();
                if(!results?.ok) {
                  const code = results?.code || 'general_error';
                  const responseText = getString(`changeemail_old.${code}`).text;
                  msg.text(responseText);
                  return false;
                }

                await new Promise((resolve) => {
                  changeEmailOldForm.on('submit', async (evt) => {
                    evt.preventDefault();
                    msg.text('');
                    const results = await verifyEmail(mfaCode.val(), false);
                    if(!results?.ok) {
                      mfaCode.val('');
                      const code = results?.code || 'general_error';
                      const responseText = getString(`changeemail_old.${code}`).text;
                      msg.text(responseText);
                    } else {
                      emailVerified = true;
                      resolve();
                    }
                  });
                });
                changeEmailOldModal.modal('hide');
                await new Promise((resolve) => {
                  changeEmailOldModal.on('hidden.bs.modal', () => {
                    resolve();
                  });
                });

                if(emailVerified) {
                  const changeEmailNewModal = $('#changeemailverifynew');
                  const changeEmailNewForm = changeEmailNewModal.find('.ui-change-email-new');
                  const msg = changeEmailNewForm.find('.ui-msg');
                  const mfaCode = changeEmailNewForm.find('[name="code"]');
                  const sendCode = changeEmailNewForm.find('.ui-sendcode');
                  const submitButton = changeEmailNewModal.find('.ui-change-email-new button[type="submit"]');
  
                  changeEmailNewForm.find('.ui-email').text(newEmail);
  
                  mfaCode.on('input', async (evt) => {
                    const input = $(evt.currentTarget);
                    const value = input.val();
                    if(value.length != 6) {
                      submitButton.prop('disabled', true);
                    } else {
                      submitButton.prop('disabled', false);
                    }
                  });

                  sendCode.on('click', async (evt) => {
                    const results = await sendChangeEmail(newEmail);
                    mfaCode.val('');
                    let responseText;
                    if(!results?.ok) {
                      const code = results?.code || 'general_error';
                      responseText = getString(`changeemail_new.${code}`).text;
                    } else {
                      responseText = getString(`changeemail_new.send_email`).text;
                    }
                    msg.text(responseText);
                  });
  
                  msg.text('');
                  mfaCode.val('');
                  changeEmailNewModal.removeAttr('hidden');
                  changeEmailNewModal.modal('show');

                  const results = await sendChangeEmail(newEmail);
                  if(!results?.ok) {
                    const code = results?.code || 'general_error';
                    const responseText = getString(`changeemail_new.${code}`).text;
                    msg.text(responseText);
                    return false;
                  }
  
                  await new Promise((resolve) => {
                    changeEmailNewForm.on('submit', async (evt) => {
                      evt.preventDefault();
                      msg.text('');
                      const results = await changeEmail(mfaCode.val());
                      if(!results?.ok) {
                        mfaCode.val('');
                        const code = results?.code || 'general_error';
                        const responseText = getString(`changeemail_new.${code}`).text;
                        msg.text(responseText);
                      } else {
                        emailVerified = true;
                        resolve();
                      }
                    });
                  });
                  changeEmailNewModal.modal('hide');
                  await new Promise((resolve) => {
                    changeEmailNewModal.on('hidden.bs.modal', () => {
                      resolve();
                    });
                  });
                }
              }

              const lzAddressFields = [
                'address', 'city', 'zipcode',
              ];
              const claimsAddressFields = [
                'claims_address', 'claims_city', 'claims_zipcode',
                'claims_zipcode4', 'claims_region',
              ];
              const lzAddressChanged = addressNeedsVerification || dirtyFields.some((value) => lzAddressFields.includes(value));
              const claimsAddressChanged = dirtyFields.some((value) => claimsAddressFields.includes(value));
              const addressChanged = lzAddressChanged || claimsAddressChanged;
              if(addressChanged && this.info.addressVerification) {
                let result = { };
                if(lzAddressChanged) {
                  const addressFields = {
                    address: values.address,
                    state: values.state,
                    city: values.city,
                    zipcode: values.zipcode,
                    zipcode4: values.zipcode4,
                  };
                  const address = assembleAddress(addressFields);
                  result = await this.verifyAddress(address);
                  if(!result || result.close) {
                    return false;
                  }
                  if(result.accept) {
                    values.address_accepted = true;
                    values.addressVerified = false;
                  } else if(result.edit) {
                    this.fields.address.ele.first().scrollAndFocus();
                    return false;
                  } else {
                    values.addressVerified = true;
                    values.address = result.address;
                    values.city = result.city;
                    values.state = result.state;
                    values.zipcode = result.zipcode;
                    values.zipcode4 = result.zipcode4;
                  }
                }

                if(claimsAddressChanged && !result.edit) {
                  if(values.claims_country === 'US' && values.claims_state === 'TX') {
                    const addressFields = {
                      address: values.claims_address,
                      state: values.claims_state,
                      city: values.claims_city,
                      zipcode: values.claims_zipcode,
                      zipcode4: values.claims_zipcode4,
                      county: values.claims_region,
                    };
                    const address = assembleAddress(addressFields);
                    result = await this.verifyAddress(address, true);
                    if(!result || result.close) {
                      return false;
                    }
                    if(result.accept) {
                      values.address_accepted = true;
                      values.claims_addressVerified = false;
                    } else if(result.edit) {
                      this.fields.claims_address.ele.first().scrollAndFocus();
                      return false;
                    } else {
                      values.claims_addressVerified = true;
                      values.claims_address = result.address;
                      values.claims_city = result.city;
                      values.claims_state = result.state;
                      values.claims_zipcode = result.zipcode;
                      values.claims_zipcode4 = result.zipcode4;
                      values.claims_region = result.county;
                    }
                  }
                }
              }

              const consentFields = [ 'within-jurisdiction-profile', 'satisfies-age-requirement', 'name-and-likeness', 'satisfies-age-requirement-claims', 'ssn-required-for-claims' ];
              const consents = {};
              for(const consentField of consentFields) {
                const [ consentKey, consentVersion = 'v1' ] = (meta[consentField]?.text || '').split(';');
                if(consentKey) {
                  const consentValue = values[consentField];
                  consents[consentKey] = {
                    value: consentValue,
                    version: consentVersion,
                  };
                }
              }
              const consentResults = await setConsents(consents);
              if(consentResults.code) {
                const { code } = consentResults;
                this.displayMessages({ message: [ context, code ] });
                return false;
              }
              const editProfileResults = await profile(values, formState);
              if(editProfileResults.code) {
                const { code } = editProfileResults;
                this.displayMessages({ message: [ context, code ] });
                return false;
              }

              // Re-enable selects before submit for "readonly select" hack.
              $('select[readonly]').prop('disabled', false);

              const successPath = this.info.successPath.startsWith('{') ? JSON.parse(this.info.successPath) : this.info.successPath;
              if(typeof successPath !== 'string') {
                if(hasNotificationRole) {
                  await navigateTo(successPath['notification']);
                } else if(hasSecondChanceRole && !hasClaimsRole) {
                  await navigateTo(successPath['secondchance']);
                } else if(hasClaimsRole && !hasSecondChanceRole) {
                  await navigateTo(successPath['claims']);
                } else {
                  await navigateTo(successPath['combined']);
                }
              } else {
                await navigateTo(successPath);
              }
              return true;
            } finally {
              this.ready();
            }
          },
        },
        citizenship: {
          'change': () => {
            this.citizenshipState();
          },
        },
        claims_country: {
          'change': () => {
            this.countryState();
          },
        },
        '.usemcaddress': {
          'change': (evt) => {
            const target = $(evt.target);
            const checked = target.prop('checked');
            if(checked) {
              this.elements.address.val(this.elements.claims_address.val());
              this.elements.city.val(this.elements.claims_city.val());
              this.elements.zipcode.val(this.elements.claims_zipcode.val());
            } else {
              this.elements.address.val('');
              this.elements.city.val('');
              this.elements.zipcode.val('');
            }
            this.elements.address.change();
            this.elements.city.change();
            this.elements.zipcode.change();
          },
        },
        '.uselzaddress': {
          'change': (evt) => {
            const target = $(evt.target);
            const checked = target.prop('checked');
            if(checked) {
              this.elements.claims_state.val('TX');
              this.elements.claims_address.val(this.elements.address.val());
              this.elements.claims_city.val(this.elements.city.val());
              this.elements.claims_zipcode.val(this.elements.zipcode.val());
            } else {
              this.elements.claims_address.val('');
              this.elements.claims_city.val('');
              this.elements.claims_zipcode.val('');
            }
            this.elements.claims_state.change();
            this.elements.claims_address.change();
            this.elements.claims_city.change();
            this.elements.claims_zipcode.change();
          },
        },
      });
      if(addressNeedsVerification) {
        this.displayMessages({ message: [ context, 'address.needs_verification' ] });
      }
    }

    async formState() {
      await this.citizenshipState();
      const ssn = await this.ssnState();
      await this.countryState();
      return {
        ssn,
      };
    }

    async citizenshipState() {
      const citizenship = this.elements.citizenship;
      if(citizenship) {
        const value = citizenship.val();
        const form = this.elements.form;
        const coo = form.find('#editprofile_countryoforigin');
        if(value !== 'NON_RESIDENT') {
          form.find('.countryoforigin').hide();
          if(!coo.prop('disabled')) {
            coo.val('');
          }
        } else {
          if(coo.val()) {
            coo.prop('disabled', true);
          }
          form.find('.countryoforigin').show();
        }
      }
    }

    async ssnState() {
      const ssn = this.elements.ssn;
      let populated = false;
      if(ssn && ssn.length) {
        const value = ssn.val();
        populated = ssnRestore !== null;
        if(populated) {
          $('.ssn_confirm').hide();
          ssn.prop('disabled', true);
          ssn.prop('readonly', true);
          let ssnEnd = value.slice(-4).padStart(4, '*');
          ssnEnd = ssnEnd.length === 4 ? ssnEnd : '****';
          const masked = `***-**-${ssnEnd}`;
          ssn.val(masked);
        }
      }
      return populated;
    }

    async countryState() {
      const claims_country = this.elements.claims_country;
      if(claims_country) {
        const value = claims_country.val();
        const form = this.elements.form;

        const claimsStateField = form.find('#editprofile_claims_state');
        if(value === 'US') {
          form.find('.claims_state').show();
          form.find('.claims_province').hide();
          form.find('#editprofile_claims_province').val('');
          claimsStateField.attr('required', true);

          form.find('#editprofile_claims_address').prop('placeholder', 'Address (USPS address format)');
          form.find('#editprofile_claims_zipcode').prop('placeholder', 'ZIP Code');
          form.find('#editprofile_claims_zipcode4').show();
          form.find('#editprofile_claims_region').show();

          if(claimsStateField.val() === 'TX') {
            form.find('.usemcaddress').attr('disabled', false);
          }
          form.find('.uselzaddress').attr('disabled', false);
        } else {
          form.find('.claims_province').show();
          form.find('.claims_state').hide();
          claimsStateField.attr('required', false).val('');

          form.find('#editprofile_claims_address').prop('placeholder', 'Address');
          form.find('#editprofile_claims_zipcode').prop('placeholder', 'Postal Code');
          form.find('#editprofile_claims_zipcode4').hide().val('');
          form.find('#editprofile_claims_region').hide().val('');

          form.find('.uselzaddress').attr('disabled', true);
        }
      }
    }

    async verifyAddress(address, allowPobox) {
      const [
        addressVerification, addressNotFound,
        addressInvalidBox, addressMissingBox,
      ] = this.info.addressVerification.split('|');
      const { ok, matches, exactMatch } = await addressVerifications(address, allowPobox);
      if(ok) {
        let results;
        const firstMatch = matches[0];
        const userZipExt = address?.zipcode?.split('-')[1];
        const userCounty = address?.county;
        const countyMatch = !userCounty || firstMatch?.county.toLowerCase() === userCounty.toLowerCase();
        const zipExtMatch = !userZipExt || firstMatch?.zipcodeAddon === userZipExt;

        if(exactMatch && countyMatch && zipExtMatch) {
          results = {
            selected: firstMatch,
          };
        } else if(firstMatch?.lookupCode === 'INVALID_BOX') {
          results = await showVerifyAddressNotFound({ address, selector: addressInvalidBox });
        } else if(firstMatch?.lookupCode === 'MISSING_BOX') {
          results = await showVerifyAddressNotFound({ address, selector: addressMissingBox });
        } else if(isArray(matches) && matches.length) {
          results = await showVerifyAddressMatches({ address, matches, selector: addressVerification });
        } else {
          results = await showVerifyAddressNotFound({ address, selector: addressNotFound });
        }
        let verifiedAddress = {};
        if((!results) || results.close) {
          return false;
        } else if(results.accept) {
          verifiedAddress.accept = true;
        } else if(results.edit) {
          verifiedAddress.edit = true;
        } else if(results.selected) {
          verifiedAddress = {
            city: results.selected.city,
            state: results.selected.state,
            zipcode: results.selected.zipcode,
            zipcode4: results.selected.zipcodeAddon,
            county: results.selected.county,
          };
          results.selected.address.forEach((item, idx) => {
            switch (idx) {
              case 0: {
                verifiedAddress.address = item;
                break;
              }
              default: {
                verifiedAddress.address2 = (values.address2 ? values.address2 + ', ' : '') + item;
                break;
              }
            }
          });
        } else {
          log.error({ results }, `Unhandled verify address results`);
          return false;
        }
        return verifiedAddress;
      } else {
        log.error('Failed to reach address lookup service.');
        return false;
      }
    }

    static autowireSelector() {
      return 'form.ui-editprofile';
    }
  }

  /**
   * Autowire.
   */
  const profileAutowire = ProfileComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    profileAutowire, ProfileComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  profileComponentFactory,
};
