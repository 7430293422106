/**
 * @imarcsgroup/client:src/strings/passwordupdate.js
 */

/**
 * Strings.
 */
const strings = {
  'passwordupdate.error': 'An error occurred updating your password.',
  'passwordupdate.service_unavailable': 'An error occurred updating your password.',
  'passwordupdate.old_password.required': 'Current Password is required.',
  'passwordupdate.new_password.required': 'New Password is required.',
  'passwordupdate.password_confirm.required': 'New Password Confirmation is required.',
  'passwordupdate.invalid_current_password': 'Your old password is invalid.',
};

/**
 * Exports.
 */
module.exports = strings;
