/**
 * @imarcsgroup/client:src/components/feedback/index.js
 */

/**
 * Feedback component factory.
 */
function feedbackComponentFactory(dyn) {
  const { log, controllers, BaseComponent } = dyn();
  const { feedback, navigateTo } = controllers;

  const context = 'feedback';

  /**
   * Feedback Component.
   */
  class FeedbackComponent extends BaseComponent {
    onMount({ validation, attach, fields, info, onEvent, validateOnChange }) {
      log.trace(`Mounting feedback component (#${this.id}).`);
      attach({
        form: ':self',
        email: 'input[name="email"]',
        name: 'input[name="name"]',
        comment: 'textarea[name="comment"]',
        disable: [ ':self', 'input, select, button' ],
        submit: 'button[type="submit"], input[type="submit"]',
        messages: '.ui-msg',
      });
      info({
        successPath: ['[data-success]'],
      });
      fields({
        email: validation().email().required(),
        name: validation().required(),
        comment: validation().required(),
      });
      validateOnChange({ context });
      onEvent({
        form: {
          async submit(evt) {
            evt.preventDefault();
            try {
              this.busy();
              this.clearMessages();
              const { valid, values, meta, issues } = await this.validate();
              if(!valid) {
                this.displayMessages({ context, issues });
                return false;
              }
              const { email, name, comment } = values;
              const feedbackResults = await feedback(email, name, comment);
              if(!feedbackResults?.ok) {
                const code = feedbackResults?.code || 'error';
                this.displayMessages({ message: [ context, code ] });
                return false;
              }
              log.info(`Feedback sent as "${email}".`);
              navigateTo(this.info.successPath);
              return true;
            } finally {
              this.ready();
            }
          },
        },
      });
    }

    static autowireSelector() {
      return 'form.ui-feedback';
    }
  }

  /**
   * Autowire.
   */
  const feedbackAutowire = FeedbackComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    feedbackAutowire, FeedbackComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  feedbackComponentFactory,
};
