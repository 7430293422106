/**
 * @imarcsgroup/client:src/config/index.js
 */

/**
 * Config Defaults factory.
 */
function configDefaultsFactory() {
  return {
    dateTime: {
      tz: 'utc',
      format: 'utc',
      locale: 'en-US',
    },
  };
}

/**
 * Exports.
 */
module.exports = {
  configDefaultsFactory,
};
