const { HTTP_OK, HTTP_SERVICE_UNAVAILABLE, HTTP_NO_CONTENT } = require('../../utilities/httpcode');
const { codeOrError } = require('../../utilities/rest');

function verifyEmailFactory(dyn) {
  const { apiFetch } = dyn();

  async function verifyEmail(code, save) {
    const pathCode = encodeURIComponent(code);
    const req = {
      self: true,
      method: 'PUT',
      path: `/api/v1/players/verify-contact/${pathCode}`,
    };
    if(save === false) {
      req.body = { save: false };
    }
    const { data, status } = await apiFetch(req);
    if(status === HTTP_SERVICE_UNAVAILABLE) {
      return { code: 'service_unavailable' };
    } else if(status !== HTTP_NO_CONTENT) {
      return codeOrError(data);
    }
    return {
      ok: true,
    };
  }

  async function changeEmail(code) {
    const pathCode = encodeURIComponent(code);
    const { data, status } = await apiFetch({
      self: true,
      method: 'POST',
      path: `/api/v1/oauth/self/mfa/redeem`,
      body: {
        'type': 'CHANGE_EMAIL',
        'token': code,
      },
    });
    if(status === HTTP_SERVICE_UNAVAILABLE) {
      return { code: 'service_unavailable' };
    } else if(status !== HTTP_OK) {
      return codeOrError(data);
    }
    return {
      ok: true,
    };
  }

  async function sendVerifyEmail() {
    const { data, status } = await apiFetch({
      method: 'POST',
      path: `/api/v1/oauth/self/mfa`,
      body: {
        'type': 'VERIFY_EMAIL',
      },
    });
    if(status === HTTP_SERVICE_UNAVAILABLE) {
      return { code: 'service_unavailable' };
    } else if(status !== HTTP_OK && status !== HTTP_NO_CONTENT) {
      return codeOrError(data);
    }
    return {
      ok: true,
    };
  }

  async function sendChangeEmail(email) {
    const { data, status } = await apiFetch({
      method: 'POST',
      path: `/api/v1/oauth/self/mfa`,
      body: {
        'type': 'CHANGE_EMAIL',
        'meta': JSON.stringify({ to: email }),
      },
    });
    if(status === HTTP_SERVICE_UNAVAILABLE) {
      return { code: 'service_unavailable' };
    } else if(status !== HTTP_OK && status !== HTTP_NO_CONTENT) {
      return codeOrError(data);
    }
    return {
      ok: true,
    };
  }

  return {
    changeEmail,
    verifyEmail,
    sendVerifyEmail,
    sendChangeEmail,
  };
}

module.exports = {
  verifyEmailFactory,
};
