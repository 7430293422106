const { DateTime } = require('luxon');
const { HTTP_OK } = require('../../utilities/httpcode');
const { codeOrError } = require('../../utilities/rest');

function assembleProfileFromRequest(profile, consents) {
  if(profile.code) {
    return {};
  }
  const dob = DateTime.fromMillis(profile?.dateOfBirth || -2208988799000, { zone: 'utc' });
  let data = {
    email: profile.emails.PERSONAL.address,
    email_confirm: profile.emails.PERSONAL.address,
    first_name: profile.firstName,
    middle_name: profile.middleName,
    last_name: profile.lastName,
    suffix: profile.suffix,
    dob: profile.dateOfBirth,
    citizenship: profile.citizenship,
    callingcode: profile.phones.MOBILE.callingCode,
    phone: profile.phones.MOBILE.number,
    callingcode2: profile.phones.HOME.callingCode,
    phone2: profile.phones.HOME.number,
    ssn: profile.nonpublicPersonalInfo.socialSecurityNumber,
    countryoforigin: profile.countryOfOrigin,
    address: profile.addresses.MAILING.address1,
    address2: profile.addresses.MAILING.address2,
    city: profile.addresses.MAILING.city,
    state: profile.addresses.MAILING.state || 'TX',
    zipcode: profile.addresses.MAILING.postalCode,
    birthday: dob,
    birthday_mm: (''+dob.month).padStart(2, '0'),
    birthday_dd: (''+dob.day).padStart(2, '0'),
    birthday_yyyy: (''+dob.year).padStart(2, '0'),
    'satisfies-age-requirement': consents.find((consent) => consent.consentName === 'satisfies-age-requirement' || consent.consentName === 'satisfies-age-requirement-claims'),
    'satisfies-age-requirement-claims': consents.find((consent) => consent.consentName === 'satisfies-age-requirement' || consent.consentName === 'satisfies-age-requirement-claims'),
  };
  if(profile.addresses.CLAIMS) {
    data = Object.assign(data, {
      claims_address: profile.addresses.CLAIMS.address1,
      claims_address2: profile.addresses.CLAIMS.address2,
      claims_city: profile.addresses.CLAIMS.city,
      claims_state: profile.addresses.CLAIMS.state,
      claims_province: profile.addresses.CLAIMS.state,
      claims_zipcode: profile.addresses.CLAIMS.postalCode,
      claims_zipcode4: profile.addresses.CLAIMS.postalCodeAddon,
      claims_country: profile.addresses.CLAIMS.isoCountryCode,
      claims_region: profile.addresses.CLAIMS.region,
    });
  }
  profile.verifications.forEach((verification) => {
    if(verification.name === 'kyc.address.MAILING') {
      data.addressVerified = verification.verificationStatuses[0].status === 'VERIFIED';
    }
    if(verification.name === 'kyc.address.CLAIMS') {
      data.claims_addressVerified = verification.verificationStatuses[0].status === 'VERIFIED';
    }
  });
  if(profile.for) {
    data.for = profile.for;
  }
  return data;
}

function assembleProfile(profile) {
  let data = {
    'for': profile.for,
    'firstName': profile.first_name,
    'middleName': profile.middle_name,
    'lastName': profile.last_name,
    'suffix': profile.suffix,
    'gender': 'UNSPECIFIED',
    'citizenship': profile.citizenship || 'UNSPECIFIED',
    'dateOfBirth': profile.dob,
    'countryOfOrigin': profile.countryoforigin || '',
    'addresses': {
      'MAILING': {
        'type': 'MAILING',
        'verified': !!profile.addressVerified,
        'address1': profile.address,
        'address2': profile.address2,
        'city': profile.city,
        'state': profile.state,
        'postalCode': profile.zipcode,
        'isoCountryCode': 'US',
      },
    },
    'phones': {
      'MOBILE': {
        'type': 'MOBILE',
        'callingCode': profile.callingcode || '1',
        'number': profile.phone,
      },
      'HOME': {
        'type': 'HOME',
        'callingCode': profile.callingcode2 || '1',
        'number': profile.phone2,
      },
    },
    'emails': {
      'PERSONAL': {
        'type': 'PERSONAL',
        'address': profile.email,
      },
    },
  };
  if(profile.for === 'claims' || profile.for === 'secondchance+claims') {
    Object.assign(data.addresses, {
      'CLAIMS': {
        'type': 'CLAIMS',
        'verified': !!profile.claims_addressVerified,
        'address1': profile.claims_address,
        'address2': profile.claims_address2,
        'city': profile.claims_city,
        'state': profile.claims_province || profile.claims_state || '',
        'postalCode': profile.claims_zipcode,
        'postalCodeAddon': profile.claims_zipcode4,
        'isoCountryCode': profile.claims_country,
        'region': profile.claims_region,
      },
    });
  }
  return data;
}

function assembleNonpublicPersonalInfo(profile) {
  const data = { };
  data.socialSecurityNumber = profile.ssn;
  return data;
}

function profileFactory(dyn) {
  const { apiFetch } = dyn();

  async function profile(profile, formState) {
    const body = assembleProfile(profile);
    const { data, status } = await apiFetch({
      method: 'PUT',
      path: '/api/v1/players/self/personal-info',
      body,
    });
    if(!(status === HTTP_OK)) {
      return codeOrError(data);
    }

    if(!formState.ssn) {
      const nppiBody = assembleNonpublicPersonalInfo(profile);
      const { data: nppiData, status: nppiStatus } = await apiFetch({
        method: 'PUT',
        path: '/api/v1/players/self/nonpublic-personal-info',
        body: nppiBody,
      });
      if(!(nppiStatus === HTTP_OK)) {
        return codeOrError(nppiData);
      }
    }

    return { ok: true };
  };

  async function getProfile() {
    const { status, data:profile } = await apiFetch({
      method: 'GET',
      path: '/api/v1/players/self/personal-info',
    });
    const { data:consents } = await apiFetch({
      method: 'GET',
      path: '/api/v1/players/self/consents',
    });
    return assembleProfileFromRequest(profile, consents);
  }
  
  return {
    profile, getProfile,
  };
}

module.exports = {
  profileFactory,
};

