const { HTTP_OK } = require('../../utilities/httpcode');
const { codeOrError } = require('../../utilities/rest');

function assembleProfile(profile) {
  let data = {};
  if(profile?.for === 'notification') {
    data = {
      'password': profile.password,
      'personalInfo': {
        'firstName': '',
        'middleName': '',
        'lastName': '',
        'suffix': '',
        'gender': 'UNSPECIFIED',
        'phones': {
          'MOBILE': {
            'type': 'MOBILE',
            'number': '',
          },
        },
        'addresses': { },
        'emails': {
          'PERSONAL': {
            'type': 'PERSONAL',
            'address': profile.email,
            'verified': 'NOT_VERIFIED', // TODO: This should not be required.
          },
        },
      },
    };
  } else if(profile?.for === 'secondchance') {
    data = {
      'password': profile.password,
      'personalInfo': {
        'firstName': profile.first_name,
        'middleName': profile.middle_name,
        'lastName': profile.last_name,
        'suffix': profile.suffix,
        'gender': 'UNSPECIFIED',
        'citizenship': 'UNSPECIFIED',
        'dateOfBirth': profile.dob,
        'addresses': {
          'MAILING': {
            'type': 'MAILING',
            'address1': profile.address,
            'city': profile.city,
            'state': profile.state,
            'postalCode': profile.zipcode,
            'postalCodeAddon': profile.zipcode_addon || null,
            'isoCountryCode': 'US',
          },
        },
        'phones': {
          'MOBILE': {
            'type': 'MOBILE',
            'number': profile.phone,
          },
          'HOME': {
            'type': 'HOME',
            'number': profile.phone2,
          },
        },
        'emails': {
          'PERSONAL': {
            'type': 'PERSONAL',
            'address': profile.email,
            'verified': 'NOT_VERIFIED', // TODO: This should not be required.
          },
        },
      },
      'nonpublicPersonalInfo': {
        'socialSecurityNumber': '',
      },
      'consents': [
        {
          'consentName': 'within-jurisdiction-profile',
          'version': 1,
          'enabled': profile['within-jurisdiction-profile'],
        },
        {
          'consentName': 'satisfies-age-requirement',
          'version': 1,
          'enabled': profile['satisfies-age-requirement'],
        },
        {
          'consentName': 'name-and-likeness',
          'version': 1,
          'enabled': profile['name-and-likeness'],
        },
      ],
      'verifications': [],
    };
    data.verifications.push({
      'name': 'kyc.address.MAILING',
      'verificationStatuses': [
        {
          'status': profile.address_accepted ? 'NOT_VERIFIED' : 'VERIFIED',
        },
      ],
    });
  } else if(profile?.for === 'claims') {
    data = {
      'password': profile.password,
      'personalInfo': {
        'firstName': profile.first_name,
        'middleName': profile.middle_name,
        'lastName': profile.last_name,
        'suffix': profile.suffix,
        'gender': 'UNSPECIFIED',
        'citizenship': profile.citizenship || 'UNSPECIFIED',
        'dateOfBirth': profile.dob,
        'countryOfOrigin': profile.countryoforigin || '',
        'addresses': {
          'CLAIMS': {
            'type': 'CLAIMS',
            'address1': profile.claims_address,
            'city': profile.claims_city,
            'state': profile.claims_province || profile.claims_state || '',
            'postalCode': profile.claims_zipcode,
            'postalCodeAddon': profile.claims_zipcode4,
            'isoCountryCode': profile.claims_country,
            'region': profile.claims_region,
          },
        },
        'phones': {
          'MOBILE': {
            'type': 'MOBILE',
            'number': profile.phone,
          },
          'HOME': {
            'type': 'HOME',
            'number': profile.phone2,
          },
        },
        'emails': {
          'PERSONAL': {
            'type': 'PERSONAL',
            'address': profile.email,
            'verified': 'NOT_VERIFIED', // TODO: This should not be required.
          },
        },
      },
      'nonpublicPersonalInfo': {
        'socialSecurityNumber': profile.ssn,
      },
      'consents': [
        {
          'consentName': 'satisfies-age-requirement-claims',
          'version': 1,
          'enabled': profile['satisfies-age-requirement-claims'],
        },
        {
          'consentName': 'ssn-required-for-claims',
          'version': 1,
          'enabled': profile['ssn-required-for-claims'],
        },
      ],
      'verifications': [],
    };
    data.verifications.push({
      'name': 'kyc.address.CLAIMS',
      'verificationStatuses': [
        {
          'status': profile.address_accepted ? 'NOT_VERIFIED' : 'VERIFIED',
        },
      ],
    });
  } else if(profile?.for === 'secondchance+claims') {
    data = {
      'password': profile.password,
      'personalInfo': {
        'firstName': profile.first_name,
        'middleName': profile.middle_name,
        'lastName': profile.last_name,
        'suffix': profile.suffix,
        'gender': 'UNSPECIFIED',
        'citizenship': profile.citizenship || 'UNSPECIFIED',
        'dateOfBirth': profile.dob,
        'countryOfOrigin': profile.countryoforigin || '',
        'addresses': {
          'MAILING': {
            'type': 'MAILING',
            'address1': profile.address,
            'city': profile.city,
            'state': profile.state,
            'postalCode': profile.zipcode,
            'postalCodeAddon': profile.zipcode_addon || null,
            'isoCountryCode': 'US',
          },
          'CLAIMS': {
            'type': 'CLAIMS',
            'address1': profile.claims_address,
            'city': profile.claims_city,
            'state': profile.claims_province || profile.claims_state || '',
            'postalCode': profile.claims_zipcode,
            'postalCodeAddon': profile.claims_zipcode4,
            'isoCountryCode': profile.claims_country,
            'region': profile.claims_region,
          },
        },
        'phones': {
          'MOBILE': {
            'type': 'MOBILE',
            'number': profile.phone,
          },
          'HOME': {
            'type': 'HOME',
            'number': profile.phone2,
          },
        },
        'emails': {
          'PERSONAL': {
            'type': 'PERSONAL',
            'address': profile.email,
            'verified': 'NOT_VERIFIED', // TODO: This should not be required.
          },
        },
      },
      'nonpublicPersonalInfo': {
        'socialSecurityNumber': profile.ssn,
      },
      'consents': [
        {
          'consentName': 'satisfies-age-requirement',
          'version': 1,
          'enabled': profile['satisfies-age-requirement-claims'],
        },
        {
          'consentName': 'satisfies-age-requirement-claims',
          'version': 1,
          'enabled': profile['satisfies-age-requirement-claims'],
        },
        {
          'consentName': 'within-jurisdiction-profile',
          'version': 1,
          'enabled': profile['within-jurisdiction-profile'],
        },
        {
          'consentName': 'name-and-likeness',
          'version': 1,
          'enabled': profile['name-and-likeness'],
        },
        {
          'consentName': 'ssn-required-for-claims',
          'version': 1,
          'enabled': profile['ssn-required-for-claims'],
        },
      ],
      'verifications': [],
    };
    data.verifications.push({
      'name': 'kyc.address.MAILING',
      'verificationStatuses': [
        {
          'status': profile.address_accepted ? 'NOT_VERIFIED' : 'VERIFIED',
        },
      ],
    });
    data.verifications.push({
      'name': 'kyc.address.CLAIMS',
      'verificationStatuses': [
        {
          'status': profile.address_accepted ? 'NOT_VERIFIED' : 'VERIFIED',
        },
      ],
    });
  }
  if(profile.for) {
    data.for = profile.for;
  }
  return data;
}

function registerFactory(dyn) {
  const { apiFetch } = dyn();
  async function register(profile) {
    const body = assembleProfile(profile);
    const { data, status } = await apiFetch({
      method: 'POST',
      path: `/api/v1/players`,
      body: {
        ...body,
      },
    });
    if(!(status === HTTP_OK)) {
      return codeOrError(data);
    }
    return {};
  };

  return {
    register,
  };
}

module.exports = {
  registerFactory,
};
