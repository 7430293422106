function feedbackFactory(dyn) {
  const { apiFetch } = dyn();

  async function feedback(email, name, comment) {
    const { data, status } = await apiFetch({
      self: true,
      method: 'POST',
      path: `/api/v1/feedback/`,
      body: {
        'email': email,
        'name': name,
        'body': comment,
      },
    });
    if(status !== 204) {
      return {
        ok: false,
      };
    }
    return {
      ok: true,
    };
  };

  return {
    feedback,
  };
}

module.exports = {
  feedbackFactory,
};
