/**
 * @imarcsgroup/client:src/strings/ticketentry.js
 */

/**
 * Strings.
 */
const strings = {
  'ticketentry.error': 'An error has occurred while trying to submit a ticket.',
  'ticketentry.game_unknown': 'Game number "{{gamenumber}}" is not recognized.',
  'ticketentry.ticket_unknown': 'Ticket "{{ticket}}" is not valid or is a winning ticket.',
  'ticketentry.ticket_dupe_self': 'You have already entered this ticket.',
  'ticketentry.ticket_dupe_other': 'Another player has already entered this ticket.',
  'ticketentry.temporary_lockout': 'Too many invalid submissions. Please try again later.',
  'ticketentry.ticket.required': 'Ticket is required.',
  'ticketentry.success': { text: 'Your entry "{{ticket}}" has been accepted.', type: 'success' },
};

/**
 * Exports.
 */
module.exports = strings;
