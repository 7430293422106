const { isArray } = require('@theroyalwhee0/istype');

const LOOKUP_CODE = {
  '1': 'VALID_ADDRESS',
  '2': 'INVALID_ADDRESS',
  '3': 'INVALID_BOX',
  '4': 'MISSING_BOX',
};

function addressVerificationsFactory(dyn) {
  const { log, apiFetch } = dyn();

  async function addressVerifications(address, allowPobox) {
    const query = {
      city: address.city,
      state: address.state,
      postalcode: address.zipcode,
      address: '',
      address2: '',
    };
    for(let idx = 0; idx < address.address.length; idx++) {
      const item = address.address[idx];
      switch (idx) {
        case 0: {
          query.address = item;
          break;
        }
        default: {
          query.address2 = (query.address2 ? query.address2 + '\n' : '') + item;
          break;
        }
      }
    }
    let ok = true;
    let exactMatch = false;
    let lookupCode;
    const matches = [];
    const { data, status } = await apiFetch({
      self: true,
      method: 'GET',
      path: `/api/v1/kyc/address`,
      query,
    });
    if(data && status === 200) {
      const addresses = data?.lookup?.Addresses;
      if(isArray(addresses)) {
        for(let idx = 0; idx < addresses.length; idx++) {
          const address = addresses[idx];
          const dpv = address.DPV;
          const corrections = address.Corrections;
          const dpvNotes = address.DPVNotes.split(',');

          // Is the address in the list of valid states?
          if(address.State !== 'TX') {
            log.trace(`Skipping address, not in Texas.`);
            continue;
          }

          // Is the address a PO box?
          if(!allowPobox && dpvNotes.includes('41')) {
            log.trace(`Skipping address, PO Box.`);
            continue;
          }

          lookupCode = LOOKUP_CODE[dpv];

          const fullAddress = [];
          fullAddress.push(address.Address1);
          if(address.Address2) {
            fullAddress.push(address.Address2);
          }
          const zipcode = address.Zip.split('-');
          const validAddress = {
            address: fullAddress,
            city: address.City,
            state: address.State,
            zipcode: zipcode[0],
            zipcodeAddon: zipcode[1],
            county: address.CountyName,
            lookupCode,
          };
          matches.push(validAddress);

          if(dpv === '1' && corrections.length === 0) {
            exactMatch = true;
            break;
          }
        }
      } else {
        log.trace(`No addresses found.`);
      }
    } else {
      ok = false;
      log.trace(`Failed to reach address lookup service.`);
    }
    return {
      ok,
      matches,
      exactMatch,
    };
  };

  return {
    addressVerifications,
  };
}

module.exports = {
  addressVerificationsFactory,
};
