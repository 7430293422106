function regExpFactory(value) {
  const re_parse = /^\/(.+)\/([A-Za-z]*)$/;
  const match = re_parse.exec(value);
  if(match) {
    const pattern = match[1];
    const options = match[2];
    return new RegExp(pattern, options);
  } else {
    throw new Error(`"${value}" is not a valid RegExp.`);
  }
}

function testerFactory(values) {
  const regexs = [];
  for(let idx = 0; idx < values.length; idx++) {
    let invert = false;
    let value = values[idx];
    if(/^!/.test(value)) {
      invert = true;
      value = value.slice(1);
    }
    const re = regExpFactory(value);
    regexs.push({ re, invert });
  }
  return {
    test(value) {
      for(let idx = 0; idx < regexs.length; idx++) {
        const { invert, re } = regexs[idx];
        const test = re.test(value);
        const check = invert ? !test : test;
        if(!check) {
          return false;
        }
      }
      return true;
    },
  };
}

function patternFactory(value) {
  return new RegExp(value);
}

module.exports = {
  regExpFactory,
  testerFactory,
  patternFactory,
};
