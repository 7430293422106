/**
 * @imarcsgroup/client:src/components/claimEntry/index.js
 */

/**
 * ClaimEntry component factory.
 */
function claimEntryComponentFactory(dyn) {
  const { log, controllers, BaseComponent, getState } = dyn();
  const { navigateTo, submitClaimForm } = controllers;

  /** Message context. */
  const context = 'claimentry';

  let rowIdent = 0;
  let component = {};
  let validateOnChange;

  /**
   * ClaimEntry Component.
   */
  class ClaimEntryComponent extends BaseComponent {
    getRowCount() {
      return $('.gamename').filter(':visible').length;
    }

    updateFields() {
      const { validation, attach, fields, pruneFields } = component;
      attach({
        prize: '.ui-msg-prize',
        games: '.gamename',
        prizevalues: '.prizevalue',
        messages: '.ui-msg',
      });
      fields({
        '.games': validation().text().required(),
        '.prizevalues': validation().text().required().pattern(/^\d+$/).gt(0).lt(1000000),
      });
      pruneFields();
      validateOnChange({ context });
      if(this.getRowCount() <= 1) {
        $('.ticket_delete').hide();
      } else {
        $('.ticket_delete').show();
      }
    }

    onMount({ validation, attach, info, fields, pruneFields, onEvent, onValid, onInvalid,
      validateOnSubmit, validateOnRequest, validateOnChange: validateOnChangeHandler }) {
      log.trace(`Mounting claim-entry component (#${this.id}).`);
      validateOnChange = validateOnChangeHandler;
      component = { validation, attach, fields, pruneFields };
      attach({
        form: ':self',
        addticket: '#addticket',
        //disable: [ ':self', 'input, select, button' ],
        submit: 'button[type="submit"], input[type="submit"]',
        messages: '.ui-msg',
        tpl: '.ui-template-row',
        entry_anchor: '#claim_entry_anchor',
      });
      onValid(() => {
        this.elements.submit.prop('disabled', false).removeAttr('disabled');
      });
      onInvalid(() => {
        this.elements.submit.prop('disabled', true).attr('disabled', 'disabled');
      });
      let validationContext = {};
      let numberOfTickets = 0;
      let totalPrizeValue = 0;
      info({
        successPath: ['[data-success]'],
        onFieldValidate: () => {
          return (field) => {
            const { idx, key, value, issue, dirty, rules, metadata, allValues: values } = field;
            if(idx === 0) {
              validationContext = {};
              numberOfTickets = 0;
              totalPrizeValue = 0;
            }
            validationContext[key] = field;
            if(idx !== Object.keys(values).length - 1) {
              return field;
            }
            for(const key in validationContext) {
              const field = validationContext[key];
              const validationRowIdx = Number(key.match(/^gamename_(\d+)/)?.[1]);
              if(!Number.isSafeInteger(validationRowIdx)) {
                continue;
              }
              const rowAccepted = this.elements.form.find(`[name="${key}"]`).parents('.form-group').find('.ticket_accepted');
              if(!rowAccepted.length) {
                log.warn(`Row accepted not found for "${key}".`);
              }
              const gameName = field;
              const prizeValue = validationContext[`prizevalue_${validationRowIdx}`];
              if(gameName.issue || !gameName.dirty || prizeValue.issue || !prizeValue.dirty) {
                rowAccepted.hide();
              } else {
                rowAccepted.show();
                numberOfTickets++;
                totalPrizeValue += Number(prizeValue.value);
              }
            }
            $('.totalticketcount').text(numberOfTickets);
            $('.totalprizevalue').text(totalPrizeValue.toLocaleString('en-US'));

            const largeTickets = Object.entries(validationContext)
              .filter(([key]) => key.startsWith('prizevalue_'))
              .filter(([ , field ]) => field.value >= 600);
            if(largeTickets.length !== 0 || numberOfTickets >= 100) {
              this.elements.addticket.hide();
            } else {
              this.elements.addticket.show();
            }
            if(numberOfTickets > 1 && largeTickets.length !== 0) {
              largeTickets.forEach(([key]) => {
                const rowAccepted = this.elements.form.find(`[name="${key}"]`).parents('.form-group').find('.ticket_accepted');
                rowAccepted.hide();
              });
              return { messages: [{ key: 'primary', message: `${context}.prize.total` }] };
            }
            return field;
          };
        },
      });
      this.addRow = async (gameName, prizeValue) => {
        const row = this.elements.tpl.clone();
        row.find('.ui-msg').addClass(`ui-msg-gamename_${rowIdent} ui-msg-prizevalue_${rowIdent}`);
        if(this.getRowCount() >= 100) {
          return;
        }
  
        const gameNameSelect = row.find('.gamename');
        const prizeValueInput = row.find('.prizevalue');
        gameNameSelect.attr('name', `gamename_${rowIdent}`);
        prizeValueInput.attr('name', `prizevalue_${rowIdent}`);

        if(gameName) {
          gameNameSelect.find(`option[value="${gameName}"]`).prop('selected', true);
        }
        if(prizeValue) {
          prizeValueInput.val(prizeValue);
        }
  
        row.find('.ticket_delete').on('click', async (evt) => {
          evt.preventDefault();
          try {
            row.remove();
            this.updateFields();
            await validateOnRequest({ context, showDirty: false });
          } catch(ex) {
            log.error(ex);
          }
        });
  
        row.removeAttr('hidden').removeClass('ui-template-row');
        row.insertBefore(this.elements.entry_anchor);
        this.updateFields();
        rowIdent++;
        await validateOnRequest({ context, showDirty: false });
        console.log('element row count', this.getRowCount());
        return { row, gameNameSelect, prizeValueInput };
      };
      if(this.getRowCount() === 0) {
        // const addAllRows = async () => {
        //   const rows = [];
        //   for(let loop=0; loop < 110; loop++) {
        //     const row = await this.addRow('scratch', 1);
        //     if(row) {
        //       rows.push(row);
        //     }
        //   }
        //   console.log('addAllRows row count', rows.length);
        //   rows.forEach(({ row, gameNameSelect, prizeValueInput }) => {
        //     if(!gameNameSelect || !prizeValueInput) {
        //       return;
        //     }
        //     gameNameSelect.change();
        //     prizeValueInput.change();
        //   });
        //   console.log('element row count', this.getRowCount());
        // };
        // addAllRows();
        this.addRow();
      }
      onEvent({
        form: {
          async submit(evt) {
            evt.preventDefault();
            try {
              this.busy();
              this.clearMessages();
              let { valid } = await validateOnSubmit({ context });
              if(!valid) {
                return false;
              }
              const data = { numberOfTickets, totalPrizeValue: totalPrizeValue * 100 };
              const results = await submitClaimForm(data);
              if(results.code) {
                const { code } = results;
                this.displayMessages({ message: [ context, code ] });
                return false;
              }
              const successPath = new URL(this.info.successPath, window.location.origin);
              successPath.search = `?claimId=${results.id}`;
              await navigateTo(successPath.href);
              return true;
            } finally {
              this.ready();
            }
          },
        },
        addticket: {
          async click(evt) {
            evt.preventDefault();
            return this.addRow();
          },
        },
      });
      return () => {
        this.elements.submit.prop('disabled', true).attr('disabled', 'disabled');
      };
    }

    static autowireSelector() {
      return 'form.ui-claimentry';
    }
  }

  /**
   * Autowire.
   */
  const claimEntryAutowire = ClaimEntryComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    claimEntryAutowire, ClaimEntryComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  claimEntryComponentFactory,
};
