/**
 * Imports.
 */
const { isArray, isString, isObject } = require('@theroyalwhee0/istype');
const { HTTP_OK, HTTP_SERVICE_UNAVAILABLE, HTTP_TOO_MANY_REQUESTS } = require('../../utilities/httpcode');
const { codeOrError } = require('../../utilities/rest');

/**
 * Constants.
 */
const svcNotifyHeader = 'x-svc-notify';
const mfaRequiredHeader = 'x-svc-mfa-required';

/**
 * LogIn controller factory.
 * @param {DynastyDepends} dyn Dynasty depends function.
 * @returns {object} The object with built functions in it.
 */
async function loginFactory(dyn) {
  const { log, apiFetch, siteId, clientId, dispatch, actions, getState } = dyn();
  const { sessionFromTokens } = actions;
  async function login(username, password) {
    // LogIn to get AuthCode.
    const { data: authData, status: authStatus, headers: authHeaders } = await apiFetch({
      method: 'PUT',
      path: `/api/v1/oauth/login/`,
      body: {
        siteId, clientId,
        'resourceOwnerCredentials': {
          'USERNAME': username,
          'PASSWORD': password,
        },
      },
    });
    if(authStatus === HTTP_SERVICE_UNAVAILABLE) {
      return { code: 'service_unavailable' };
    }
    if(authStatus === HTTP_TOO_MANY_REQUESTS) {
      return { code: 'too_many_requests' };
    }
    if(!(
      authStatus === HTTP_OK &&
      isArray(authData) &&
      authData.length === 1 &&
      isObject(authData[0]) &&
      isString(authData[0].authCode)
    )) {
      return codeOrError(authData);
    }
    let mfaRequired = true;
    const mfaHeader = authHeaders[mfaRequiredHeader];
    if(isArray(mfaHeader) && mfaHeader.length > 0) {
      mfaRequired = mfaHeader[0] === 'true';
    }
    return { ok: true, authData, authStatus, authHeaders, mfaRequired };
  }

  async function verifyLogin(loginData, mfaToken) {
    const authHeaders = loginData.authHeaders;

    // Use AuthCode to get OAuth & Refresh Tokens.
    let authCode = loginData.authData[0].authCode;
    if(mfaToken) {
      authCode += `,${mfaToken}`;
    }
    const { data: tokenData, status: tokenStatus } = await apiFetch({
      method: 'POST',
      path: `/api/v1/oauth/self/tokens`,
      body: { siteId, clientId, authCode },
    });
    if(!(
      tokenStatus === HTTP_OK &&
      isArray(tokenData) &&
      tokenData.length === 2 &&
      isObject(tokenData[0]) &&
      isString(tokenData[0].token) &&
      isObject(tokenData[1]) &&
      isString(tokenData[1].token)
    )) {
      return codeOrError(tokenData);
    }
    // Setup session in store.
    dispatch(sessionFromTokens(tokenData));
    const roles = getState((_) => _.session.roles || []);
    // Service Notification Header.
    const notifyHeader = authHeaders[svcNotifyHeader];
    let notifications;
    if(isArray(notifyHeader) && notifyHeader.length > 0) {
      if(notifyHeader.length > 1) {
        throw new Error(`Multiple "${svcNotifyHeader}" response headers found`);
      }
      notifications = notifyHeader.join(' ').split(' ');
    }
    log.debug(`User has roles "${roles.join(', ')}"`);
    return { ok: true, notifications, roles };
  };

  return {
    login, verifyLogin,
  };
}

/**
 * Exports.
 */
module.exports = {
  loginFactory,
};
