/**
 * @imarcsgroup/client:src/components/lopgout/index.js
 */

const { CLAIM_ACTIVE_STATE } = require('../../utilities/claimstate');

/**
 * CloseAccount component factory.
 */
function closeaccountComponentFactory(dyn) {
  const { log, BaseComponent, controllers, store, getState } = dyn();
  const { navigateTo, closeAccount, logout, claimslist } = controllers;

  /** Message context. */
  const context = 'closeaccount';

  /**
   * CloseAccount Component.
   */
  class CloseAccountComponent extends BaseComponent {
    onMount({ attach, onEvent }) {
      log.trace(`Mounting close-account component (#${this.id}).`);
      const url = this.ele.attr('href') ?? this.ele.attr('data-href') ?? '';
      const roles = getState((_) => _.session.roles);

      attach({
        dialog: ':self',
        close_btn: 'button.modal_cancel',
        submit_btn: '.closeaccount_close',
        messages: '.ui-msg',
      });
      onEvent({
        'button.modal_cancel': {
          async click(evt) {
            this.elements.dialog.modal('hide');
          },
        },
        '.closeaccount_close': {
          async click(evt) {
            evt.preventDefault();
            let valid = true;
            const issues = { };
            log.trace(`Closing account.`);
            if(roles.includes('claims')) {
              const { data, status } = await claimslist();
              let claimsActive = false;
              if(status === 200) {
                for(const claim of data) {
                  if(CLAIM_ACTIVE_STATE[claim.claimState]) {
                    claimsActive = true;
                    break;
                  }
                }
              }
              if(claimsActive) {
                log.warn(`Cannot close account with active claims.`);
                valid = false;
                issues.close = { key: 'close', code: 'active_claim' };
                this.ele.find('.buttonarea').hide();
                this.ele.find('.confirmonly').show();
              }
            }
            if(!valid) {
              this.displayMessages({ context, issues });
              return false;
            }
            
            await closeAccount();
            await logout();
            navigateTo(url);
          },
        },
      });
    }

    static autowireSelector() {
      return '.ui-closeaccount';
    }
  }

  /**
   * Autowire.
   */
  const closeaccountAutowire = CloseAccountComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    closeaccountAutowire, CloseAccountComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  closeaccountComponentFactory,
};
