function claimFormFactory(dyn) {
  const { apiFetch } = dyn();

  async function submitClaimForm(values) {
    const { status, data } = await apiFetch({
      method: 'POST',
      path: '/api/v1/claims/self/claimform',
      body: { ...values },
    });
    return { ...data };
  };

  return {
    submitClaimForm,
  };
}

module.exports = {
  claimFormFactory,
};
