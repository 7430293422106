/**
 * @imarcsgroup/client:src/components/profilenotification/index.js
 */

/**
 * Notification Profile component factory.
 */
function notificationProfileComponentFactory(dyn) {
  const { log, controllers, BaseComponent, stringTable } = dyn();
  const {
    getProfile, navigateTo,
    sendVerifyEmail, verifyEmail,
    sendChangeEmail, changeEmail,
  } = controllers;
  const { getString } = stringTable;

  /** Message context. */
  const context = 'profile';

  /**
   * Notification Profile Component.
   */
  class NotificationProfileComponent extends BaseComponent {
    async onMount({ validation, attach, fields, onEvent, info, validateOnChange, validateOnRequest }) {
      log.trace(`Mounting profile component (#${this.id}).`);
      attach({
        form: ':self',
        email: '[name="email"]',
        email_confirm: '[name="email_confirm"]',
        disable: [ ':self', 'button' ],
        submit: 'button[type="submit"], input[type="submit"]',
        reset: 'button[type="reset"], input[type="reset"]',
        notify: '.ui-profile-notify',
        messages: '.ui-msg',
      });
      fields({
        email: validation().email().required(),
        email_confirm: validation().email().equalsInsensitive('email').required(),
      });
      info({
        for: ['[data-for]'],
        allowRole: ['[data-allow-role]'],
        denyRole: ['[data-deny-role]'],
        successPath: ['[data-success]'],
        validateOnRequest: ['[data-validate-on-request]'],
      });
      const hasNotificationRole = this.hasRole('notification');
      const hasSecondChanceRole = this.hasRole('secondchance');
      const hasClaimsRole = this.hasRole('claims');

      // Deny access to profile pages that are not for the user's current set of roles.
      const allowedRoles = this.info?.allowRole?.split(',') || [];
      const deniedRoles = this.info?.denyRole?.split(',') || [];
      const deniedAccess = deniedRoles.length !== 0 && deniedRoles.some((role) => {
        return this.hasRole(role);
      });
      const allowedAccess = allowedRoles.length === 0 || allowedRoles.every((role) => {
        return this.hasRole(role);
      });
      if(!allowedAccess || deniedAccess) {
        if(hasNotificationRole) {
          await navigateTo('/home/notification_preferences');
        } else if(hasSecondChanceRole && !hasClaimsRole) {
          await navigateTo('/home/enter/');
        } else if(!hasSecondChanceRole && hasClaimsRole) {
          await navigateTo('/home/claims_home/');
        } else {
          await navigateTo('/home/landing/');
        }
        return;
      }

      const restoreValues = await getProfile();
      this.populate(restoreValues);
      validateOnChange({ context });
      // Validate profile on load.
      if(this.info.validateOnRequest !== 'false') {
        await validateOnRequest({ context });
      }
      // Display notify message?
      const queryParams = new URLSearchParams(window.location.search);
      if(queryParams.get('notify') === '1') {
        this.elements.notify.show();
      }
      const formState = { ssn: true };
      const email = this.elements.email.val();

      onEvent({
        form: {
          async reset(evt) {
            try {
              this.busy();
              this.populate(await getProfile(), true);
              this.clearMessages();
            } finally {
              this.ready();
            }
          },
          async submit(evt) {
            evt.preventDefault();
            try {
              this.busy();
              this.clearMessages();
              let { valid, serialized: values, meta, issues } = await this.validate();
              if(this.info.for) {
                values.for = this.info.for;
              }
              if(!valid) {
                // Display messages.
                this.displayMessages({ context, issues });
                return false;
              }

              const dirtyFields = Object.keys(this.dirty);
              const emailChanged = dirtyFields.includes('email');
              if(emailChanged) {
                let emailVerified = false;
                const newEmail = this.elements.email.val();
                
                const changeEmailOldModal = $('#changeemailverifyold');
                const changeEmailOldForm = changeEmailOldModal.find('.ui-change-email-old');
                const msg = changeEmailOldForm.find('.ui-msg');
                const mfaCode = changeEmailOldForm.find('[name="code"]');
                const sendCode = changeEmailOldForm.find('.ui-sendcode');
                const submitButton = changeEmailOldModal.find('.ui-change-email-old button[type="submit"]');

                changeEmailOldForm.find('.ui-email').text(email);

                mfaCode.on('input', async (evt) => {
                  const input = $(evt.currentTarget);
                  const value = input.val();
                  if(value.length != 6) {
                    submitButton.prop('disabled', true);
                  } else {
                    submitButton.prop('disabled', false);
                  }
                });

                sendCode.on('click', async (evt) => {
                  const results = await sendVerifyEmail();
                  mfaCode.val('');
                  let responseText;
                  if(!results?.ok) {
                    const code = results?.code || 'general_error';
                    responseText = getString(`changeemail_old.${code}`).text;
                  } else {
                    responseText = getString(`changeemail_old.send_email`).text;
                  }
                  msg.text(responseText);
                });

                msg.text('');
                mfaCode.val('');
                changeEmailOldModal.removeAttr('hidden');
                changeEmailOldModal.modal('show');

                const results = await sendVerifyEmail();
                if(!results?.ok) {
                  const code = results?.code || 'general_error';
                  const responseText = getString(`changeemail_old.${code}`).text;
                  msg.text(responseText);
                  return false;
                }

                await new Promise((resolve) => {
                  changeEmailOldForm.on('submit', async (evt) => {
                    evt.preventDefault();
                    msg.text('');
                    const results = await verifyEmail(mfaCode.val(), false);
                    if(!results?.ok) {
                      mfaCode.val('');
                      const code = results?.code || 'general_error';
                      const responseText = getString(`changeemail_old.${code}`).text;
                      msg.text(responseText);
                    } else {
                      emailVerified = true;
                      resolve();
                    }
                  });
                });
                changeEmailOldModal.modal('hide');
                await new Promise((resolve) => {
                  changeEmailOldModal.on('hidden.bs.modal', () => {
                    resolve();
                  });
                });

                if(emailVerified) {
                  const changeEmailNewModal = $('#changeemailverifynew');
                  const changeEmailNewForm = changeEmailNewModal.find('.ui-change-email-new');
                  const msg = changeEmailNewForm.find('.ui-msg');
                  const mfaCode = changeEmailNewForm.find('[name="code"]');
                  const sendCode = changeEmailNewForm.find('.ui-sendcode');
                  const submitButton = changeEmailNewModal.find('.ui-change-email-new button[type="submit"]');
  
                  changeEmailNewForm.find('.ui-email').text(newEmail);
  
                  mfaCode.on('input', async (evt) => {
                    const input = $(evt.currentTarget);
                    const value = input.val();
                    if(value.length != 6) {
                      submitButton.prop('disabled', true);
                    } else {
                      submitButton.prop('disabled', false);
                    }
                  });

                  sendCode.on('click', async (evt) => {
                    const results = await sendChangeEmail(newEmail);
                    mfaCode.val('');
                    let responseText;
                    if(!results?.ok) {
                      const code = results?.code || 'general_error';
                      responseText = getString(`changeemail_new.${code}`).text;
                    } else {
                      responseText = getString(`changeemail_new.send_email`).text;
                    }
                    msg.text(responseText);
                  });
  
                  msg.text('');
                  mfaCode.val('');
                  changeEmailNewModal.removeAttr('hidden');
                  changeEmailNewModal.modal('show');

                  const results = await sendChangeEmail(newEmail);
                  if(!results?.ok) {
                    const code = results?.code || 'general_error';
                    const responseText = getString(`changeemail_new.${code}`).text;
                    msg.text(responseText);
                    return false;
                  }
  
                  await new Promise((resolve) => {
                    changeEmailNewForm.on('submit', async (evt) => {
                      evt.preventDefault();
                      msg.text('');
                      const results = await changeEmail(mfaCode.val());
                      if(!results?.ok) {
                        mfaCode.val('');
                        const code = results?.code || 'general_error';
                        const responseText = getString(`changeemail_new.${code}`).text;
                        msg.text(responseText);
                      } else {
                        emailVerified = true;
                        resolve();
                      }
                    });
                  });
                  changeEmailNewModal.modal('hide');
                  await new Promise((resolve) => {
                    changeEmailNewModal.on('hidden.bs.modal', () => {
                      resolve();
                    });
                  });
                }
              }

              // const editProfileResults = await profile(values, formState);
              // if(editProfileResults.code) {
              //   const { code } = editProfileResults;
              //   this.displayMessages({ message: [ context, code ] });
              //   return false;
              // }

              await navigateTo(this.info.successPath);
              return true;
            } finally {
              this.ready();
            }
          },
        },
      });
    }

    static autowireSelector() {
      return 'form.ui-editprofile-notification';
    }
  }

  /**
   * Autowire.
   */
  const notificationProfileAutowire = NotificationProfileComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    notificationProfileAutowire, NotificationProfileComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  notificationProfileComponentFactory,
};
