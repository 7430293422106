const { DateTime } = require('luxon');

function dateTimeServiceFactory(dyn) {
  const { log, tz: zone, locale, format } = dyn();

  log.info(`Date Time service started in timezone '${zone}'.`);

  function timestampToDateTime(timestamp) {
    return DateTime.fromMillis(timestamp, { zone });
  }

  function formatDateTime(datetime, format) {
    return datetime.toFormat(format);
  }

  return {
    timestampToDateTime,
    formatDateTime,
  };
}


module.exports = {
  dateTimeServiceFactory,
};
