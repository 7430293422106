/**
 * Imports.
 */
const { isObject, isString, isArray, isFunction, getTypeOf } = require('@theroyalwhee0/istype');
const { uint32Counter } = require('@theroyalwhee0/counters');
const { taker } = require('@theroyalwhee0/iter');
const { filterValue } = require('./fields');
const { getFieldType, getFieldValue, setFieldValue } = require('../../utilities/fields');
const { getNextFocusableElement } = require('../../utilities/dom');
const { regExpFactory } = require('../../utilities/regexp');

/**
 * Base Component factory.
 */
function baseComponentFactory(dyn) {
  const { log, controllers, validation, stringTable, subscribe } = dyn();
  const { lookups } = controllers;
  const { getString, findString } = stringTable;

  /**
   * Component ID.
   */
  const nextComponentId = taker(uint32Counter());

  /**
   * Base Component.
   */
  class BaseComponent {

    /**
     * Constructor.
     * @param {jqElement} ele Root component element.
     */
    constructor(ele) {
      this.ele = ele;
      this.id = nextComponentId();
      this.elements = {};
      this.fields = {};
      this.info = {};
      this.state = {};
      this.features = {};
      this.dirty = {};
      this.onValid = [];
      this.onInvalid = [];
      this.cfg = {
        ready: true, // Support ready/waiting/busy on this component.
      };
    }

    /**
     * Mounting.
     */
    async onMount() {
      throw new Error('Please override onMount().');
    }

    unmount() {
      // NOTE: Unmount must be sync.
      this.state = {};
    }

    async onConfig({ config }) {
      // Nothing to do here.
    }

    callOnValid(...args) {
      for(let idx = 0; idx < this.onValid.length; idx++) {
        this.onValid[idx](...args);
      }
    }

    callOnInvalid(...args) {
      for(let idx = 0; idx < this.onInvalid.length; idx++) {
        this.onInvalid[idx](...args);
      }
    }

    async mount() {
      this.state = {};
      this.ele.addClass('ui-attached');
      this.ele.attr('data-ui-id', this.id);

      const config = (key, value) => {
        this.cfg[key] = value;
      };

      await this.onConfig({ config });

      this.busy();

      function getFeatures(root) {
        const features = (root.attr('data-features') || '').split(',').reduce((features, item) => {
          if(item) {
            features[item] = true;
          }
          return features;
        }, {});
        return features;
      }

      this.features = getFeatures(this.ele);

      function attachPart(selector, root) {
        if(selector === ':self') {
          return root;
        } else if(isString(selector)) {
          return $(selector, root);
        } else if(isArray(selector)) {
          let ele = $();
          for(let idx = 0; idx < selector.length; idx++) {
            ele = ele.add(attachPart(selector[idx], root));
          }
          return ele;
        } else {
          throw new Error(`Unrecognized addElement selector "${selector}" (${getTypeOf(selector)}).`);
        }
      }

      const attach = (elements) => {
        for(let key in elements) {
          this.elements[key] = attachPart(elements[key], this.ele);
        }
      };

      const prop = (props) => {
        for(let key in props) {
          const ele = this.elements[key];
          for(let name in props[key]) {
            ele.prop(name, props[key][name]);
          }
        }
      };

      const re_many = /^\./;

      const fields = (fields) => {
        for(let key in fields) {
          let many = false;
          let parts = false;
          const value = fields[key];
          if(re_many.test(key)) {
            // If starts with '.' then this is multiple items...
            many = true;
            key = key.replace(re_many, '');
          } else if(this.elements[key]?.length > 1) {
            // If there are multiple elements matching then it is a multipart item...
            parts = true;
          }
          let fieldName = key;
          const elements = this.elements[key];
          if(!elements?.length) {
            console.warn(`No elements found for key "${key}".`);
          }
          if(many) {
            for(let idx = 0; idx < elements.length; idx++) {
              const ele = $(elements.get(idx));
              fieldName = ele.attr('name');
              let validate;
              if(value === true) {
                validate = validation();
              } else if(isObject(value) && value.validation === true) {
                validate = value;
              } else {
                throw new Error(`Unsupported field data "${value}".`);
              }
              const { rules } = validate.sync(ele);
              this.fields[fieldName] = { ele, rules };
            }
          } else if(parts) {
            const ele = $(elements);
            let validate;
            if(value === true) {
              validate = validation();
            } else if(isObject(value) && value.validation === true) {
              validate = value;
            } else {
              throw new Error(`Unsupported field data "${value}".`);
            }
            const { rules } = validate.sync(ele);
            this.fields[fieldName] = { ele, rules };
          } else {
            const ele = $(elements.get(0));
            let validate;
            if(value === true) {
              validate = validation();
            } else if(isObject(value) && value.validation === true) {
              validate = value;
            } else {
              throw new Error(`Unsupported field data "${value}".`);
            }
            const { rules } = validate.sync(ele);
            this.fields[fieldName] = { ele, rules };
          }
        }
      };

      const pruneFields = () => {
        for(let key in this.fields) {
          const ele = this.fields[key].ele;
          const exists = ele.is(':visible');
          if(!exists) {
            delete this.fields[key];
          }
        }
      };

      const attr = (attrs) => {
        for(let key in attrs) {
          const ele = this.elements[key];
          for(let name in attrs[key]) {
            ele.attr(name, attrs[key][name]);
          }
        }
      };

      const onEvent = (events) => {
        for(let key in events) {
          let ele;
          if(key === ':self') {
            ele = this.ele;
          } else if(this.elements[key]) {
            ele = this.elements[key];
          } else {
            ele = this.ele.find(key);
          }
          const item = events[key];
          for(let event in item) {
            const fn = item[event].bind(this);
            ele.on(event, fn);
          }
        }
      };

      function getInfoPath(key, root, infopath) {
        let ele = root;
        let array = false;
        if(infopath[infopath.length - 1] === '[]') {
          array = true;
          infopath = infopath.slice(0, infopath.length - 1);
        }
        for(let idx = 0; idx < infopath.length; idx++) {
          const part = infopath[idx];
          const isLast = idx + 1 === infopath.length;
          if(part === ':self') {
            ele = root;
          } else if(part === ':document') {
            ele = document;
          } else if(isLast) {
            // If part like '[data-value]'...
            const matchAttr = /^\[([a-z\-]+)\]$/i.exec(part);
            if(matchAttr && matchAttr.length === 2) {
              if(array) {
                // Then select each of those values.
                const values = [];
                for(let idx = 0; idx < ele.length; idx++) {
                  const item = $(ele.get(idx));
                  values.push(item.attr(matchAttr[1]));
                }
                return values;
              } else {
                // Then select that value.
                return ele.attr(matchAttr[1]);
              }
            }
            // If part like '[data-value="hello"], supports *=, ~=, $=
            const matchAttrEq = /\[[a-z\-]+[*$~]?=["']?[^\]"']+["']?\]$/i.exec(part);
            if(matchAttrEq) {
              if(array) {
                // Return each of those boolean selector matches.
                const values = [];
                for(let idx = 0; idx < ele.length; idx++) {
                  const item = $(ele.get(idx));
                  values.push(item.is(part));
                }
                return values;
              } else {
                // Return boolean selector match.
                return ele.is(part);
              }
            }

            throw new Error(`Unsupported last infopath part "${part}" for "${key}"`);
          } else if(typeof part === 'string') {
            ele = root.find(part);
          } else {
            throw new Error(`Unsupported infopath part "${part}" for "${key}"`);
          }
        }
      }

      const info = (infoItems, processItems) => {
        for(let key in infoItems) {
          const item = infoItems[key];
          if(typeof item === 'function') {
            this.info[key] = item();
          } else {
            this.info[key] = getInfoPath(key, this.ele, item);
          }
        }
        if(processItems) {
          for(let key in processItems) {
            const item = processItems[key];
            if(typeof item === 'function') {
              if(this.info[key] !== undefined) {
                this.info[key] = item(this.info[key]);
              }
            } else {
              throw new Error(`Expected "${item}" to be a function.`);
            }
          }
        }
      };

      const onState = (events) => {
        const unsubscribes = [];
        for(let key in events) {
          const item = events[key];
          const unsubscribe = subscribe(item.selector, item.action, !!item.initial);
          unsubscribes.push(unsubscribe);
        }
        return unsubscribes;
      };

      const autotab = () => {
        for(let name in this.fields) {
          const field = this.fields[name].ele;
          for(let idx = 0; idx < field.length; idx++) {
            const ele = $(field[idx]);
            const autoTab = ele.attr('data-autotab') === 'true';
            const maxLen = Number(ele.attr('maxlength'));
            if(autoTab && !isNaN(maxLen)) {
              ele.on('input', (evt) => {
                const target = $(evt.target);
                const value = target.val();
                if(value.length >= maxLen) {
                  const nextEle = getNextFocusableElement(target);
                  nextEle.focus();
                }
              });
            }
          }
        }
      };

      const validateOnSubmit = async ({ context }) => {
        this.clearMessages();
        const validate = await this.validate();
        const { valid, values, meta, issues, messages } = validate;
        if(!valid) {
          this.callOnInvalid({ values, meta, issues, messages });
          this.displayMessages({ context, issues, messages, showDirty: true });
        } else {
          this.callOnValid({ values });
        }
        return validate;
      };

      const validateOnRequest = async ({ context, showDirty }) => {
        showDirty = showDirty === undefined ? true : showDirty;
        this.clearMessages();
        let { valid, values, meta, issues } = await this.validate();
        if(!valid) {
          this.callOnInvalid({ values, meta, issues });
          this.displayMessages({ context, issues, showDirty, noFocus: true });
        } else {
          this.callOnValid({ values });
        }
        return { valid, values, meta, issues };
      };

      const validateOnChange = ({ context }) => {
        for(let key in this.fields) {
          const { ele, rules } = this.fields[key];
          const onChange = async (evt) => {
            this.dirty[key] = true;
            this.clearMessages();
            let { valid, values, meta, issues, messages } = await this.validate();
            if(!valid) {
              this.callOnInvalid({ ele, key, values, meta, issues });
              this.displayMessages({ context, issues, messages, noFocus: true, showDirty: false });
            } else {
              this.callOnValid({ ele, key, values });
            }
          };
          ele.off('change.validate');
          ele.on('change.validate', onChange);
        }
      };

      function isTrue(value) {
        return !!(
          (value === 1) ||
          (value === true) ||
          (value === '1') ||
          (value === 'true')
        );
      }

      info({
        onFieldValidate: ['[data-on-field-validate]'],
        triggerChangeOnPopulate: () => isTrue(this.ele.attr('data-change-on-populate')),
      });

      const onValid = (fn) => {
        this.onValid.push(fn);
      };
      const onInvalid = (fn) => {
        this.onInvalid.push(fn);
      };
      const onReady = await this.onMount({
        validation, config, attach, prop, attr, fields, pruneFields,
        info, onEvent, onState, autotab, validateOnChange,
        validateOnRequest, validateOnSubmit, onValid, onInvalid,
      });

      for(let key in this.fields) {
        const field = this.fields[key];
        // Limit characters enterable into fields.
        if(field.rules.chars) {
          const re = regExpFactory(field.rules.chars);
          field.ele.on('keydown', (evt) => {
            if(evt?.key?.length === 1) {
              if(!(evt.ctrlKey || evt.altKey || evt.metaKey)) {
                if(!re.test(evt.key)) {
                  evt.preventDefault();
                }
              }
            }
          });
        }
        if(field.rules.onChange) {
          const { ele, rules } = field;
          const { onChange } = rules;
          field.ele.on('input', (evt) => {
            const [value] = getFieldValue(ele);
            const result = onChange({ key, ele, evt, rules, value });
            if(result) {
              if('value' in result) {
                setFieldValue(ele, result.value);
              }
            }
          });
          if(this.info.triggerChangeOnPopulate) {
            field.ele.trigger('input');
          }
        }
      }

      defaultAttr(this.elements.form, 'action', '#');
      defaultAttr(this.elements.form, 'method', 'post');
      defaultAttr(this.elements.form, 'novalidate', true);
      this.ready();
      if(isFunction(onReady)) {
        await onReady();
      }
    }

    /**
     * Form & Fields.
     */

    setFormData(data, clear = false) {
      for(let name in this.fields) {
        const { ele: field, rules } = this.fields[name];
        let item;
        if(name in data) {
          item = data[name];
        } else if(clear) {
          item = '';
        } else {
          log.trace(`Field '${name}' has no data. Skipping.`);
          continue;
        }
        if(field.length === 0) {
          log.warn(`Field '${name}' not found. Skipping.`);
          continue;
        } else if(field.length === 1) {
          setFieldValue(field, item);
        } else {
          if(rules.joiner) {
            const split = item.split(rules.joiner);
            setFieldValue(field, split);
          } else {
            throw new Error(`No rules given to split "${name}" (${item}).`);
          }
        }
      }
    }

    getFormData() {
      const data = {
        values: {},
        meta: {},
      };
      for(let name in this.fields) {
        const { ele: field } = this.fields[name];
        if(field.length > 1) {
          const list = new Array(field.length);
          for(let idx = 0; idx < field.length; idx++) {
            const ele = $(field.get(idx));
            const fieldName = ele.attr('name');
            const match = /\.(\d+)$/.exec(fieldName);
            const [ value, meta ] = getFieldValue(ele);
            let position;
            if(match) {
              position = Number(match[1]);
            } else {
              position = 0;
              data.meta[name] = meta;
            }
            list[position] = value;
          }
          data.values[name] = list;
        } else if(field.length === 1) {
          const [ value, meta ] = getFieldValue(field);
          data.values[name] = value;
          data.meta[name] = meta;
        } else {
          data.values[name] = '';
          data.meta[name] = { missing: true };
        }
      }
      return data;
    }

    getFieldType(field) {
      return getFieldType(field);
    }

    getFieldValue(field) {
      return getFieldValue(field);
    }

    busy() {
      if(!this.cfg.ready) {
        return;
      }
      this.ele.removeClass('ui-ready').addClass('ui-waiting');
      this.elements?.disable?.prop('disabled', true).addClass('disabled');
    }

    ready() {
      if(!this.cfg.ready) {
        return;
      }
      this.ele.removeClass('ui-waiting').addClass('ui-ready');
      this.elements?.disable?.prop('disabled', false).removeClass('disabled');
    }

    clearMessages() {
      if(this.elements?.messages) {
        this.elements.messages.text('');
        this.ele.find('.alert').addBack('.alert')
          .removeClass('alert')
          .removeClass('alert-primary')
          .removeClass('alert-secondary')
          .removeClass('alert-success')
          .removeClass('alert-danger')
          .removeClass('alert-warning')
          .removeClass('alert-info')
          .removeClass('ui-msg-success')
          .removeClass('ui-msg-error')
        ;
      }
      return this;
    }

    findOneOf(finders) {
      for(let idx = 0; idx < finders.length; idx++) {
        const finder = finders[idx];
        const ele = finder(this.ele);
        if(ele && ele.length) {
          return ele;
        }
      }
    }

    attachMessage({ key, type, text }) {
      // Get message element.
      const msg = this.findOneOf([
        (_) => key && _.find(`.ui-msg-${key}`).not('.ui-template-row *'),
        (_) => key && _.find(`.ui-msg-${key}_${key.match(/_(\d)+$/)}`).not('.ui-template-row *'),
        (_) => _.find(`.ui-msg-primary`).not('.ui-template-row *').first(),
        (_) => _.find(`.ui-msg`).not('.ui-template-row *').first(),
      ]);

      // Get parent element.
      const parent = this.findOneOf([
        (_) => key && _.find(`.ui-field-${key}`).not('.ui-template-row *'),
        (_) => key && this.elements[key]?.closest('.ui-field').not('.ui-template-row *'),
        (_) => key && this.elements[key]?.closest('.form-group').not('.ui-template-row *'),
        (_) => key && msg.closest('.ui-field').not('.ui-template-row *'),
        (_) => key && msg.closest('.form-group').not('.ui-template-row *'),
        (_) => this.ele,
      ]);
      // Flag message type.
      const className = `ui-msg-${type}`;
      const classTarget = parent && parent.length ? parent : msg;

      classTarget
        .removeClass('ui-msg-success')
        .removeClass('ui-msg-error')
        .removeClass('ui-msg-warning')
        .addClass(className)
        .addClass('alert');
      // Display message.
      if(msg && msg.length) {
        msg.text(text);
        if(msg.is(':hidden')) {
          log.warn({ ele: msg, text }, `Message element is hidden.`);
        }
      } else {
        log.warn(`Can't find message area for message "${text}"`);
      }
      return { ele: msg, parent };
    }

    displayMessages({ context, issues, messages, message, data, noFocus, showDirty = true } = {}) {
      messages = toArray(messages);
      const focus = new Set();
      const scroll = new Set();

      if(issues) {
        for(let key in issues) {
          focus.add(key);
          const issue = issues[key];
          const { code, data: issueData } = issue;
          const msgLookup = { message: `${context}.${key}.${code}` };
          const messageData = Object.assign({}, data, issueData);
          const hasMsg = findString(msgLookup);
          let msg;
          if(!hasMsg.ok) {
            const groupKey = key.replace(/_\d+$/, '');
            const msgGroupLookup = { message: `${context}.${groupKey}.${code}` };
            const hasMsgGroup = findString(msgGroupLookup);
            if(!hasMsgGroup.ok) {
              msg = getString(msgGroupLookup, messageData);
            }
          } else {
            msg = getString(msgLookup, messageData);
          }
          const { type, text } = msg;
          if(showDirty === false && this.dirty[key] !== true) {
            continue;
          }
          this.attachMessage({ key, type, text });
        }
      }

      if(message) {
        messages = [
          {
            message: message.join('.'),
          },
        ];
      }

      if(messages) {
        for(let idx = 0; idx < messages.length; idx++) {
          const item = messages[idx];
          const { key, message } = item;
          const msg = getString({ message }, data);
          const { type, text } = msg;
          const { ele } = this.attachMessage({ key, type, text });
          scroll.add(ele);
        }
      }

      if(focus.size && !noFocus) {
        const ele = this.findFirstFocus({ focus });
        if(ele?.length) {
          ele.first().scrollAndFocus();
        } else {
          log.debug(`Unable to find focus target "${focus}".`);
        }
      } else if(scroll.size && !noFocus) {
        const ele = this.findFirstFocus({ scroll });
        if(ele?.length) {
          ele.first().scrollAndFocus();
        } else {
          log.debug(`Unable to find scroll target "${scroll}".`);
        }
      }

      return this;
    }

    findFirstFocus({ focus, scroll }) {
      let candidates = [];
      if(focus && scroll) {
        throw new Error('"focus" and "scroll" are mutually exclusive.');
      } else if(focus) {
        for(let name of focus) {
          const ele = this.elements[name] || this.fields[name]?.ele;
          if(ele && ele.length && ele.is(':visible')) {
            const offset = ele.offset();
            candidates.push({ name, ele, offset });
          }
        }
      } else if(scroll) {
        for(let ele of scroll) {
          if(ele && ele.length && ele.is(':visible')) {
            const offset = ele.offset();
            candidates.push({ name: '', ele, offset });
          }
        }
      }
      if(candidates.length) {
        const sorted = candidates.slice();
        sorted.sort((left, right) => {
          const leftOffset = left.offset;
          const rightOffset = right.offset;
          // Sort by Top, then Left, then Name.
          if(leftOffset.top < rightOffset.top) {
            return -1;
          } else if(leftOffset.top > rightOffset.top) {
            return 1;
          } else if(leftOffset.left < rightOffset.left) {
            return -1;
          } else if(leftOffset.left > rightOffset.left) {
            return 1;
          } else {
            const compare = (left.name + '').localeCompare(right.name + '');
            return compare < 0 ? -1 : compare > 0 ? 1 : 0;
          }
        });
        return sorted[0].ele;
      } else {
        return $([]);
      }
    }

    async validate(data) {
      data = data || this.getFormData();
      const { values, meta } = data;
      let issues = {};
      let messages = [];

      // Round 1 filtering.
      // Output may be primitives or containers.
      for(let key in this.fields) {
        const { rules } = this.fields[key];
        const original = values[key];
        const metadata = meta[key] = meta[key] || {};
        const value = filterValue(original, rules);
        if(value !== original) {
          metadata.original = original;
          values[key] = value;
        }
      }

      // Round 1 checks.
      // Input may be primitives or containers.
      for(let key in this.fields) {
        let issue = issues[key] || false;
        const { rules } = this.fields[key];
        const value = values[key];
        if(!issue && rules.required === true) {
          if(isEmpty(value)) {
            issue = { key, code: 'required' };
          }
        }
        if(!issue && rules.numeric === true) {
          if(isNaN(value)) {
            issue = { key, code: 'not_numeric' };
          }
        }
        if(issue) {
          issues[key] = issue;
        }
      }

      // Route 2 filtering.
      // Output is primitives only.
      for(let key in this.fields) {
        const { rules } = this.fields[key];
        const original = values[key];
        const metadata = meta[key] = meta[key] || {};
        if(isArray(original)) {
          const isEmpty = original.every((_) => {
            return _ === '' || _ === null || _ === undefined;
          });
          if(isEmpty) {
            values[key] = '';
            break;
          }
          const joiner = rules?.joiner || '';
          const joined = original.join(joiner);
          const value = filterValue(joined, rules);
          if(value !== original) {
            metadata.joinerSize = (original.length - 1) * (joiner.length || '');
            metadata.original = original;
            values[key] = value;
          }
        }
      }

      // Round 2 checks.
      // Input is primitives only.
      for(let key in this.fields) {
        let issue = issues[key] || false;
        const { rules } = this.fields[key];
        const value = values[key];
        const metadata = meta[key] = meta[key] || {};
        if(!issue && rules.optional === true) {
          // NOTE: Optional is not the reverse of required.
          // Optional allows a ruleset to be short-circuited.
          // If a optional value is empty then any furter rules are skipped.
          // This allows things like patterns or minlength to apply to a non-required field.
          if(isEmpty(value)) {
            // Skip additional checks.
            continue;
          }
        }
        const joinerSize = metadata.joinerSize || 0;
        if(!issue && 'minlen' in rules && rules.minlen + joinerSize > value.length) {
          issue = { key, code: 'too_short' };
        }
        if(!issue && 'maxlen' in rules && rules.maxlen + joinerSize < value.length) {
          issue = { key, code: 'too_long' };
        }
        if(!issue && 'pattern' in rules && !rules.pattern.test(value)) {
          issue = { key, code: 'mismatched' };
        }
        if(!issue && 'equals' in rules) {
          const compareKey = rules.equals;
          const compareTo = values[compareKey];
          if(value !== compareTo) {
            issue = { key, code: 'not_equal' };
          }
        }
        if(!issue && 'equalsInsensitive' in rules) {
          const compareKey = rules.equalsInsensitive;
          const compareTo = values[compareKey].toLowerCase();
          if(value.toLowerCase() !== compareTo) {
            issue = { key, code: 'not_equal' };
          }
        }
        if(!issue && 'lt' in rules) {
          const compareTo = Number(rules.lt);
          if(value >= compareTo) {
            issue = { key, code: 'too_high' };
          }
        }
        if(!issue && 'lte' in rules) {
          const compareTo = Number(rules.lte);
          if(value > compareTo) {
            issue = { key, code: 'too_high' };
          }
        }
        if(!issue && 'gt' in rules) {
          const compareTo = Number(rules.gt);
          if(value <= compareTo) {
            issue = { key, code: 'too_low' };
          }
        }
        if(!issue && 'gte' in rules) {
          const compareTo = Number(rules.gte);
          if(value < compareTo) {
            issue = { key, code: 'too_low' };
          }
        }

        // Lookups.
        if(!issue && 'lookup' in rules) {
          const lookupResults = await lookups(value, rules.lookup, rules.lookupParams);
          if(lookupResults.found === false) {
            issue = { key, code: 'not_in_lookup' };
          }
        }

        // If issues, add issue.
        if(issue) {
          issues[key] = issue;
        }
      }

      if(this.info.onFieldValidate) {
        const onFieldValidate = isFunction(this.info.onFieldValidate) ? this.info.onFieldValidate : global[this.info.onFieldValidate];
        let idx = 0;
        for(let key in this.fields) {
          let issue = issues[key] || false;
          const { rules } = this.fields[key];
          const value = values[key];
          const metadata = meta[key] = meta[key] || {};
          const dirty = !!this.dirty[key];
          const results = await onFieldValidate({ idx, key, value, issue, dirty, rules, metadata, allValues: values });
          if(results) {
            if(results.issue) {
              issues[key] = results.issue;
            }
            if(results.messages) {
              messages = messages.concat(results.messages);
            }
          }
          idx++;
        }
      }

      const serializedValues = {};
      for(let key in this.fields) {
        const { rules: { serialize } } = this.fields[key];
        let value = values[key];
        if(serialize.remove) {
          value = value.replace(serialize.remove, '');
        }
        serializedValues[key] = value;
      }

      // Results.
      const valid = Object.keys(issues).length === 0 && Object.keys(messages).length === 0;
      return {
        valid,
        issues,
        values,
        serialized: serializedValues,
        meta,
        messages,
      };
    }

    getRoles() {
      const classes = $('body').attr('class').split(' ');
      const roles = classes.filter((className) => className.startsWith('ui-role-'));
      return roles;
    }

    hasRole(role) {
      const roles = this.getRoles();
      return roles.includes(`ui-role-${role}`);
    }

    // Populate.
    populate(data, clear = false) {
      this.setFormData(data, clear);
    }

    // Autowire.
    static autowireSelector() {
      return null;
    }

    static autowireFactory() {
      const selector = this.autowireSelector();
      if(!(isString(selector) && selector)) {
        throw new Error(`autowireSelector "${selector}" is invalid`);
      }
      const ThisComponent = this;
      return async function autowire() {
        const items = $(selector);
        const elements = [];
        for(let idx = 0; idx < items.length; idx++) {
          const ele = $(items[idx]);
          const instance = new ThisComponent(ele);
          await instance.mount();
          elements.push(instance);
        }
        return elements;
      };
    }
  }

  return BaseComponent;
}

function isEmptyPrimative(value) {
  return !!(
    (value === '') ||
    (value === undefined) ||
    (value === null) ||
    (value === 0) ||
    (value === false)
  );
}

function isEmpty(value) {
  return !!(
    isEmptyPrimative(value) || (
      isArray(value) &&
      (
        (value.length === 0) ||
        (value.every(isEmptyPrimative))
      )
    )
  );
}

function toArray(value, undefinedEmpty = true) {
  if(Array.isArray(value)) {
    return value;
  }
  const ary = [];
  if(undefinedEmpty === false || value !== undefined) {
    ary.push(value);
  }
  return ary;
}

/**
 * Default attribute if not set.
 * @param {jqElement} ele The element to set.
 * @param {string} name The attribute name.
 * @param {string} value The attribute value.
 * @returns {boolean} True if set, false if not.
 */
function defaultAttr(ele, name, value) {
  if(ele && ele.length) {
    const current = ele.attr(name);
    if(!(typeof current === 'string' && current)) {
      if(value === true) {
        value = name;
      }
      ele.attr(name, '' + value);
      return true;
    }
  }
  return false;
}

/**
 * Exports.
 */
module.exports = {
  baseComponentFactory,
};
