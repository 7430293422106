/**
 * @imarcsgroup/client:src/components/resetpassword/index.js
 */

/**
 * ResetPassword component factory.
 */
function resetPasswordComponentFactory(dyn) {
  const { log, controllers, BaseComponent } = dyn();
  const { resetPassword, verifyToken, navigateTo } = controllers;

  const context = 'resetpassword';

  /**
   * ResetPassword Component.
   */
  class ResetPasswordComponent extends BaseComponent {
    async onMount({ validation, attach, fields, info, onEvent }) {
      log.trace(`Mounting reset-password component (#${this.id}).`);
      attach({
        form: ':self',
        password: 'input[name="password"]',
        password_confirm: 'input[name="password_confirm"]',
        disable: [ ':self', 'input, select, button' ],
        submit: 'button[type="submit"], input[type="submit"]',
        messages: '.ui-msg',
      });
      fields({
        password: validation().password().required(),
        password_confirm: validation().password().equals('password').required(),
      });
      info({
        successPath: ['[data-success]'],
      });

      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get('token');
      if(token) {
        const { data } = await verifyToken(token, 'reset');
        if(data.reset.status !== 'valid') {
          this.displayMessages({ message: [ context, 'password_reset_token_expired' ], noFocus: true });
        }
      } else {
        console.warn('No token provided.');
      }

      onEvent({
        form: {
          async submit(evt) {
            evt.preventDefault();
            try {
              this.busy();
              this.clearMessages();
              const { valid, values, meta, issues } = await this.validate();
              if(!valid) {
                this.displayMessages({ context, issues });
                return false;
              }
              const { password } = values;
              const resetPasswordResults = await resetPassword(token, password);
              if(resetPasswordResults.code) {
                const { code } = resetPasswordResults;
                this.displayMessages({ message: [ context, code ] });
                return false;
              }
              navigateTo(this.info.successPath);
              return true;
            } finally {
              this.ready();
            }
          },
        },
      });
    }

    static autowireSelector() {
      return 'form.ui-resetpassword';
    }
  }

  /**
   * Autowire.
   */
  const resetPasswordAutowire = ResetPasswordComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    resetPasswordAutowire, ResetPasswordComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  resetPasswordComponentFactory,
};
