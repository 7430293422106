/**
 * Imports.
 */
const { writeSessionCookie, readSessionCookie } = require('./cookies');
const { isOAuthExpired, isRefreshExpired } = require('./checks');
const { asyncInterval } = require('../../utilities/asyncinterval');

/**
 * Constants.
 */
const EXPIRES_WITHIN = 15 * 1000;
const ACTIVITY_WITHIN = 20 * 60 * 1000;
const TIMEOUT_URL = '/home/session_timeout/';

/**
 * Session service factory.
 */
function sessionServiceFactory(dyn) {
  const { log, dispatch, actions, subscribe, getState, controllers } = dyn();
  const { sessionSet, sessionLogOut } = actions;
  const { refreshSession } = controllers;
  const cookieSession = readSessionCookie();
  let userHasSession = false;
  if(cookieSession) {
    if(!isOAuthExpired(cookieSession, EXPIRES_WITHIN)) {
      dispatch(sessionSet(cookieSession));
      userHasSession = true;
    } else if(!isRefreshExpired(cookieSession, EXPIRES_WITHIN)) {
      dispatch(sessionSet(cookieSession));
      userHasSession = true;
    }
  }
  if(userHasSession) {
    log.info(`User appears to have a valid, unexpired session. Restoring it.`);
  }
  addEventListener('beforeunload', (evt) => {
    let session = getState((_) => _.session);
    writeSessionCookie(session);
  });
  const unsubscribe = subscribe((_) => _.session, async (session) => {
    writeSessionCookie(session);
  }, true);
  const stopInterval = asyncInterval(async () => {
    let session = getState((_) => _.session);
    const cookieSession = readSessionCookie();
    if(session?.tokens?.oauth || session?.tokens?.refresh) {
      if(!cookieSession) {
        log.info('Session cookie cleared. Logging out.');
        dispatch(sessionLogOut());
        return;
      } else if(cookieSession.ident !== session.ident) {
        log.info(`Session cookie modified. Reloading session.`);
        dispatch(sessionSet(cookieSession));
        return;
      }

      const now = Date.now();
      if(session.activity.touch + ACTIVITY_WITHIN < now) {
        log.info('Logging out due to inactivity.');
        dispatch(sessionLogOut({ url: TIMEOUT_URL }));
      }

      const oauthExpired = isOAuthExpired(session, EXPIRES_WITHIN);
      const refreshExpired = isRefreshExpired(session);
      if(oauthExpired && !refreshExpired) {
        log.info('OAuth token has expired. Refreshing tokens.');
        await refreshSession();
      } else if(oauthExpired || refreshExpired) {
        log.info('OAuth and/or Refresh tokens have expired. Logging out.');
        dispatch(sessionLogOut({ url: TIMEOUT_URL }));
      }
    } else if(cookieSession) {
      log.info(`Session cookie detected. Loading session.`);
      dispatch(sessionSet(cookieSession));
      return;
    }
  }, 787, true);
  function unload() {
    unsubscribe();
    stopInterval();
  }
  log.debug(`Session service ready.`);
  return unload;
}

/**
 * Exports.
 */
module.exports = {
  sessionServiceFactory,
};
