/**
 * @imarcsgroup/client:src/security.js
 */

/**
 * Imports.
 */
const windowLib = global.window || undefined;
const documentLib = global.document || undefined;

/**
 * Constants.
 */
const reHttps = /^https:/;

/**
 * Security service.
 */
function securityService({
  log, window = windowLib, document = documentLib,
}) {
  // Window opener.
  if(window.opener) {
    log.trace('Clearing window opener.');
    window.opener = null;
  }
  // Window name.
  if(window.name) {
    log.trace({ windowName: `${window.name}` }, 'Clearing window name.');
    window.name = '';
  }

  function deobfuscateString(str, key) {
    let obfuscated = '';
    for(let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i) ^ key.charCodeAt(i % key.length);
      obfuscated += String.fromCharCode(charCode);
    }
    return obfuscated;
  }

  // Domain safeguard.
  const safeDomain = process.env.DOMAIN_WHITELIST.some((domain) => {
    return window.location.hostname === deobfuscateString(domain, process.env.DOMAIN_WHITELIST_KEY);
  });
  if(!safeDomain) {
    $('body').hide();
    window.location.replace(deobfuscateString(process.env.DOMAIN_SAFEGUARD_URL, process.env.DOMAIN_SAFEGUARD_KEY));
    return false;
  }

  // // Secure Context, if supported.
  // if('isSecureContext' in window && window.isSecureContext !== true) {
  //   // REF: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/isSecureContext
  //   log.warn('Site should be running in a secure context.');
  // }
  // // HTTPS.
  // if(!reHttps.test(window.location.protocol)) {
  //   log.warn('Site should be running securely.');
  // }
  // Location hash.
  function checkHash() {
    if(window.location.hash && !/^#[a-zA-Z0-9_]+$/.test(window.location.hash)) {
      log.trace({ hash: `${window.location.hash}` }, 'Clearing complex window hash.');
      window.history.replaceState({}, document.title, '.');
    }
  }
  checkHash();
  window.addEventListener('hashchange', checkHash, false);
  // Done.
  log.debug('Security service started.');
  return true;
}

/**
 * Exports.
 */
module.exports = {
  securityService,
};
