/**
 * @imarcsgroup/client:src/components/unsubscribe/index.js
 */

/**
 * Unsubscribe component factory.
 */
function unsubscribeComponentFactory(dyn) {
  const { log, controllers, BaseComponent } = dyn();
  const { unsubscribe } = controllers;

  const context = 'unsubscribe';

  /**
   * Unsubscribe Component.
   */
  class UnsubscribeComponent extends BaseComponent {
    async onMount({ validation, attach, fields, info, onEvent }) {
      log.trace(`Mounting forgot-password component (#${this.id}).`);
      attach({
        form: ':self',
        disable: [ ':self', 'input, select, button' ],
        submit: 'button[type="submit"], input[type="submit"]',
        messages: '.ui-msg',
      });
      try {
        this.busy();
        this.clearMessages();
        const { valid, values, meta, issues } = await this.validate();
        if(!valid) {
          this.displayMessages({ context, issues });
          return false;
        }
        const urlParams = new URLSearchParams(window.location.search);
        const externalId = urlParams.get('externalid');
        const { status } = await unsubscribe(externalId);
        if(status !== 204) {
          this.displayMessages({ message: [ context, 'general_error' ] });
          return false;
        } else {
          this.displayMessages({ message: [ context, 'success' ] });
        }
        return true;
      } finally {
        this.ready();
      }
    }

    static autowireSelector() {
      return 'form.ui-unsubscribe';
    }
  }

  /**
   * Autowire.
   */
  const unsubscribeAutowire = UnsubscribeComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    unsubscribeAutowire, UnsubscribeComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  unsubscribeComponentFactory,
};
