const { createSelector } = require('@reduxjs/toolkit');

function subscribeFactory(dyn) {
  const { store } = dyn();
  return function subscribe(selector, callback, options = {}) {
    if(options === true || options === false) {
      options = { initial: options };
    }
    const { initial } = options;
    const stateSelector = createSelector(selector, (_) => _);
    let changed = false;
    let running;
    let last;
    let stop = false;
    async function subscriber() {
      if(stop) {
        return;
      }
      if(running) {
        changed = true;
        return;
      }
      do {
        running = true;
        const state = store.getState();
        const data = stateSelector(state);
        changed = false;
        if(last !== data) {
          last = data;
          running = callback(data);
          await running;
        }
      } while(changed);
      running = false;
    }
    if(initial) {
      subscriber();
    }
    const storeUnsubscribe = store.subscribe(subscriber);
    async function unsubscribe() {
      stop = true;
      storeUnsubscribe();
      if(running) {
        await running;
        running = false;
      }
    }
    return {
      unsubscribe,
    };
  };
}


module.exports = {
  subscribeFactory,
};
