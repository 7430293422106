/**
 * Imports.
 */
const { HTTP_UNAUTHORIZED, HTTP_FORBIDDEN } = require('../../utilities/httpcode');

/**
 * Header names.
 */
const rolesUpdatedHeader = 'x-roles-updated';

/**
 * Claims fetch factory.
 */
function claimsFetchFactory(dyn) {
  const { log, claimsFetchCore } = dyn();
  return async function claimsFetch({ method, path, query, body, self = 'auto', cache = false, cacheBuster = false }) {
    const { status, data, headers } = await claimsFetchCore({ method, path, query, body, self, cache, cacheBuster });
    return { status, data, headers };
  };
}

/**
 * Exports.
 */
module.exports = {
  claimsFetchFactory,
};
