/**
 * @imarcsgroup/client:src/components/nav/index.js
 */

/**
 * Nav component factory.
 */
function navComponentFactory(dyn) {
  const { log, BaseComponent } = dyn();

  /**
   * Body Component.
   */
  class NavComponent extends BaseComponent {
    onConfig({ config }) {
      config('ready', false);
    }

    onMount({ attach }) {
      log.trace(`Mounting nav component (#${this.id}).`);
      attach({
        items: '.nav-item',
        anchors: '.nav-item a[href]',
      });
      const href = document.location.pathname;
      this.elements.items.removeClass('active');
      for(let idx = 0; idx < this.elements.anchors.length; idx++) {
        const ele = $(this.elements.anchors.get(idx));
        if(ele.attr('href') === href) {
          ele.closest('.nav-item').addClass('active');
        }
      }
    }

    static autowireSelector() {
      // This should point to a Boostrap .navbar
      return '.ui-navbar';
    }
  }

  /**
   * Autowire.
   */
  const navAutowire = NavComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    navAutowire, NavComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  navComponentFactory,
};
