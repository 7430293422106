/**
 * UserData service factory.
 */
function userdataServiceFactory(dyn) {
  const { log, dispatch, actions, subscribe, getState, controllers } = dyn();
  const { userdataSet, userdataClear } = actions;
  const { getProfile } = controllers;

  const unsubscribe = subscribe((_) => _.session.roles, async (roles) => {
    if(roles?.includes('@access')) {
      try {
        const profile = await getProfile();
        dispatch(userdataSet(profile));
      } catch(ex) {
        if(ex.name === 'TypeError' && /NetworkError/.test(ex.message)) {
          // Ignore error.
        } else {
          throw ex;
        }
      }
    } else {
      dispatch(userdataClear());
    }
  }, true);

  log.debug(`User Data service ready.`);

  function unload() {
    unsubscribe();
  }

  return unload;
}

/**
 * Exports.
 */
module.exports = {
  userdataServiceFactory,
};
