/**
 * @imarcsgroup/client:src/strings/forgotpassword.js
 */

/**
 * Strings.
 */
const strings = {
  'forgotpassword.temporary_lockout': 'Your account has been locked due to invalid login attempts.',
};

/**
 * Exports.
 */
module.exports = strings;
