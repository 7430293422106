const { isObject } = require('@theroyalwhee0/istype');

function codeOrError(value) {
  if(isObject(value) && typeof value.code === 'string') {
    value.ok = false;
    return value;
  } else {
    value = { code: value, ok: false };
  }
  return value;
}

module.exports = {
  codeOrError,
};
