/**
 * @imarcsgroup/client:src/components/messages/index.js
 */

/**
 * Messages component factory.
 */
function messagesFactory(dyn) {
  const { log, BaseComponent, controllers } = dyn();
  const { getMessages, setMessages } = controllers;

  /**
   * Messages Component.
   */
  class MessagesComponent extends BaseComponent {
    onConfig({ config }) {
      config('ready', false);
    }

    async nextModal(key) {
      const selector = `#${key}`;
      return new Promise((resolve) => {
        let nav = false;
        $(`${selector} [data-nav]`).on('click', (ele) => {
          nav = $(ele.currentTarget).attr('data-nav');
          $(selector).modal('hide');
        });
        $(selector).on('hidden.bs.modal', async (ele) => {
          await setMessages([{ key }]);
          resolve(nav);
        });
        $(selector).modal();
      });
    }

    async onMount() {
      log.trace(`Mounting messages component (#${this.id}).`);
      const body = $('body');
      if(body.hasClass('ui-role-unread-messages')) {
        const messages = await getMessages();
        for(const message of messages) {
          const nav = await this.nextModal(message.key);
          if(nav) {
            window.location.href = nav;
            return;
          }
        }
      }
    }

    static autowireSelector() {
      return '.ui-messages';
    }
  }

  /**
   * Autowire.
   */
  const messagesAutowire = MessagesComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    messagesAutowire, MessagesComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  messagesFactory,
};
