/**
 * @imarcsgroup/client:src/components/claimssetoffsdownload/index.js
 */

/**
 * ClaimsSetoffsDownload component factory.
 */
function claimsSetoffsDownloadComponentFactory(dyn) {
  const { log, controllers, BaseComponent } = dyn();
  const { claimsSetoffsDownload } = controllers;
  
  const context = 'claimssetoffsdownload';

  /**
   * ClaimsSetoffsDownload Component.
   */
  class ClaimsSetoffsDownloadComponent extends BaseComponent {
    async onMount({ validation, attach, fields, info, onEvent, validateOnChange }) {
      log.trace(`Mounting claims-setoffs-download component (#${this.id}).`);
      attach({
        form: ':self',
        messages: '.ui-msg',
      });
      const queryParams = new URL(document.location).searchParams;
      const token = queryParams.get('token');
      try {
        this.busy();
        this.clearMessages();
        const results = await claimsSetoffsDownload(token);
        if(!results?.ok) {
          const code = results?.code || 'error';
          this.displayMessages({ message: [ context, code ] });
          return false;
        }
        return true;
      } finally {
        this.ready();
      }
    }

    static autowireSelector() {
      return '.ui-claimssetoffsdownload';
    }
  }

  /**
   * Autowire.
   */
  const claimsSetoffsDownloadAutowire = ClaimsSetoffsDownloadComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    claimsSetoffsDownloadAutowire, ClaimsSetoffsDownloadComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  claimsSetoffsDownloadComponentFactory,
};
