/**
 * @imarcsgroup/client:src/errors.js
 */

/**
 * Imports.
 */
const windowLib = window;
const consoleLib = console;

/**
 * Error handler service.
 */
function errorHandler({ log, window = windowLib, console = consoleLib }) {
  window.addEventListener('error', (evt) => {
    evt.preventDefault();
    let err = evt.error;
    if(!(err instanceof Error)) {
      err = {
        message: `error was not an Error: ${err} (${typeof err}).`,
        value: err,
      };
    }
    log.error(err, `Unhandled exception: ${err.message}`);
    const causes = [err];
    while(err && err.parent) {
      err = err.parent;
      if(causes.includes(err)) {
        log.warn({ causes }, 'Circular reference in cause of unhandled exception');
        break;
      } else {
        causes.push(err);
        log.error(err, `Cause of unhandled exception: ${err.message}`);
      }
    }
  });
  window.addEventListener('unhandledrejection', (evt) => {
    try {
      evt.preventDefault();
      const err = evt.reason || (evt.detail && evt.detail.reason);
      log.error({ err }, `Unhandled rejection: ${err.message}`);
      const causes = [err];
      while(err && err.parent) {
        err = err.parent;
        if(causes.includes(err)) {
          log.warn({ causes }, 'Circular reference in cause of unhandled rejection');
          break;
        } else {
          causes.push(err);
          log.error({ err }, `Cause of unhandled rejection: ${err.message}`);
        }
      }
    } catch(ex) {
      console.error('An error occurred in the "unhandledrejection" handler.', ex);
    }
  });
  log.trace('Error handling service started.');
}

/**
 * Exports.
 */
module.exports = {
  errorHandler,
};
