/**
 * @imarcsgroup/client:src/components/login/index.js
 */

const { isFunction } = require('@theroyalwhee0/istype');
const { verifyLoginAccess } = require('./verify');
const { serviceNotification } = require('./svcnotify');

/**
 * LogIn component factory.
 */
function logInComponentFactory(dyn) {
  const { log, controllers, BaseComponent, getState, stringTable } = dyn();
  const { login, navigateTo, svcNotify, setConsents, sendVerifyLogin, verifyLogin } = controllers;
  const { getString } = stringTable;

  /** Message context. */
  const context = 'login';

  function getValueByRole(data) {
    let value;
    if(/^{/.test(data)) {
      const roles = getState((_) => _.session.roles);
      const rules = JSON.parse(data);

      let ruleMatch = false;
      for(const rule of Object.keys(rules)) {
        const requiredRoles = rule.split(',');
        ruleMatch = requiredRoles.every((role) => roles.includes(role));
        if(ruleMatch) {
          value = rules[rule];
          break;
        }
      }
    }
    return value;
  }

  /**
   * LogIn Component.
   */
  class LogInComponent extends BaseComponent {
    onMount({ validation, attach, fields, prop, info, onEvent, onState, validateOnChange }) {
      log.trace(`Mounting log-in component (#${this.id}).`);
      attach({
        form: ':self',
        email: 'input[name="email"]',
        password: 'input[name="password"]',
        disable: [ ':self', 'input, select, button' ],
        submit: 'button[type="submit"], input[type="submit"]',
        messages: '.ui-msg',
      });
      info({
        mfa: ['[data-mfa]'],
        successPath: ['[data-success]'],
        postValidate: ['[data-on-postvalidate]'],
        svcNotify: ['[data-svcnotify]'],
        svcAlert: ['[data-svcalert]'],
      });

      fields({
        email: validation().email().required(),
        password: validation().password().required(),
      });
      prop({
        form: {
          autocomplete: false,
        },
      });
      validateOnChange({ context });
      onEvent({
        form: {
          async submit(evt) {
            evt.preventDefault();
            let ready = true;
            try {
              this.busy();
              this.clearMessages();
              const { valid, values, meta, issues } = await this.validate();
              if(!valid) {
                this.displayMessages({ context, issues });
                return false;
              }
              if(this.info.postValidate) {
                const postValidateName = this.info.postValidate;
                const onPostValidate = window[postValidateName];
                if(isFunction(onPostValidate)) {
                  try {
                    const result = await onPostValidate();
                    if(!result?.ok) {
                      return;
                    }
                  } catch(ex) {
                    console.error(ex);
                  }
                } else {
                  log.warn(`No function named "${postValidateName}".`);
                  return;
                }
              }
              let results = await login(values.email, values.password);
              if(!results?.ok) {
                const code = results?.code || 'error';
                this.displayMessages({ message: [ context, code ] });
                return false;
              }

              // 2FA
              if(results.mfaRequired) {
                results = await verifyLoginAccess({
                  log,
                  login: this,
                  selector: this.info.mfa,
                  onState,
                  getString,
                  sendVerifyLogin,
                  verifyLogin,
                  loginData: results,
                });
              } else {
                results = await verifyLogin(results);
              }

              // Service Notifications.
              let location = false;
              if(results?.notifications && results?.notifications?.includes('account')) {
                location = await serviceNotification({
                  log,
                  login: this,
                  selector: this.info.svcNotify,
                  svcNotify,
                  setConsents,
                  getValueByRole,
                });

                if(location) {
                  ready = false;

                  const svcAlert = this.info.svcAlert;
                  if(svcAlert) {
                    const alert = $(svcAlert);
                    const alertForm = alert.find('form');
                    alert.removeAttr('hidden');
                    alert.modal('show');
              
                    await new Promise((resolve) => {
                      // On Submit...
                      alertForm.on('submit', async (evt) => {
                        evt.preventDefault();
                        resolve();
                      });
                    });
                    // Everything is resolved...
                    alert.modal('hide');
                    await new Promise((resolve) => {
                      alert.on('hidden.bs.modal', () => {
                        resolve();
                      });
                    });
                    alert.attr('hidden', 'hidden');
                  }
                }
              } else {
                const append = getValueByRole(this.info.appendPath) || '';
                location = `${getValueByRole(this.info.successPath)}${append}`;
              }
              await navigateTo(location);
              return true;
            } finally {
              if(ready) {
                this.ready();
              }
            }
          },
        },
      });
    }

    static autowireSelector() {
      return 'form.ui-login';
    }
  }

  /**
 * Autowire.
 */
  const logInAutowire = LogInComponent.autowireFactory();

  /**
 * Built.
 */
  return {
    logInAutowire, LogInComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  logInComponentFactory,
};
