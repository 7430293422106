const CLAIM_ACTIVE_STATE = {
  MC_IN_PROGRESS: 'MC_IN_PROGRESS',
  MC_CLAIM_CENTER_HOLD: 'MC_CLAIM_CENTER_HOLD',
  MC_PAYMENT_SUBMITTED_ZELLE: 'MC_PAYMENT_SUBMITTED_ZELLE',
  MC_PAYMENT_SUBMITTED_ZELLE_ACTIVE: 'MC_PAYMENT_SUBMITTED_ZELLE_ACTIVE',
  MC_PAYMENT_SUBMITTED_CHECK: 'MC_PAYMENT_SUBMITTED_CHECK',
  MC_PAYMENT_SENT: 'MC_PAYMENT_SENT',
  MC_PAYMENT_RESISSUED: 'MC_PAYMENT_RESISSUED',
  MC_PAYMENT_REISSUED_ACTIVE: 'MC_PAYMENT_REISSUED_ACTIVE',
  MC_PAYMENT_SETOFFS_ZELLE: 'MC_PAYMENT_SETOFFS_ZELLE',
  MC_PAYMENT_SETOFFS_CHECK: 'MC_PAYMENT_SETOFFS_CHECK',
  MC_PAYMENT_REVIEW: 'MC_PAYMENT_REVIEW',
  MC_PAYMENT_REVIEW_ACTIVE: 'MC_PAYMENT_REVIEW_ACTIVE',
  MC_PAYMENT_SENT_ACTIVE: 'MC_PAYMENT_SENT_ACTIVE',
  MC_PAYMENT_SENT_OVERRIDE_ACTIVE: 'MC_PAYMENT_SENT_OVERRIDE_ACTIVE',
};

const CLAIM_COMPLETED_STATE = {
  MC_REJECTED: 'MC_REJECTED',
  MC_PAYMENT_SENT_80: 'MC_PAYMENT_SENT_80',
  MC_PAYMENT_SENT_80X: 'MC_PAYMENT_SENT_80X',
  MC_PAYMENT_SENT_OVERRIDE: 'MC_PAYMENT_SENT_OVERRIDE',
  MC_PAYMENT_RECONCILED: 'MC_PAYMENT_RECONCILED',
  MC_CLAIM_CENTER: 'MC_CLAIM_CENTER',
  MC_PAYMENT_SENT_B: 'MC_PAYMENT_SENT_B',
  MC_PAYMENT_SENT_C: 'MC_PAYMENT_SENT_C',
};

/**
 * Exports.
 */
module.exports = {
  CLAIM_ACTIVE_STATE,
  CLAIM_COMPLETED_STATE,
};
