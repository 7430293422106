/**
 * @imarcsgroup/client:src/session/actions.js
 */

/**
 * Imports.
 */
const { createAction } = require('@reduxjs/toolkit');

/**
 * Actions.
 */
const sessionClear = createAction('session/clear');
const sessionLogOut = createAction('session/logout');
const sessionFromTokens = createAction('session/fromTokens');
const sessionSet = createAction('session/set');
const sessionActivity = createAction('session/activity');

/**
 * Exports.
 */
module.exports = {
  sessionClear,
  sessionLogOut,
  sessionFromTokens,
  sessionSet,
  sessionActivity,
};
