/**
 * @imarcsgroup/client:src/components/drawconfiglist/index.js
 */

/**
 * DrawConfigList component factory.
 */
function drawconfiglistComponentFactory(dyn) {
  const { log, controllers, BaseComponent } = dyn();
  const { drawconfiglistVisible } = controllers;

  /**
   * DrawConfigList Component.
   */
  class DrawConfigListComponent extends BaseComponent {

    build({ drawConfigs }) {
      const { colsPerRow } = this.info;
      let x, y, row;
      for(let idx = 0; idx < drawConfigs.length; idx++) {
        if(row === undefined || x >= colsPerRow) {
          const previous = row;
          row = this.elements.tpl.clone();
          row.removeAttr('hidden').removeClass('ui-template-row');
          row.insertAfter(previous || this.elements.tpl);
          x = 0;
          y = y === undefined ? 0 : y+1;
        }
        const drawConfig = drawConfigs[idx];
        let dynData = {};
        try {
          dynData = JSON.parse(drawConfig.dynData);
        } catch(ex) {
          if(ex.name === 'SyntaxError') {
            log.warn(ex, `Failure parsing draw config dyndata for ID "${drawConfig.drawConfigId}".`);
          } else {
            throw ex;
          }
        }
        const homeHref = dynData?.desktop?.links?.home;
        const cell = row.find('.col').eq(x);
        if(!drawConfig.hasOpenDraws) {
          cell.addClass('ui-drawconfig-closed');
        }
        const drawConfigName = drawConfig.name;
        const openSrc = dynData?.desktop?.images?.thumbnail?.default;
        const closedSrc = dynData?.desktop?.images?.thumbnail?.closed;
        let imageSrc = drawConfig.hasOpenDraws ? openSrc : closedSrc;
        cell.find('.ui-drawconfiglist-name').text(drawConfigName);
        cell.attr('data-drawconfigid', drawConfig.drawConfigId);
        let img = cell.find('img');
        if(!(img && img.length)) {
          img = $('<img>');
          img.appendTo(cell);
        }
        const loader = $('<img>');
        loader.one('load', () => {
          img.attr('alt', drawConfigName);
          img.css({ 'background-image': `url('${imageSrc}')` });
          const hoverUrl = img.attr('data-hover');
          if(hoverUrl) {
            const originalUrl = img.attr('src');
            img.on('mouseenter', () => {
              img.attr('src', hoverUrl);
            });
            img.on('mouseleave', () => {
              img.attr('src', originalUrl);
            });
          }
        });
        loader.on('error', () => {
          if(imageSrc === closedSrc) {
            log.warn(`Attempting fall back to open-draw-config image for closed-draw-config "${drawConfigName}".`);
            imageSrc = openSrc;
            loader.attr('src', imageSrc);
          } else {
            log.warn(`Unable to load promotion image for "${drawConfigName}".`);
            img.attr('src', null);
            img.attr('alt', 'Promotion image is missing.');
          }
        });
        loader.attr('src', imageSrc);
        let anchor;
        if(homeHref) {
          anchor = img.closest('a');
          if(!(anchor && anchor.length)) {
            anchor = $('<a>');
            img.appendTo(anchor);
            anchor.appendTo(cell);
          }
          anchor.attr('href', homeHref);
          anchor.attr('data-track', `click`);
          anchor.attr('data-track-category', `promotion homepage`);
          anchor.attr('data-track-action', `clicked : ${drawConfigName}`);
        }
        if(this.info.thumbnailSize && this.info.thumbnailSize.length === 2) {
          img.css('width', this.info.thumbnailSize[0]);
          img.css('height', this.info.thumbnailSize[1]);
        }
        x += 1;
      }
    }

    async onMount({ attach, info }) {
      log.trace(`Mounting draw-config-list component (#${this.id}).`);
      attach({
        tpl: '.ui-template-row',
      });
      info({
        colsPerRow: () => this.elements.tpl.find('.col').length,
        thumbnailSize: ['[data-thumbnail-size]'],
      });
      if(this.info.thumbnailSize) {
        this.info.thumbnailSize = this.info.thumbnailSize.split('x');
      }
      const { data: { drawConfigs } } = await drawconfiglistVisible();
      this.build({ drawConfigs });
    }

    static autowireSelector() {
      return '.ui-drawconfiglist';
    }
  }

  /**
   * Autowire.
   */
  const drawconfiglistAutowire = DrawConfigListComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    drawconfiglistAutowire, DrawConfigListComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  drawconfiglistComponentFactory,
};
