function unsubscribeFactory(dyn) {
  const { apiFetch } = dyn();

  async function unsubscribe(externalId) {
    const { status } = await apiFetch({
      method: 'PUT',
      path: '/api/v1/players/notifications/unsubscribe/',
      // Renamed to avoid automatic decryption
      body: { externalKey: externalId },
    });
    return { status };
  };

  return {
    unsubscribe,
  };
}

module.exports = {
  unsubscribeFactory,
};
