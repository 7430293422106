/**
 * Wait for DOM event.
 */
function waitForDomEvent(target, type, timeOut=0) {
  return new Promise((resolve, reject) => {
    const options = { capture: true, once: true, passive: true };
    let timeoutId;
    if(timeOut) {
      timeoutId = setTimeout(() => {
        target.removeEventListener(type, messageHandler, options);
        reject(new Error('waitForDomEvent:TIMEOUT'));
      }, timeOut);
    }
    function messageHandler(evt) {
      if(timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = undefined;
      }
      resolve();
    }
    target.addEventListener(type, messageHandler, options);
  });
}


/**
 * Count the number of checked elements in a collection.
 */
function countCheckedElements(elements) {
  let checked = 0;
  elements.each((idx, ele) => {
    if($(ele).is(':checked')) {
      checked += 1;
    }
  });
  return checked;
}

/**
 * Get the next element in a set of elements.
 */
function nextInSet(elements, current) {
  let state = 'init';
  const next = elements.filter((idx, ele) => {
    if(state === 'init' && ele === current.get(0)) {
      state = 'current';
    } else if(state === 'current') {
      state = 'found';
      return true;
    }
    return false;
  });
  return next;
}

function getFieldType(ele) {
  ele = $(ele);
  let fieldType;
  const type = (ele.prop('nodeName') || 'undefined').toLowerCase() + ':' + (ele.attr('type') || 'undefined').toLowerCase();
  switch (type) {
    case 'input:undefined':
    case 'input:text': {
      fieldType = [ 'input', 'text' ];
      break;
    }
    case 'input:password': {
      fieldType = [ 'input', 'password' ];
      break;
    }
    case 'input:email': {
      fieldType = [ 'input', 'email' ];
      break;
    }
    case 'input:checkbox': {
      fieldType = [ 'checkbox', 'checkbox' ];
      break;
    }
    case 'select:undefined': {
      fieldType = [ 'select', 'select' ];
    }
  }
  if(!fieldType || fieldType.length !== 2) {
    throw new Error(`Unsupported node/type field "${type}"`);
  }
  return fieldType;
}

function getFieldValue(ele) {
  ele = $(ele);
  const [ type, subtype ] = getFieldType(ele);
  switch (type) {
    case 'input': {
      return ele.val();
    }
    case 'select': {
      return ele.find('option:selected').attr('value');
    }
    case 'checkbox': {
      return ele.is(':checked') ? 'checked' : '';
    }
    default: {
      throw new Error(`Unsupported node/type field "${type}:${subtype}"`);
    }
  }
}



const focussableSelector = 'a:not([disabled]), button:not([disabled]), input:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
function getNextFocusableElement(target) {
  target = target ?? $(document.activeElement);
  if(target && target.length) {
    const focussable = $(focussableSelector).filter((_idx, ele) => {
      ele = $(ele);
      return ele.get(0) === target.get(0) || ele.is(':visible');
    });
    const idx = focussable.index(target);
    if(idx !== -1) {
      return $(focussable.get(idx+1));
    }
  }
  return $();
}


/**
 * Exports.
 */
module.exports = {
  waitForDomEvent,
  getNextFocusableElement,
};
