/**
 * @imarcsgroup/client:src/components/lopgout/index.js
 */

/**
 * LogOut component factory.
 */
function logoutComponentFactory(dyn) {
  const { log, BaseComponent, controllers, store } = dyn();
  const { navigateTo, logout } = controllers;

  /**
   * LogOut Component.
   */
  class LogOutComponent extends BaseComponent {
    onMount({ onEvent }) {
      log.trace(`Mounting log-out component (#${this.id}).`);
      const url = this.ele.attr('href') ?? this.ele.attr('data-href') ?? '';
      onEvent({
        ':self': {
          async click(evt) {
            evt.preventDefault();
            await logout(url);
            navigateTo(url);
          },
        },
      });
    }

    static autowireSelector() {
      return 'a.ui-logout, button.ui-logout';
    }
  }

  /**
   * Autowire.
   */
  const logoutAutowire = LogOutComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    logoutAutowire, LogOutComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  logoutComponentFactory,
};
