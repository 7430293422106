const { HTTP_OK, HTTP_SERVICE_UNAVAILABLE, HTTP_NO_CONTENT } = require('../../utilities/httpcode');
const { codeOrError } = require('../../utilities/rest');

function verifyLoginFactory(dyn) {
  const { apiFetch } = dyn();

  async function sendVerifyLogin(authCode, sms) {
    const meta = { authCode };
    const type = sms ? 'VERIFY_LOGIN_SMS' : 'VERIFY_LOGIN';
    const { data, status } = await apiFetch({
      method: 'POST',
      path: `/api/v1/oauth/mfa`,
      body: { type, meta: JSON.stringify(meta) },
    });
    if(status === HTTP_SERVICE_UNAVAILABLE) {
      return { code: 'service_unavailable' };
    } else if(status !== HTTP_OK && status !== HTTP_NO_CONTENT) {
      return codeOrError(data);
    }
    return {
      ok: true,
    };
  }

  return {
    sendVerifyLogin,
  };
}

module.exports = {
  verifyLoginFactory,
};
