/**
 * Imports.
 */
const { isObject, isString, isArray } = require('@theroyalwhee0/istype');
const { NONE } = require('../../utilities/none');

/**
 * Build an API url from parts.
 */
function buildApiUrl(path, apiDomain, query) {
  const url = new URL(path, apiDomain);
  if(query) {
    if(query instanceof URLSearchParams) {
      url.search = query.toString();
    } else {
      for(let name in query) {
        const value = query[name];
        if(isArray(value)) {
          value.forEach((item) => {
            url.searchParams.set(name, '' + item);
          });
        } else {
          url.searchParams.set(name, '' + value);
        }
      }
    }
  }
  return url;
}

/**
 * API core fetch factory.
 * Businesss rules during fetch go in apiFetch, fetch code goes in apiFetchCore.
 */
function apiFetchCoreFactory(dyn) {
  const { log, fetch = global.fetch, apiKey, apiDomain, getState } = dyn();
  if(!(apiDomain && isString(apiDomain))) {
    throw new Error(`API Domain must be a string.`);
  }
  log.debug(`Running against SC API '${apiDomain}'`);
  return async function apiFetchCore({ method, path, query, body, self = 'auto', cache = false, cacheBuster = false, recursive = false }) {
    const now = Date.now();
    const url = buildApiUrl(path, apiDomain, query);
    const headers = {
      'content-type': 'application/json',
    };
    if(isObject(body) || isArray(body)) {
      body = JSON.stringify(body);
    }
    const options = {
      method, headers, body,
      mode: 'cors',
      redirect: 'follow',
    };
    if(self === 'auto') {
      self = /\/self(\/|$)/.test(url.pathname);
    }
    if(apiKey) {
      headers['x-api-key'] = apiKey;
    }
    if(self) {
      const token = getState((_) => _.session?.tokens?.oauth?.key);
      if(token) {
        headers['authorization'] = `oauth ${token}`;
      }
    }
    options.credentials = 'omit';
    if(!cache) {
      headers['cache-control'] = 'no-store, max-age=0';
      if(cacheBuster) {
        url.searchParams.set('_', now);
      }
    }
    let result;
    try {
      const response = await fetch(url.href, options);
      const { status } = response;
      let data = NONE;
      const contentType = response.headers.get('content-type');
      if(/^application\/json(;|$)/.test(contentType)) {
        // Only parse response if it is JSON.
        data = await response.json();
      }
      const responseHeaders = {};
      for(let header of response.headers) {
        responseHeaders[header[0]] = header.slice(1);
      }
      result = { status, data, headers: responseHeaders, url, res: response };
    } catch(ex) {
      result = {
        status: 400,
        data: {
          code: 'bad_request',
          message: 'Bad request.',
        },
        headers: {},
        url,
        res: {},
      };
      if(ex.message.includes('NetworkError')) {
        result.status = 503;
        result.data = {
          code: 'service_unavailable',
          message: 'Service unavailable.',
        };
      }
    }
    return result;
  };
}

/**
 * Claims core fetch factory.
 * Businesss rules during fetch go in claimsFetch, fetch code goes in claimsFetchCore.
 */
function claimsFetchCoreFactory(dyn) {
  const { log, fetch = global.fetch, apiKey, apiDomain, config, getState } = dyn();
  if(!(apiDomain && isString(apiDomain))) {
    log.warn(`Claims API misconfigured. Stubbing.`);
    return async function apiFetchCore({ method, path, query, body, self = 'auto', cache = false, cacheBuster = false, recursive = false }) {
      const response = {
        status: 501,
        data: { },
        headers: {
          'content-length': '0',
          'content-type': 'application/json;charset=UTF-8',
        },
      };
      return response;
    };
  }
  log.debug(`Running against Claims API '${apiDomain}'`);
  return async function apiFetchCore({ method, path, query, body, self = 'auto', cache = false, cacheBuster = false, recursive = false }) {
    const now = Date.now();
    const url = buildApiUrl(path, apiDomain, query);
    const headers = {
      'content-type': 'application/json',
      'x-site-id': config.siteId,
      'x-site-env-id': config.env,
    };
    if(isObject(body) || isArray(body)) {
      body = JSON.stringify(body);
    }
    const options = {
      method, headers, body,
      mode: 'cors',
      redirect: 'follow',
    };
    if(self === 'auto') {
      self = /\/self(\/|$)/.test(url.pathname);
    }
    if(apiKey) {
      headers['x-api-key'] = apiKey;
    }
    if(self) {
      let token = getState((_) => _.session?.tokens?.oauth?.key);
      if(config.override) {
        token = config.override;
      }
      if(token) {
        headers['authorization'] = `oauth ${token}`;
      }
    }
    options.credentials = 'omit';
    if(!cache) {
      headers['cache-control'] = 'no-store, max-age=0';
      if(cacheBuster) {
        url.searchParams.set('_', now);
      }
    }
    const response = await fetch(url.href, options);
    const { status } = response;
    let data = NONE;
    const contentType = response.headers.get('content-type');
    if(/^application\/json(;|$)/.test(contentType)) {
      // Only parse response if it is JSON.
      data = await response.json();
    }
    const responseHeaders = {};
    for(let header of response.headers) {
      responseHeaders[header[0]] = header.slice(1);
    }
    return { status, data, headers: responseHeaders, url };
  };
}

/**
 * Exports.
 */
module.exports = {
  apiFetchCoreFactory,
  claimsFetchCoreFactory,
};
