/**
 * @imarcsgroup/client:src/utilities/none.js
 */

/**
 * None symbol.
 */
const NONE = Symbol('NONE');

/**
 * Exports.
 */
module.exports = {
  NONE,
};
