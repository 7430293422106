/**
 * @imarcsgroup/client:src/strings/login.js
 */

/**
 * Strings.
 */
const strings = {
  'login.error': 'An error has occurred while trying to log in.',
  'login.service_unavailable': 'An error has occurred while trying to log in.',
  'login.invalid_login': 'Invalid Email Address or Password.',
  'login.temporary_lockout': 'Your account has been locked due to invalid login attempts.',
  'login.email.required': 'Email Address is required.',
  'login.email.mismatched': 'Email Address must be an email address.',
  'login.password.required': 'Password is required.',
  'login.consents.required': 'Please accept the conditions.',
};

/**
 * Exports.
 */
module.exports = strings;
