/**
 * Run an async function on interval.
 * Invokations may not overlap.
 * Invoking while already running flags to run immediatly.
 * Returns a stopInterval function.
 */
async function asyncInterval(callback, every, initial = false) {
  let running;
  let stop;
  let changed;
  async function intervalCallback() {
    if(stop) {
      return;
    }
    if(running) {
      changed = true;
      return;
    }
    do {
      running = true;
      changed = false;
      running = callback();
      await running;
    } while(changed);
    running = false;
  }
  let intervalId = setInterval(intervalCallback, every);
  if(initial) {
    await intervalCallback();
  }
  async function stopInterval() {
    stop = true;
    if(intervalId) {
      clearInterval(intervalId);
      intervalId = undefined;
    }
    if(running) {
      await running;
      running = false;
    }
  }
  return stopInterval;
}

/**
 * UNTESTED
 * Run an async function on interval and wait for the interval to stop.
 * Invokations may not overlap.
 * Invoking while already running flags to run immediatly.
 * The function is handed an object with stopInterval.
 */
// function asyncIntervalWait(callback, every, initial = false) {
//   return new Promise(async (resolve) => {
//     const state = {
//       running: false,
//       stop: false,
//       changed: false,
//       intervalId: undefined,
//       stopInterval: () => {
//         throw new Error(`stopInterval not initialized correctly`);
//       },
//     };
//     async function intervalCallback() {
//       if (state.stop) {
//         return;
//       }
//       if (state.running) {
//         state.changed = true;
//         return;
//       }
//       do {
//         state.running = true;
//         state.changed = false;
//         const { stopInterval } = state;
//         running = callback({ stopInterval });
//         await state.running;
//       } while (state.changed);
//       state.running = false;
//     }
//     state.intervalId = setInterval(intervalCallback, every);
//     async function stopInterval() {
//       state.stop = true;
//       if (state.intervalId) {
//         clearInterval(state.intervalId);
//         state.intervalId = undefined;
//       }
//       if (state.running) {
//         await state.running;
//         state.running = false;
//       }
//       resolve();
//     }
//     state.stopInterval = stopInterval;
//     if (initial) {
//       await intervalCallback();
//     }
//   });
// }

/**
 * Exports.
 */
module.exports = {
  asyncInterval,
};
