/**
 * @imarcsgroup/client:src/components/bridge/index.js
 */

/**
 * Bridge component factory.
 */
function bridgeComponentFactory(dyn) {
  const { log, BaseComponent, controllers } = dyn();
  const { navigateTo } = controllers;

  /**
   * Bridge Component.
   */
  class BridgeComponent extends BaseComponent {
    onMount({ info, onState }) {
      log.trace(`Mounting bridge component (#${this.id}).`);
      onState({
        session: {
          selector: (_) => _.session.roles,
          action: async (roles) => {
            if(roles.includes('@access')) {
              const urlParams = new URLSearchParams(window.location.search);
              const path = urlParams.get('path');
              const validPath = /^\/([a-z0-9_]+\/)*(\?[a-z0-9_\-&=]+)?$/i.test(path);
              if(validPath) {
                navigateTo(path);
              } else {
                log.warn(`Invalid bridge path. (${path})`);
              }
            }
          },
        },
      });
    }

    static autowireSelector() {
      return '.ui-bridge';
    }
  }

  /**
   * Autowire.
   */
  const bridgeAutowire = BridgeComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    bridgeAutowire, BridgeComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  bridgeComponentFactory,
};
