/**
 * api-client:src/ui/domready.js
 */

/**
 * Resolve when DOM is ready.
 */
function domReady(jq) {
  return new Promise((resolve) => {
    jq(() => {
      resolve();
    });
  });
}

/**
 * DOM Ready factory.
 */
async function domReadyFactory(dyn) {
  const { log, jq } = dyn();
  await domReady(jq);
  log.debug('DOM ready.');
}

/**
 * Exports.
 */
module.exports = {
  domReady,
  domReadyFactory,
};
