/**
 * @imarcsgroup/client:src/main.js
 */

/**
 * Imports.
 */
const { dynasty } = require('@theroyalwhee0/dynasty');
const { checkLibraries } = require('./libs');
const { jQuery } = require('./libs/jquery');
const { raiseUnhandledError } = require('./utilities/unhandled');

/**
 * Application Imports.
 */
const { appFactory } = require('./app');
const { logFactory } = require('./app/logs');
const { securityService } = require('./app/security');
const { errorHandler } = require('./app/errors');

/**
 * String Tables.
 */
const { stringsFactory } = require('./services/strings');
const defaultStrings = require('./strings/all');

/**
 * Validation.
 */
const { validationFactory } = require('./validation');

/**
 * Service Imports.
 */
const { apiFetchFactory } = require('./services/fetch/api');
const { claimsFetchFactory } = require('./services/fetch/claims');
const { apiFetchCoreFactory, claimsFetchCoreFactory } = require('./services/fetch/core');
const { sessionServiceFactory } = require('./services/session/service');
const { userdataServiceFactory } = require('./services/userdata/service');
const { dateTimeServiceFactory } = require('./services/datetime/index');
const { activityServiceFactory } = require('./services/activity/index');
const { routerServiceFactory } = require('./services/router/index');

/**
 * Store Imports.
 */
const { storeFactory } = require('./store');
const { subscribeFactory } = require('./store/subscribe');
const { subscribeTillFactory } = require('./store/subscribetill');
const { getStateFactory } = require('./store/getstate');
const actions = require('./store/actions');
const reducers = require('./store/reducers');
const middleware = require('./store/middleware');

/**
 * Component Imports.
 */
const { componentFactories } = require('./components/all');
const { componentsFactory } = require('./components/factory');
const { claimslistComponentFactory } = require('./components/claimslist');

/**
 * Controller Imports.
 */
const { controllerFactories } = require('./controllers/all');
const { controllersFactory } = require('./controllers/factory');
const { refreshSessionFactory } = require('./controllers/refreshsession');

/**
 * Configuration Imports.
 */
const { configDefaultsFactory } = require('./config');

/**
 * API Imports.
 */
const { apiFactory } = require('./api');
const { apiStartFactory } = require('./api/start');
const { apiEndFactory } = require('./api/end');

/**
 * UI Imports.
 */
const { uiFactory } = require('./ui');
const { domReadyFactory } = require('./ui/domready');
const { autoWireFactory } = require('./ui/autowire');
const { autoWireEarlyFactory } = require('./ui/autowireearly');
const { baseComponentFactory } = require('./components/base');

/**
 * Constants.
 */
const VERSION = '3.0.0';

/**
 * Setup logging and error handling.
 */
function setupLoggingAndErrorHandling() {
  try {
    const log = logFactory({ name: 'api' });
    errorHandler({ log });
    return { log };
  } catch(err) {
    return { err };
  }
}

/**
 * Setup API Library.
 */
(async function apiSetupLibrary() {
  // Setup logging and error handling.
  const { log, err } = setupLoggingAndErrorHandling();
  if(err || (!log)) {
    console.error('There was an error setting up logging or error handling.', err);
    return;
  }

  // Check security.
  if(!securityService({ log })) {
    throw new Error('Security service failed.');
  }

  // Setup global object.
  const client = apiFactory({ global: window, log, defaultStrings, VERSION });

  // Check libraries.
  checkLibraries();

  // WARN: There should not be async code above here for coordination of the library initialization and loading.

  // Setup dependencies.
  await dynasty(({ add, value, once, depends, attach, extend, entryPoint, config, collect, pullMember }) => {
    // Config.
    config(configDefaultsFactory());
    config(window.siteConfiguration || {});
    // General.
    add('start', entryPoint(), depends('app'));
    add('log', value(log));
    add('jq', value(jQuery));
    add('client', value(client));
    add('app',
      once(appFactory),
      attach('log', 'client', 'components'),
      depends('ui', 'services', 'store')
    );
    // Library.
    add('apiStart',
      once(apiStartFactory),
      attach('log')
    );
    add('apiEnd',
      once(apiEndFactory),
      attach('log')
    );
    // UI.
    add('ui',
      once(uiFactory),
      attach('log', 'components'),
      depends('autoWireEarly', 'autoWire', 'domReady')
    );
    add('domReady',
      once(domReadyFactory),
      attach('log', 'jq')
    );
    add('autoWire',
      once(autoWireFactory),
      attach('log', 'jq', 'components'),
      depends('apiEnd')
    );
    add('autoWireEarly',
      once(autoWireEarlyFactory),
      attach('log', 'jq', 'components'),
      depends('apiStart')
    );
    // UI Components.
    add('BaseComponent',
      once(baseComponentFactory),
      attach('log', 'subscribe', 'validation', 'stringTable', 'controllers')
    );
    add('components',
      once(componentsFactory),
      attach(
        'log', 'jq', 'controllers',
        'dateTime',
        'BaseComponent', 'validation',
        'getState', 'stringTable',
        'builtComponents',
        '$env=env'
      ), extend({
        componentFactories,
      })
    );
    add('builtComponents',
      collect(),
      attach('claimsList')
    );
    add('claimsList',
      once(claimslistComponentFactory),
      attach(
        'log', 'controllers',
        'BaseComponent', 'stringTable',
        'dateTime',
        '$env=env', '$debugClaims=debug.debugClaims',
        '$instantsResourcesDomain=external.instantsResourcesDomain'
      )
    );
    // Controllers.
    add('controllers',
      once(controllersFactory),
      attach(
        'log', 'apiFetch', 'claimsFetch',
        'dispatch', 'actions',
        'getState', 'subscribeTill',
        '$siteId=siteId', '$clientId=clientId',
        'builtControllers'
      ), extend({
        controllerFactories,
      })
    );
    add('builtControllers',
      collect(),
      attach('refreshSession')
    );
    add('refreshSession',
      once(refreshSessionFactory),
      attach(
        'log', 'apiFetchCore',
        'dispatch', 'actions',
        'getState', 'subscribeTill',
        '$siteId=siteId', '$clientId=clientId'
      )
    );
    // Services.
    add('apiFetch',
      once(apiFetchFactory),
      attach('log', 'apiFetchCore', 'actions', 'dispatch', 'subscribeTill', 'refreshSession')
    );
    add('apiFetchCore',
      once(apiFetchCoreFactory),
      attach('log', 'getState', '$apiKey=apiKey', '$apiDomain=apiDomain')
    );
    add('claimsFetch',
      once(claimsFetchFactory),
      attach('log', 'claimsFetchCore', 'actions', 'dispatch', 'subscribeTill', 'refreshSession')
    );
    add('claimsFetchCore',
      once(claimsFetchCoreFactory),
      attach('log', 'getState',
        '$apiKey=claims.apiKey', '$apiDomain=claims.apiDomain', '$config=claims')
    );
    add('dateTime',
      once(dateTimeServiceFactory),
      attach('log', '$tz=dateTime.tz', '$format=dateTime.format', '$locale=dateTime.locale')
    );
    // Services.
    add('services', collect(), attach('sessionService', 'userdataService', 'activityService', 'routerService'));
    add('sessionService',
      once(sessionServiceFactory),
      attach(
        'log', 'actions', 'controllers',
        'dispatch', 'subscribe', 'getState'
      )
    );
    add('userdataService',
      once(userdataServiceFactory),
      attach(
        'log', 'actions', 'controllers',
        'dispatch', 'subscribe', 'getState'
      )
    );
    add('activityService',
      once(activityServiceFactory),
      attach('log', 'actions', 'dispatch')
    );
    add('routerService',
      once(routerServiceFactory),
      attach('log', 'subscribe', 'controllers')
    );
    // Validation.
    add('validation', once(validationFactory), attach('log'));
    // Store.
    add('store',
      once(storeFactory),
      attach('log', 'reducers', 'middleware')
    );
    add('subscribeTill', once(subscribeTillFactory), attach('subscribe'));
    add('subscribe', once(subscribeFactory), attach('log', 'store'));
    add('dispatch', pullMember('store', 'dispatch'), attach('store'));
    add('getState', once(getStateFactory), attach('store'));
    add('actions', value(actions));
    add('reducers', value(reducers));
    add('middleware', value(middleware));
    // Messages.
    add('stringTable',
      once(stringsFactory),
      attach('strings', 'log')
    );
    add('strings',
      pullMember('client', 'strings'),
      attach('client')
    );
  });
}()).catch(raiseUnhandledError);
