/**
 * api-client:src/ui/index.js
 */

/**
 * UI factory.
 */
function uiFactory(dyn) {
  const { log } = dyn();
  log.trace('UI setup.');
}

/**
 * Exports.
 */
module.exports = {
  uiFactory,
};
