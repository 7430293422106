/**
 * api-client:src/app/index.js
 */

/**
 * API factory.
 */
function appFactory(dyn) {
  const { log, client, strings } = dyn();
  const env = process.env.NODE_ENV;
  log.info({ version: client.VERSION, env }, 'Application setup.');
}

/**
 * Exports.
 */
module.exports = {
  appFactory,
};
