/**
 * @imarcsgroup/client:src/session/actions.js
 */

/**
 * Imports.
 */
const { createAction } = require('@reduxjs/toolkit');

/**
 * Actions.
 */
const userdataClear  = createAction('userdata/clear');
const userdataSet = createAction('userdata/set');

/**
 * Exports.
 */
module.exports = {
  userdataClear,
  userdataSet,
};
