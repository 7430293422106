/**
 * @imarcsgroup/client:src/strings/all.js
 */

/**
 * Imports.
 */
const strings = {
  ...require('./entrylist'),
  ...require('./feedback'),
  ...require('./login'),
  ...require('./passwordupdate'),
  ...require('./register'),
  ...require('./ticketentry'),
  ...require('./forgotpassword'),
};

/**
 * Exports.
 */
module.exports = strings;
