/**
 * Imports.
 */
const controllerFactories = {
  // NOTE: ./refreshsession factory included in main.
  ...require('./addressverifications'),
  ...require('./closeaccount'),
  ...require('./consents'),
  ...require('./drawconfiglist'),
  ...require('./feedback'),
  ...require('./forgotpassword'),
  ...require('./login'),
  ...require('./logout'),
  ...require('./lookups'),
  ...require('./passwordupdate'),
  ...require('./profile'),
  ...require('./register'),
  ...require('./resetpassword'),
  ...require('./router/navigateto'),
  ...require('./submissions'),
  ...require('./svcnotify'),
  ...require('./userdata'),
  ...require('./verifylogin'),
  ...require('./verifyemail'),
  ...require('./zipcode'),
  ...require('./claimslist'),
  ...require('./claimslist/instanttickets'),
  ...require('./claimssetoffs'),
  ...require('./notifypreferences'),
  ...require('./messages'),
  ...require('./unsubscribe'),
  ...require('./claimform'),
  ...require('./verifytoken'),
};

/**
 * Exports.
 */
module.exports = {
  controllerFactories,
};
