/**
 * @imarcsgroup/client:src/utilities/unhandled.js
 */

/**
 * Raise an unhandled error event.
 * @param {Error} err Error to raise.
 */
function raiseUnhandledError(err) {
  const event = new Event('error');
  event.error = err;
  global.dispatchEvent(event);
}

/**
 * Exports.
 */
module.exports = {
  raiseUnhandledError,
};
