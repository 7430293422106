function assembleAddress(values) {
  const address = {
    address: [],
    city: values.city,
    state: values.state,
    zipcode: values.zipcode,
  };
  if(values.address) {
    const addresses = values.address.split('\n');
    address.address = address.address.concat(addresses);
  }
  if(values.zipcode4) {
    address.zipcode += `-${values.zipcode4}`;
  }
  if(values.county) {
    address.county = values.county;
  }
  return address;
}

async function showVerifyAddressNotFound({ selector, address }) {
  const results = {};
  const modalEle = $(selector);
  modalEle.find('.ui-address-entered').text(formatAddress(address));
  const acceptEle = modalEle.find('.ui-address-accept');
  const editEle = modalEle.find('.ui-address-edit ');
  const onClose = new Promise((resolve) => {
    acceptEle.on('click', () => {
      results.accept = true;
      resolve();
    });
    editEle.on('click', () => {
      results.edit = true;
      resolve();
    });
    modalEle.on('hidden.bs.modal', (evt) => {
      results.close = true;
      resolve();
    });
  });
  modalEle.modal('show');
  await onClose;
  modalEle.modal('hide');
  return results;
}

async function showVerifyAddressMatches({ selector, matches, address }) {
  const results = {};
  const modalEle = $(selector);
  modalEle.find('.ui-address-entered').text(formatAddress(address));
  const addressList = modalEle.find('.ui-address-list');
  const templateEle = addressList.find('.ui-address-list-template');
  if(templateEle.length === 0) {
    throw new Error(`Address List Template element not found.`);
  }
  templateEle.attr('hidden', 'hidden');
  addressList.children().not('.ui-address-list-template').remove();
  matches.forEach((item, idx) => {
    const ele = templateEle.clone();
    ele.removeClass('ui-address-list-template').removeAttr('hidden');
    ele.find('.ui-address-value').text(formatAddress(item));
    ele.find('.ui-address-selection').val(idx);
    addressList.append(ele);
    if(idx === 0) {
      ele.find('.ui-address-selection').prop('checked', true);
    }
  });
  const acceptEle = modalEle.find('.ui-address-accept');
  const editEle = modalEle.find('.ui-address-edit');
  const onClose = new Promise((resolve) => {
    acceptEle.on('click', () => {
      const idx = Number(
        $('.ui-address-entered-list').find('.ui-address-selection:checked').val() ||
        addressList.find('.ui-address-selection:checked').val()
      );
      if(!isNaN(idx)) {
        if(idx === -1) {
          results.accept = true;
          resolve();
        } else {
          results.selected = matches[idx];
          resolve();
        }
      } else {
        throw new Error(`Invalid selected address index "${
          $('.ui-address-entered-list').find('.ui-address-selection:checked').val() ||
            addressList.find('.ui-address-selection:checked').val()
        }".`);
      }
    });
    editEle.on('click', () => {
      results.edit = true;
      resolve();
    });
    modalEle.on('hidden.bs.modal', (evt) => {
      results.close = true;
      resolve();
    });
  });
  modalEle.modal('show');
  await onClose;
  modalEle.modal('hide');
  return results;
}

function formatAddress(address) {
  const addressEntered = [].concat(address.address);
  addressEntered.push(`${address.city}`);
  if(address.county) {
    addressEntered.push(`${address.county}`);
  }
  addressEntered.push(`Texas`);
  addressEntered.push(`${address.zipcode}${!!address.zipcodeAddon ? '-' + address.zipcodeAddon : ''}`);
  return addressEntered.join('\n');
}

module.exports = {
  assembleAddress,
  showVerifyAddressNotFound,
  showVerifyAddressMatches,
};
