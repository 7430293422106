/**
 * @imarcsgroup/client:src/components/entrylist/index.js
 */

const { isArray, isInteger } = require('@theroyalwhee0/istype');
const { regExpFactory } = require('../../utilities/regexp');

/**
 * EntryList component factory.
 */
function entryListComponentFactory(dyn) {
  const { log, controllers, BaseComponent, dateTime } = dyn();
  const { drawconfiglistVisible, selfSubmissions } = controllers;

  const context = 'entrylist';

  let initialDrawConfig = Number(new URL(document.location).searchParams.get('drawconfig'));
  initialDrawConfig = isInteger(initialDrawConfig) ? initialDrawConfig : '';

  /**
   * EntryList Component.
   */
  class EntryListComponent extends BaseComponent {
    populateSelect({ drawConfigs }) {
      const populated = this.elements.select.attr('data-populated') === 'true';
      if(!drawConfigs || populated) {
        return;
      }
      this.elements.select.attr('data-populated', 'true');
      for(let idx = 0; idx < drawConfigs.length; idx++) {
        const drawConfig = drawConfigs[idx];
        const option = $('<option>');
        option.text(drawConfig.name);
        option.attr('value', drawConfig.drawConfigId);
        option.appendTo(this.elements.select);
      }
    }

    async build({ url, drawConfigs, drawConfigId }) {
      this.populateSelect({ drawConfigs });
      if(drawConfigId) {
        // The correct option may not be selected, select it.
        this.elements.select.find(`option[value="${drawConfigId}"]`).prop('selected', true);
      }
      this.ele.find('tbody tr').not(this.elements.tpl).remove();
      // Build formatters.
      const tplHeaders = this.elements.tpl.find('th');
      const formatters = Array.prototype.reduce.call(tplHeaders, (formatters, ele) => {
        ele = $(ele);
        const format = ele.attr('data-format');
        const parse = ele.attr('data-parse');
        if(format && parse) {
          const name = ele.attr('class').split(/\s/).reduce((name, value) => {
            if(!name) {
              const match = /^ui-el-([a-z]+)$/.exec(value);
              if(match) {
                name = match[1];
              }
            }
            return name;
          }, '');
          if(name) {
            const re_parse = regExpFactory(parse);
            const formatter = (value) => {
              const re_format = /\$\{\}/g;
              const match = re_parse.exec(value);
              if(match) {
                let idx = 0;
                value = format.replace(re_format, () => {
                  idx += 1;
                  return match[idx];
                });
              }
              return value;
            };
            formatters[name] = formatter;
          }
        }
        return formatters;
      }, {});
      this.clearMessages();
      if(drawConfigId || url) {
        const submissionResults = await selfSubmissions({ url, drawConfigId, pagesize: this.info.pagesize });
        if(!submissionResults.ok) {
          const code = submissionResults?.code || 'error';
          this.displayMessages({ message: [ context, code ] });
          return false;
        } else if(submissionResults?.data?.submissions.length) {
          const { data } = submissionResults;
          let row;
          for(let idx = 0; idx < data.submissions.length; idx++) {
            const submission = data.submissions[idx];
            const previous = row;
            let entryCount = 0;
            let entryCodes = [];
            let dateSubmitted;
            for(let loop = 0; loop < submission.submissions.length; loop++) {
              const childSubmission = submission.submissions[loop];
              entryCodes.push(childSubmission.entryCode);
              entryCount += childSubmission.drawEntryCount;
              dateSubmitted = dateSubmitted ?? dateTime.timestampToDateTime(childSubmission.dateSubmitted);
            }
            const dateSubmittedFormatted = dateTime.formatDateTime(dateSubmitted, this.info.dateSubmittedFormat);
            row = this.elements.tpl.clone();
            const filterValue = (column, value) => {
              const ident = (_) => _;
              const joiner = ', ';
              const formatter = formatters[column] || ident;
              if(isArray(value)) {
                value = value.map(formatter).join(joiner);
              } else {
                value = formatter('' + value);
              }
              return value;
            };
            row.removeAttr('hidden').removeClass('ui-template-row');
            row.find('.ui-el-drawNum').text(filterValue('drawNum', submission.drawNum));
            row.find('.ui-el-drawName').text(filterValue('drawName', submission.drawName));
            row.find('.ui-el-code').text(filterValue('code', entryCodes));
            row.find('.ui-el-entryCount').text(filterValue('entryCount', entryCount));
            row.find('.ui-el-dateSubmitted')
              .attr('data-timestamp', dateSubmitted)
              .text(filterValue('drawSubmitted', dateSubmittedFormatted));
            row.insertAfter(previous || this.elements.tpl);
          }
          this.paging(data);
        } else {
          this.displayMessages({ message: [ context, 'no_entries' ] });
          return false;
        }
      }
    }

    paging(data) {
      const first = this.elements.paging.find('.ui-paging-firstPage');
      const last = this.elements.paging.find('.ui-paging-lastPage');
      const prev = this.elements.paging.find('.ui-paging-prevPage');
      const next = this.elements.paging.find('.ui-paging-nextPage');
      first.prop('disable', false).removeClass('disable');
      last.prop('disable', false).removeClass('disable');
      prev.prop('disable', false).removeClass('disable');
      next.prop('disable', false).removeClass('disable');
      const firstPageUrl = data.currentPage <= 1 || !data.firstPageUrl ? null : data.firstPageUrl;
      const lastPageUrl = data.currentPage >= data.totalPages || !data.lastPageUrl ? null : data.lastPageUrl;
      const prevPageUrl = data.currentPage <= 1 || !data.previousPageUrl ? null : data.previousPageUrl;
      const nextPageUrl = data.currentPage >= data.totalPages || !data.nextPageUrl ? null : data.nextPageUrl;
      first.attr('data-url', `${firstPageUrl ?? ''}`);
      last.attr('data-url', `${lastPageUrl ?? ''}`);
      prev.attr('data-url', `${prevPageUrl ?? ''}`);
      next.attr('data-url', `${nextPageUrl ?? ''}`);
      if(!firstPageUrl) {
        first.prop('disable', true).addClass('disable');
      }
      if(!lastPageUrl) {
        last.prop('disable', true).addClass('disable');
      }
      if(!prevPageUrl) {
        prev.prop('disable', true).addClass('disable');
      }
      if(!nextPageUrl) {
        next.prop('disable', true).addClass('disable');
      }
    }

    async onMount({ info, attach, onEvent }) {
      log.trace(`Mounting entry-list component (#${this.id}).`);
      this.ele.find('.ui-list-msg').remove();
      attach({
        tpl: '.ui-template-row',
        paging: '.ui-paging',
        select: '.ui-entrylist-select',
        messages: '.ui-msg',
      });
      info({
        dateSubmittedFormat: () => this.ele.find('.ui-template-row .ui-el-dateSubmitted').attr('data-dateformat'),
        pagesize: ['[data-pagesize]'],
      });
      let drawConfigs;
      if(this.elements.select) {
        const { data } = await drawconfiglistVisible();
        drawConfigs = data.drawConfigs;
      }
      await this.build({ drawConfigs, drawConfigId: initialDrawConfig });
      onEvent({
        'select': {
          'change': () => {
            const drawConfigId = this.elements.select.val();
            let url = location.pathname;
            if(drawConfigId) {
              url = `${location.pathname}?drawconfig=${drawConfigId}`;
            }
            history && history.replaceState(null, '', url);
            this.build({ drawConfigId });
          },
        },
        '.ui-paging-firstPage, .ui-paging-lastPage, .ui-paging-prevPage, .ui-paging-nextPage': {
          'click': (evt) => {
            evt.preventDefault();
            const target = $(evt.target);
            const url = target.attr('data-url');
            if(url) {
              return this.build({ url });
            }
          },
        },
      });
    }

    static autowireSelector() {
      return '.ui-entrylist';
    }
  };

  /**
   * Autowire.
   */
  const entryListAutowire = EntryListComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    entryListAutowire, EntryListComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  entryListComponentFactory,
};
