/**
 * @imarcsgroup/client:src/components/userdata/index.js
 */

/**
 * UserData component factory.
 */
function userComponentFactory(dyn) {
  const { log, BaseComponent, controllers } = dyn();

  /**
   * UserData Component.
   */
  class UserDataComponent extends BaseComponent {
    onConfig({ config }) {
      config('ready', false);
    }

    onMount({ onState }) {
      log.trace(`Mounting user data component (#${this.id}).`);
      // Setup the template.
      let template = this.ele.attr('data-template');
      if(!template) {
        template = this.ele.text();
        this.ele.attr('data-template', template);
      }
      this.ele.text('');
      this.template = template;

      const discardOnEmpty = this.ele.attr('data-userdata-discard') === 'true';

      // On userdata change...
      onState({
        userdata: {
          selector: (_) => _.userdata,
          action: async (userdata) => {
            if(userdata.user) {
              const data = Object.assign({}, userdata.user);
              data.email_short = data.email.substring(0, 4);

              let discard = false;
              const matches = this.template.match(/\{\{([a-z_]+)\}\}/gi);
              for(let loop=0;loop < matches.length;loop++) {
                const name = matches[loop].replace(/[\{\}]/g, '');
                const value = data[name];
                if(!value && discardOnEmpty) {
                  discard = true;
                  break;
                }
              }
              if(discard) {
                this.ele.text('');
              } else {
                const value = this.template.replace(/\{\{([a-z_]+)\}\}/gi, (match, name) => {
                  return data[name] || '';
                });
                this.ele.text(value);
              }
              this.ele.addClass('ui-ready');
            } else {
              this.ele.removeClass('ui-ready');
            }
          },
          initial: true,
        },
      });
    }

    static autowireSelector() {
      return '.ui-userdata';
    }
  }

  /**
   * Autowire.
   */
  const userAutowire = UserDataComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    userAutowire, UserDataComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  userComponentFactory,
};
