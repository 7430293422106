/**
 * Imports.
 */
const { HTTP_NO_CONTENT } = require('../../utilities/httpcode');

/**
 * Close Account controller factory.
 * @param {DynastyDepends} dyn Dynasty depends function.
 * @returns {object} The object with built functions in it.
 */
async function closeAccountFactory(dyn) {
  const { log, apiFetch } = dyn();
  async function closeAccount() {
    // Log Out.
    const { status } = await apiFetch({
      method: 'POST',
      path: `/api/v1/players/self/close`,
      body: {
        'removeData': false,
      },
    });
    if (status === HTTP_NO_CONTENT) {
      log.trace(`Account closed.`);
    } else {
      log.error('An error occurred closing the account.');
      return false;
    }
    return true;
  }
  return {
    closeAccount,
  };
}

/**
 * Exports.
 */
module.exports = {
  closeAccountFactory,
};
