/**
 * @imarcsgroup/client:src/components/verifyemail/index.js
 */

/**
 * Verify Email component factory.
 */
function verifyEmailComponentFactory(dyn) {
  const { log, controllers, BaseComponent, getState } = dyn();
  const { sendVerifyEmail, verifyEmail, changeEmail, sendChangeEmail, navigateTo } = controllers;

  /** Message context. */
  const context = 'verifyemail';

  function getUrlByRole(url) {
    const roles = getState((_) => _.session.roles);
    if(/^{/.test(url)) {
      const data = JSON.parse(url);
      for(const key of Object.keys(data)) {
        const roleSet = key.split(',');
        let roleCheck = 0;
        for(let loop=0;loop < roleSet.length;loop++) {
          const role = roleSet[loop];
          roleCheck += roles.includes(role) ? 1 : 0;
        }
        if(roleCheck === roleSet.length) {
          url = data[key];
          break;
        }
      };
    }
    return url;
  }

  /**
   * VerifyEmail Component.
   */
  class VerifyEmailComponent extends BaseComponent {
    async onMount({ defaultAttr, validation, attach, fields, prop, info, onState, onEvent }) {
      log.trace(`Mounting verify-email component (#${this.id}).`);

      let changingEmail = false;

      attach({
        form: '.ui-verify',
        email: '.ui-email',
        sendCode: 'a.ui-sendcode, button.ui-sendcode',
        code: 'input[name="code"]',
        messages: '.ui-msg',
        verifyEmail: '.ui-verify',
        showChangeEmail: '.ui-change-email',
        showVerifyEmail: '.ui-verify-email',
        changeForm: '.ui-change',
        changeFormMessage: '.ui-change .ui-msg',
        changeFormEmail: '.ui-change input[name=email]',
        changeFormConfirm: '.ui-change input[name=email_confirm]',
        submitButton: '.ui-verify button[type="submit"]',
      });
      fields({
        code: validation().text().required(),
      });
      info({
        successPath: ['[data-success]'],
      });
      prop({
        form: {
          autocomplete: false,
        },
      });

      this.elements.changeForm.attr('action', '#');
      this.elements.changeForm.attr('method', 'post');
      this.elements.changeForm.attr('novalidate', true);

      // On userdata change...
      onState({
        userdata: {
          selector: (_) => _.userdata,
          action: (userdata) => {
            if(!changingEmail) {
              this.elements.email.text(userdata?.user?.email ?? '-');
            }
          },
          initial: true,
        },
      });

      const uiSendVerifyEmail = async (showMessage = true) => {
        const currentRoles = getState((_) => _.session.roles);
        if(!currentRoles.includes('@access')) {
          log.trace('User is not logged in. Aborted sending verification email.');
          return;
        }
        try {
          this.busy();
          this.clearMessages();
          this.elements.code.val('');
          const results = await sendVerifyEmail();
          if(!results?.ok) {
            const code = results?.code || 'error';
            this.displayMessages({ message: [ context, code ] });
            return false;
          } else if(showMessage) {
            this.displayMessages({ message: [ context, 'send_email' ] });
          }
          return true;
        } finally {
          this.ready();
        }
      };


      const uiSendChangeEmail = async (toEmail, showMessage = true) => {
        try {
          this.busy();
          this.clearMessages();
          this.elements.code.val('');
          const results = await sendChangeEmail(toEmail);
          if(!results?.ok) {
            const code = results?.code || 'error';
            return false;
          } else if(showMessage) {
            this.displayMessages({ message: [ context, 'send_email' ] });
          }
          return true;
        } finally {
          this.ready();
        }
      };

      onEvent({
        code: {
          input(evt) {
            const input = $(evt.currentTarget);
            const value = input.val();
            if(value.length != 6) {
              this.elements.submitButton.prop('disabled', true);
            } else {
              this.elements.submitButton.prop('disabled', false);
            }
          },
        },
        showChangeEmail: {
          click(evt) {
            evt.preventDefault();
            try {
              this.busy();
              this.clearMessages();
              this.elements.changeFormEmail.val('');
              this.elements.changeFormConfirm.val('');
              this.elements.verifyEmail.hide();
              this.elements.changeForm.show();
              this.elements.changeForm.find('[autofocus]').focus();
              return true;
            } finally {
              this.ready();
            }
          },
        },
        showVerifyEmail: {
          click(evt) {
            evt.preventDefault();
            try {
              this.busy();
              this.clearMessages();
              this.elements.changeFormEmail.val('');
              this.elements.changeFormConfirm.val('');
              this.elements.changeForm.hide();
              this.elements.verifyEmail.show();
              this.elements.verifyEmail.find('[autofocus]').focus();
              return true;
            } finally {
              this.ready();
            }
          },
        },
        changeForm: {
          async submit(evt) {
            evt.preventDefault();
            try {
              this.busy();
              this.clearMessages();
              const toEmail = this.elements.changeFormEmail.val().trim();
              const toEmailConfirm = this.elements.changeFormConfirm.val().trim();
              if(!/^.+@.+\..+$/.test(toEmail)) {
                this.elements.changeFormMessage.text('Enter a valid Email Address.');
                return false;
              } else if(toEmail !== toEmailConfirm) {
                this.elements.changeFormMessage.text('Email Addresses do not match.');
                return false;
              }
              await uiSendChangeEmail(toEmail);
              this.elements.changeForm.hide();
              this.elements.changeFormEmail.val('');
              this.elements.changeFormConfirm.val('');
              this.elements.code.val('');
              this.elements.verifyEmail.show();
              this.elements.verifyEmail.find('[autofocus]').focus();
              this.elements.email.text(toEmail);
              changingEmail = toEmail;
              return true;
            } finally {
              this.ready();
            }
          },
        },
        form: {
          async submit(evt) {
            evt.preventDefault();
            try {
              this.busy();
              this.clearMessages();
              const { valid, values, issues } = await this.validate();
              if(!valid) {
                this.displayMessages({ context, issues });
                return false;
              }
              let results;
              if(changingEmail) {
                results = await changeEmail(values.code);
              } else {
                results = await verifyEmail(values.code);
              }
              if(!results?.ok) {
                const code = results?.code || 'error';
                this.displayMessages({ message: [ context, code ] });
                return false;
              }
              const location = getUrlByRole(this.info.successPath);
              await navigateTo(location);
              return true;
            } finally {
              this.ready();
            }
          },
        },
        sendCode: {
          click(evt) {
            evt.preventDefault();
            if(changingEmail) {
              return uiSendChangeEmail(changingEmail);
            } else {
              return uiSendVerifyEmail();
            }
          },
        },
      });

      // Ready...
      await uiSendVerifyEmail(false);
    }

    static autowireSelector() {
      return 'div.ui-verifyemail';
    }
  }

  /**
 * Autowire.
 */
  const verifyEmailAutowire = VerifyEmailComponent.autowireFactory();

  /**
 * Built.
 */
  return {
    verifyEmailAutowire, VerifyEmailComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  verifyEmailComponentFactory,
};
