/**
 * @imarcsgroup/client:src/store/index.js
 */

/**
 * Imports.
 */
const { configureStore } = require('@reduxjs/toolkit');

/**
 * Store factory.
 */
function storeFactory(dyn) {
  const { log, reducers = {}, middleware = {} } = dyn();
  const reducerCount = Object.keys(reducers).length;
  const middlewareList = Object.values(middleware);
  const middlewareCount = middlewareList.length;
  const devTools = process.env.NODE_ENV === 'development';
  const store = configureStore({ reducer: reducers, devTools, middleware: middlewareList });
  log.debug({ reducers: reducerCount, middleware: middlewareCount }, 'Store created.');
  return store;
}

/**
 * Exports.
 */
module.exports = {
  storeFactory,
};
