/**
 * Navigate-To controller factory.
 * @param {DynastyDepends} dyn Dynasty depends function.
 * @returns {object} The object with built functions in it.
 */
async function navigateToFactory(dyn) {
  const { log } = dyn();
  async function navigateTo(url, tab) {
    if(url && url !== '#') {
      if(!tab) {
        log.trace(`Navigating to "${url}".`);
        window.location.href = url;
      } else {
        log.trace(`Opening "${url}".`);
        window.open(url, '_blank');
      }
      return true;
    }
    return false;
  };
  return {
    navigateTo,
  };
}

/**
 * Exports.
 */
module.exports = {
  navigateToFactory,
};
