/**
 * Router service factory.
 */
function routerServiceFactory(dyn) {
  const { log, subscribe, controllers } = dyn();
  const { navigateTo } = controllers;

  subscribe((_) => _.session.timeoutUrl, async (url) => {
    if(url) {
      navigateTo(url);
    }
  }, true);
  log.debug(`Router service ready.`);
}

/**
 * Exports.
 */
module.exports = {
  routerServiceFactory,
};
