/**
 * @imarcsgroup/client:src/components/ticketEntry/index.js
 */

const { isInteger } = require('@theroyalwhee0/istype');
const { getNextFocusableElement } = require('../../utilities/dom');

/**
 * TicketEntry component factory.
 */
function ticketEntryComponentFactory(dyn) {
  const { log, controllers, BaseComponent } = dyn();
  const { navigateTo, createSubmission } = controllers;

  /** Message context. */
  const context = 'ticketentry';

  /**
   * Ticket Parser.
   */
  function ticketParser(value) {
    const gamenumber = (value && value.slice(0, 4)) || '';
    return { gamenumber };
  }

  /**
   * TicketEntry Component.
   */
  class TicketEntryComponent extends BaseComponent {
    onMount({ validation, attach, info, fields, onEvent, autotab }) {
      const isLoggedIn = this.hasRole('login-mfa');
      const hasSecondChanceRole = this.hasRole('secondchance');

      if(isLoggedIn && !hasSecondChanceRole) {
        navigateTo('/home/lz_upgrade_required/');
        return;
      }

      log.trace(`Mounting ticket-entry component (#${this.id}).`);
      attach({
        form: ':self',
        ticket: '[name="ticket"], [name="ticket.1"], [name="ticket.2"], [name="ticket.3"], [name="ticket.4"], [name="ticket.5"]',
        disable: [ ':self', 'input, select, button' ],
        submit: 'button[type="submit"], input[type="submit"]',
        messages: '.ui-msg',
      });
      fields({
        ticket: validation().text().required(),
      });
      autotab();
      onEvent({
        ticket: {
          async paste(evt) {
            let content = evt.originalEvent?.clipboardData?.getData('text');
            if(content) {
              content = content.replace(/[\- ,]/g, '');
              const first = $(evt.currentTarget);
              let target = first;
              while(content && target.length) {
                const maxLen = Number(target.attr('maxlength'));
                if(!isInteger(maxLen)) {
                  break;
                }
                const part = content.slice(0, maxLen);
                content = content.slice(maxLen);
                target.val(part);
                target = getNextFocusableElement(target);
              }
              window.setTimeout(() => {
                first.focus();
              }, 4);
            }
          },
        },
        form: {
          async submit(evt) {
            evt.preventDefault();
            try {
              this.busy();
              this.clearMessages();
              const { valid, values, meta, issues } = await this.validate();
              const { ticket } = values;
              const { gamenumber } = ticketParser(ticket);
              log.trace({ valid }, `Ticket "${ticket}" for game "${gamenumber}".`);
              if(!valid) {
                this.displayMessages({ context, issues, data: { ticket, gamenumber } });
                return false;
              }
              const submissionResults = await createSubmission({ tickets: [ticket] });
              if(submissionResults.code) {
                const { code } = submissionResults;
                this.displayMessages({ message: [ context, code ], data: { ticket, gamenumber } });
                return false;
              } else {
                this.displayMessages({ message: [ context, 'success' ], data: { ticket, gamenumber } });
                this.elements.ticket.val('');
                return true;
              }
            } finally {
              this.ready();
            }
          },
        },
      });
    }

    static autowireSelector() {
      return 'form.ui-ticketentry';
    }
  }

  /**
   * Autowire.
   */
  const ticketEntryAutowire = TicketEntryComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    ticketEntryAutowire, TicketEntryComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  ticketEntryComponentFactory,
};
