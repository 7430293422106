/**
 * @imarcsgroup/client:src/libs/index.js
 */

/**
 * Imports.
 */
const { checkJquery } = require('./jquery');

/**
 * Check libraries.
 */
function checkLibraries() {
  checkJquery();
}

/**
 * Exports.
 */
module.exports = {
  checkLibraries,
};
