/**
 * Imports.
 */
const { HTTP_OK } = require('../../utilities/httpcode');

/**
 * UserData controller factory.
 * @param {DynastyDepends} dyn Dynasty depends function.
 * @returns {object} The object with built functions in it.
 */
async function userDataFactory(dyn) {
  const { log, apiFetch, getState, dispatch, actions, siteId, clientId, subscribeTill } = dyn();

  async function userData() {
  };

  return {
    userData,
  };
}

/**
 * Exports.
 */
module.exports = {
  userDataFactory,
};
