const { HTTP_OK, HTTP_SERVICE_UNAVAILABLE, HTTP_NO_CONTENT } = require('../../utilities/httpcode');
const { codeOrError } = require('../../utilities/rest');

function resetPasswordFactory(dyn) {
  const { apiFetch } = dyn();

  async function resetPassword(token, password) {
    const { data, status } = await apiFetch({
      method: 'PUT',
      path: `/api/v1/players/reset-password`,
      body: {
        oneTimeToken: token,
        newPassword: password,
      },
    });
    if(status === HTTP_SERVICE_UNAVAILABLE) {
      return { code: 'service_unavailable' };
    } else if(status !== HTTP_OK && status !== HTTP_NO_CONTENT) {
      return codeOrError(data);
    }
    return {
      ok: true,
    };
  };

  return {
    resetPassword,
  };
}

module.exports = {
  resetPasswordFactory,
};
