/**
 * api-client:src/ui/autowire.js
 */

/**
 * Constants.
 */
const re_autowire = /.Autowire$/;

/**
 * AutoWire factory.test(name)
 */
async function autoWireFactory(dyn) {
  const { log, components } = dyn();
  for(let name in components) {
    const autoWireFn = components[name];
    if(re_autowire.test(name) && typeof autoWireFn === 'function') {
      await autoWireFn();
    }
  }
  log.trace('AutoWire complete.');
}

/**
 * Exports.
 */
module.exports = {
  autoWireFactory,
};
