/**
 * @imarcsgroup/client:src/components/registernotification/index.js
 */

/**
 * Notification Register component factory.
 */
function notificationRegisterComponentFactory(dyn) {
  const { log, env, controllers, BaseComponent } = dyn();
  const { register, login, verifyLogin, navigateTo } = controllers;

  /** Message context. */
  const context = 'register';

  /**
   * Notification Register Component.
   */
  class NotificationRegisterComponent extends BaseComponent {
    onMount({ validation, attach, fields, onEvent, info, validateOnChange }) {
      log.trace(`Mounting register component (#${this.id}).`);

      if(env && env !== 'prod') {
        console.log('======== ATTACHING DEBUG FAST REGISTER ========');
        $('#register_email').on('dblclick', (evt) => {
          if(evt.shiftKey) {
            var myemail = `test.user+${Date.now()}@imarcsgroup.com`;
            $('#register_email').val(myemail);
            $('#register_confirmemail').val(myemail);
            $('#register_password').val('Test123456');
            $('#register_confirmpassword').val('Test123456');
          }
        });
      }

      attach({
        form: ':self',
        email: '[name="email"]',
        email_confirm: '[name="email_confirm"]',
        password: '[name="password"]',
        password_confirm: '[name="password_confirm"]',
        disable: [ ':self', 'input, select, button' ],
        submit: 'button[type="submit"], input[type="submit"]',
        messages: '.ui-msg',
      });

      fields({
        email: validation().email().required(),
        email_confirm: validation().email().equalsInsensitive('email').required(),
        password: validation().password().required(),
        password_confirm: validation().password().equals('password').required(),
      });
      info({
        login: ['[data-login]'],
        for: ['[data-for]'],
        successPath: ['[data-success]'],
      });
      validateOnChange({ context });
      onEvent({
        form: {
          async reset(evt) {
            try {
              this.busy();
              this.clearMessages();
            } finally {
              this.ready();
            }
          },
          async submit(evt) {
            evt.preventDefault();
            try {
              this.busy();
              this.clearMessages();
              let { valid, serialized: values, meta, issues } = await this.validate();
              if(this.info.for) {
                values.for = this.info.for;
              }
              if(!valid) {
                // Display messages.
                this.displayMessages({ context, issues });
                return false;
              }
              const registerResults = await register(values);
              if(registerResults.code) {
                const { code } = registerResults;
                this.displayMessages({ message: [ context, code ] });
                return false;
              }
              if(this.info.login) {
                const results = await login(values.email, values.password);
                if(!results?.ok) {
                  const code = results?.code || 'error';
                  this.displayMessages({ message: [ context, code ] });
                  return false;
                } else {
                  await verifyLogin(results);
                }
              }
              await navigateTo(this.info.successPath);
              return true;
            } finally {
              this.ready();
            }
          },
        },
      });
    }

    static autowireSelector() {
      return 'form.ui-register-notification';
    }
  }

  /**
   * Autowire.
   */
  const notificationRegisterAutowire = NotificationRegisterComponent.autowireFactory();

  /**
   * Built.
   */
  return {
    notificationRegisterAutowire, NotificationRegisterComponent,
  };
}

/**
 * Exports.
 */
module.exports = {
  notificationRegisterComponentFactory,
};
