/**
 * @imarcsgroup/client:src/session/actions.js
 */

/**
 * Imports.
 */
const { createReducer } = require('@reduxjs/toolkit');
const { sessionClear, sessionLogOut, sessionFromTokens, sessionSet, sessionActivity } = require('./actions');

function isBoolean(value) {
  return value === true || value === false;
}

/**
 * Initial State.
 */
const initialState = {
  type: 'anonymous',
  roles: [],
  ident: '',
  timeoutUrl: '',
  activity: {
    touch: Date.now(),
  },
  tokens: {
  },
};

/**
 * Reducer.
 */
const sessionReducer = createReducer(initialState, {
  [sessionClear]: (_state, _action) => {
    return initialState;
  },
  [sessionActivity]: (state, action) => {
    const activity = action.payload;
    Object.assign(state.activity, activity);
  },
  [sessionLogOut]: (state, action) => {
    let url = action.payload?.url || '';
    if(url === '#') {
      url = '';
    }
    Object.assign(state, initialState);
    state.roles = initialState.roles.concat();
    if(url) {
      state.roles.push('@leaving');
      state.timeoutUrl = url;
    }
  },
  [sessionSet]: (state, action) => {
    const { tokens, ...rest } = action.payload;
    state.refresh = false;
    Object.assign(state, rest);
    Object.assign(state.tokens, tokens);
  },
  [sessionFromTokens]: (state, action) => {
    state.type = 'user';
    state.refresh = false;
    const roles = new Set();
    for(let item of action.payload) {
      const tokenType = item.tokenType.toLowerCase();
      const token = state.tokens[tokenType] = state.tokens[tokenType] || {};
      token.key = item.token;
      const expiresIn = Number(item.tokenParams.expiresIn);
      token.issueTime = Number(item.tokenParams.issueTime);
      token.expireTime = token.issueTime + (expiresIn * 60 * 1000);
      if(tokenType === 'oauth') {
        item.roles.forEach((role) => {
          roles.add(role);
        });
      }
    }
    if(state.tokens.oauth?.key) {
      roles.add('@access');
    }
    state.roles = Array.from(roles);
    state.ident = Date.now().toString(36);
  },
});

/**
 * Exports.
 */
module.exports = {
  session: sessionReducer,
};
