/**
 * @imarcsgroup/client:src/strings/feedback.js
 */

/**
 * Strings.
 */
const strings = {
  'feedback.error': 'An error has occurred while trying to send feedback.',
  'feedback.email.mismatched': 'Email should be an email address.',
  'feedback.email.required': 'Email is required.',
  'feedback.name.required': 'Name is required.',
  'feedback.comment.required': 'Comment is required.',
};

/**
 * Exports.
 */
module.exports = strings;
