/**
 * Activity service factory.
 */
function activityServiceFactory(dyn) {
  const { log, dispatch, actions } = dyn();
  const { sessionActivity } = actions;

  $(':root').on('keydown pointerdown wheel', (evt) => {
    const activity = {
      touch: Date.now(),
    };
    dispatch(sessionActivity(activity));
  });
  log.debug(`Activity service ready.`);
}

/**
 * Exports.
 */
module.exports = {
  activityServiceFactory,
};
